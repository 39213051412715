/**
 * --------------------------------------------------------------------------
 * NJ: password-input.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';

export default class Password extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-form-item--password`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.password-input`;
  static readonly SELECTOR = {
    default: `.${Password.NAME}`
  };
  private static readonly INPUT_CLASS = `${Core.KEY_PREFIX}-form-item__field`;
  private static readonly TOGGLE_BTN_CLASS = `${Core.KEY_PREFIX}-form-item__password-button`;
  private static readonly NOTICE_CLASS = `${Core.KEY_PREFIX}-form-item__password-notice`;
  private static readonly VISIBILITY_CLASS = `${Core.KEY_PREFIX}-form-item--visible`;

  protected static readonly DEFAULT_OPTIONS = {
    selector: Password.SELECTOR
  };

  constructor(element: HTMLElement, options = {}) {
    super(Password, element, Manipulator.extend(true, Password.DEFAULT_OPTIONS, options));

    this.setListeners();
    Data.setData(element, Password.DATA_KEY, this);
  }

  static init(options = {}): Password[] {
    return super.init(this, options, Password.SELECTOR.default) as Password[];
  }

  static getInstance(element: HTMLElement): Password {
    return Data.getData(element, Password.DATA_KEY) as Password;
  }

  dispose(): void {
    Data.removeData(this.element, Password.DATA_KEY);
    this.element = null;
  }

  private setListeners(): void {
    if (!this.element) {
      return;
    }

    const input = this.element.getElementsByClassName(Password.INPUT_CLASS)[0] as HTMLInputElement;
    const toggleButton = this.element.getElementsByClassName(Password.TOGGLE_BTN_CLASS)[0];
    const toggleButtonLabel = toggleButton.querySelector('span');
    const notice = this.element.getElementsByClassName(Password.NOTICE_CLASS)[0];

    const showPasswordLabel = toggleButtonLabel.getAttribute('data-password-button-label-show');
    const hidePasswordLabel = toggleButtonLabel.getAttribute('data-password-button-label-hide');
    const passwordIsVisibleLabel = notice.getAttribute('data-password-notice-is-visible');
    const passwordIsHiddenLabel = notice.getAttribute('data-password-notice-is-hidden');

    if (!showPasswordLabel || !hidePasswordLabel || !passwordIsVisibleLabel || !passwordIsHiddenLabel) {
      console.warn(
        '[FLUID] Some of these required attributes are missing: [data-password-button-label-show], [data-password-button-label-hide], [data-password-notice-is-visible], [data-password-notice-is-hidden]'
      );
    }

    toggleButtonLabel.innerHTML = showPasswordLabel;

    if (this.element.classList.contains(Password.VISIBILITY_CLASS)) {
      input.type = 'text';
      toggleButton.setAttribute('aria-pressed', 'false');
      toggleButtonLabel.innerHTML = hidePasswordLabel;
      notice.innerHTML = passwordIsVisibleLabel;
    }

    if (toggleButton) {
      EventHandler.on(toggleButton, 'click keydown', (event: MouseEvent | KeyboardEvent) => {
        if (event.type === 'keydown' && 'key' in event && event.key !== 'Enter' && event.key !== ' ') {
          return;
        }

        event.preventDefault();
        if (input.type === 'text') {
          input.type = 'password';
          this.element.classList.remove(Password.VISIBILITY_CLASS);
          toggleButton.setAttribute('aria-pressed', 'false');
          toggleButtonLabel.innerHTML = showPasswordLabel;
          notice.innerHTML = passwordIsHiddenLabel;
        } else if (input.type === 'password') {
          input.type = 'text';
          this.element.classList.add(Password.VISIBILITY_CLASS);
          toggleButton.setAttribute('aria-pressed', 'true');
          toggleButtonLabel.innerHTML = hidePasswordLabel;
          notice.innerHTML = passwordIsVisibleLabel;
        }
      });
    } else {
      console.warn('Toggle password icon not found');
    }
  }
}
