import PlaceIcon from "@mui/icons-material/Place";
import DeleteIcon from "@mui/icons-material/Delete";
import PencilIcon from "@mui/icons-material/Edit";
import PlusIcon from "@mui/icons-material/Add";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import { useMemo } from "react";
import { ReactCountryFlag } from "react-country-flag";
import { ColModel } from "../components/GenericTableRow";
import { formatCoordinates } from "./helpers";
import {
	Portfolio,
	Site,
	isPortfolio,
} from "../../features/sites/sites.module";
import { HamburgerMenu } from "../components/HamburgerMenu";
import { HamburgerOptions } from "../components/HamburgerOptions";
import { formatProxyGenerationStatus } from "../utils/formatProxyGenerationStatus";
import { FlashUpdateTableCell } from "../components/FlashUpdateTableCell";
import { Typography } from "@mui/material";
import { formatDecimal } from "../utils/formatDecimal";
import { SiteKnownStatusField } from "../components/SiteKnownStatusField";
import { If } from "../components/If";
import { SiteTechnicalDetails } from "../components/SiteTechnicalDetails";

export interface TenderFormSiteTableModelParams {
	showActions: boolean;
	showRefreshCluster?: boolean;
	onDeletePortfolio: (m: Portfolio) => void;
	onDeleteSite: (m: Site) => void;
	selectPortfolio: (m: Portfolio) => void;
	renamePortfolio: (rename: boolean) => void;
	openPortfolioSiteModal: (id: number) => void;
	refreshSiteCluster: (m: Site) => Promise<void> | void;
}

export const useTenderFormSiteTableModel = (
	params: TenderFormSiteTableModelParams
) => {
	const {
		showActions,
		showRefreshCluster = true,
		onDeletePortfolio,
		onDeleteSite,
		selectPortfolio,
		renamePortfolio,
		openPortfolioSiteModal,
		refreshSiteCluster,
	} = params;
	const rowModel: ColModel<Site | Portfolio>[] = useMemo(() => {
		const rows = [
			{
				id: "delete",
				header: () => "Actions",
				xs: 1,
				hidden: !showActions,
				accessor: (m: Site | Portfolio) => (
					<>
						{isPortfolio(m) && (
							<>
								<DeleteIcon
									datatest-id={`${m.name}-delete`}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										onDeletePortfolio(m);
									}}
								/>
							</>
						)}
						{!isPortfolio(m) && (
							<>
								<DeleteIcon
									datatest-id={`${m.specific_name}-delete`}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										onDeleteSite(m);
									}}
								/>
							</>
						)}
						{isPortfolio(m) && (
							<>
								<PencilIcon
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										selectPortfolio(m);
										renamePortfolio(true);
									}}
								/>
							</>
						)}
						{isPortfolio(m) && (
							<>
								<PlusIcon
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										selectPortfolio(m);
										openPortfolioSiteModal(m.id);
									}}
								/>
							</>
						)}
					</>
				),
			},
			{
				id: "id",
				header: () => "Id",
				xs: 1,
				hidden: true,
				accessor: (m: Site | Portfolio) => m.id,
			},
			{
				id: "asset_id",
				header: () => "TPR Id",
				xs: 1,
				hidden: false,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "-" : m.asset_id,
				sortable: true,
			},
			{
				id: "specific_name",
				header: () => "Name",
				xs: 2,
				accessor: (m: Site | Portfolio) => (
					<>
						{m.hasOwnProperty("specific_name") &&
						(m as Site).specific_name
							? (m as Site).specific_name
							: m.name}
						{"children" in m && m.children
							? `(${m.children.length})`
							: ""}
					</>
				),
				sortable: true,
			},
			{
				id: "type",
				header: () => "Type",
				xs: 0.5,
				accessor: (m: Site | Portfolio) => (
					<>
						{!!m?.children && (
							<>
								<BookmarksIcon />
								{" Portfolio"}
							</>
						)}
						{!m?.children && (
							<>
								{" "}
								<PlaceIcon></PlaceIcon>
								{" Site"}
							</>
						)}
					</>
				),
			},
			{
				id: "country",
				header: () => "Country",
				xs: 0.5,
				accessor: (m: Site | Portfolio) => (
					<>
						{"country" in m && (
							<>
								<ReactCountryFlag
									countryCode={m.country ?? ""}
									svg
									title={m.country ?? ""}
								/>

								{m.country}
							</>
						)}
					</>
				),
			},
			{
				id: "cluster",
				header: () => "Cluster",
				xs: 1,
				accessor: (m: Site | Portfolio) => {
					let clusterName = "";
					if ("cluster_names" in m) {
						clusterName = m.cluster_names ?? "";
					}
					return (
						<FlashUpdateTableCell
							field={clusterName}
							isUpdate={m.isNewUpdate}
							align="left"
							componentType="box"
							title={clusterName}
							sx={{
								border: "0 !important",
								padding: "0 !important",
							}}
						>
							<Typography variant="subtitle2" noWrap>
								{clusterName}
							</Typography>
						</FlashUpdateTableCell>
					);
				},
			},
			{
				id: "clusterstatus",
				header: () => "Cl. Status",
				xs: 1,
				accessor: (m: Site | Portfolio) => {
					return (
						<FlashUpdateTableCell
							field={
								"proxy_generation_status" in m &&
								formatProxyGenerationStatus(
									m?.proxy_generation_status,
									m.proxy_generation_errors,
									() => refreshSiteCluster(m)
								)
							}
							isUpdate={m.isNewUpdate}
							align="left"
							sx={{
								border: "0 !important",
								padding: "0 !important",
							}}
						>
							{"proxy_generation_status" in m &&
								formatProxyGenerationStatus(
									m?.proxy_generation_status,
									m.proxy_generation_errors,
									() => refreshSiteCluster(m)
								)}
						</FlashUpdateTableCell>
					);
				},
			},
			{
				id: "clusterdate",
				header: () => "Cl. Date",
				xs: 1,
				accessor: (m: Site | Portfolio) => {
					return (
						<FlashUpdateTableCell
							field={
								"proxy_generation_date" in m &&
								m.proxy_generation_date
									? new Date(
											Date.parse(
												`${m.proxy_generation_date}Z`
											)
									  ).toLocaleString()
									: ""
							}
							isUpdate={m.isNewUpdate}
							align="left"
							sx={{
								border: "0 !important",
								padding: "0 !important",
							}}
						>
							{"proxy_generation_date" in m &&
							m.proxy_generation_date
								? new Date(
										`${
											m.proxy_generation_date.endsWith(
												"Z"
											)
												? m.proxy_generation_date
												: m.proxy_generation_date + "Z"
										}`
								  ).toLocaleString()
								: ""}
						</FlashUpdateTableCell>
					);
				},
			},
			{
				id: "technology",
				header: () => "Technology",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					"techno" in m && m?.techno?.name,
			},
			{
				id: "installed_capacity_mw",
				header: () => "Installed capacity",
				xs: 1,
				accessor: (m: Site | Portfolio) => (
					<>
						{"installed_capacity_mw" in m &&
							(formatDecimal(m.installed_capacity_mw) ?? "")}
						{!("installed_capacity_mw" in m)
							? (m as Portfolio).children
									.reduce((t: number, s: Site) => {
										let capacity = s.installed_capacity_mw;
										if (typeof capacity !== "number") {
											capacity = parseFloat(
												capacity || "0.0"
											);
										}
										return t + (capacity ?? 0);
									}, 0)
									.toFixed(2)
							: ""}
					</>
				),
				sortable: true,
			},
			{
				id: "max_injection",
				header: () => "Max Grid Injection",
				xs: 1.2,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "-" : m.max_grid_injection_mw || "-",
			},
			{
				id: "park_age",
				header: () => "Park age",
				xs: 1,
				accessor: (m: Site | Portfolio) => {
					if (!(m as Portfolio).children) {
						return m?.park_age;
					}

					const weightedSum = (m as Portfolio).children.reduce(
						(t: number, s: Site) =>
							t +
							(s.installed_capacity_mw ?? 0) * (s.park_age ?? 0),
						0
					);

					const portfolioCapa = (m as Portfolio).children.reduce(
						(t: number, s: Site) =>
							t + Number(s.installed_capacity_mw ?? 0),
						0
					);

					if (portfolioCapa === 0) {
						return "";
					}

					return Math.round(weightedSum / portfolioCapa);
				},
				sortable: true,
			},
			{
				id: "tech",
				header: () => "Technical details",
				xs: 2,
				accessor: (m: Site | Portfolio) => (
					<SiteTechnicalDetails site={m} />
				),
			},
			{
				id: "latitude",
				header: () => "Latitude",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "" : formatCoordinates(4, m).latitude,
				sortable: true,
			},
			{
				id: "longitude",
				header: () => "Longitude",
				xs: 1,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? "" : formatCoordinates(4, m).longitude,
				sortable: true,
			},
			{
				id: "knownstatus",
				header: () => "Known status",
				xs: 1,
				accessor: (m: Site | Portfolio) => {
					return (
						<Typography variant="subtitle2">
							{!isPortfolio(m) && (
								<SiteKnownStatusField site={m} />
							)}
						</Typography>
					);
				},
			},

			{
				id: "customerp50",
				header: () => "Customer P50",
				xs: 1,
				accessor: (m: Site | Portfolio) => (
					<>
						<Typography variant="subtitle2">
							{!isPortfolio(m) && m.real_power_production}
							{isPortfolio(m) &&
								m.children.reduce(
									(t: number, s: Site) =>
										t + (s.real_power_production ?? 0),
									0
								)}
						</Typography>
					</>
				),
			},
			{
				id: "clusterp50",
				header: () => "Cluster P50",
				xs: 1,
				accessor: (m: Site | Portfolio) => (
					<>
						<Typography variant="subtitle2">
							{!isPortfolio(m) && m.cluster_p50}
							{isPortfolio(m) &&
								m.children.reduce(
									(t: number, s: Site) =>
										t + (s.cluster_p50 ?? 0),
									0
								)}
						</Typography>
					</>
				),
			},
			{
				id: "#pricings",
				header: () => "Pricings",
				xs: 1,
				accessor: (m: Site | Portfolio) => {
					return (
						<Typography
							variant="subtitle2"
							sx={{
								...(!m.count_attached_pricings && {
									color: "red",
								}),
							}}
						>
							{m.count_attached_pricings}
						</Typography>
					);
				},
			},
			{
				id: "actions",
				header: () => " ",
				hidden: !showRefreshCluster,
				xs: 0.5,
				accessor: (m: Site | Portfolio) =>
					isPortfolio(m) ? null : (
						<If condition={m.could_refresh_cluster_status}>
							<HamburgerMenu>
								<HamburgerOptions
									title={"Refresh cluster"}
									action={() => refreshSiteCluster(m)}
								/>
							</HamburgerMenu>
						</If>
					),
			},
		];
		return rows;
	}, []);

	return rowModel;
};
