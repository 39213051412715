/**
 * --------------------------------------------------------------------------
 * Bootstrap (v4.1.3): dom/eventHandler.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * --------------------------------------------------------------------------
 */
import { CustomEvent, NativeEvent } from './enum';

interface Handler extends Function {
  delegationSelector?: any;
  originalHandler?: any;
  oneOff?: boolean;
  uidEvent?: number;
}

export default class EventHandler {
  private static readonly NAMESPACE_REGEX = /[^.]*(?=\..*)\.|.*/;
  private static readonly STRIPNAME_REGEX = /\..*/;
  private static readonly KEYEVENT_REGEX = /^key/;
  private static readonly STRIPUID_REGEX = /::\d+$/;
  private static readonly EVENTREGISTRY = {}; // Events storage
  private static uidEvent = 1;

  static getUidEvent(element, uid?): number {
    return (uid && `${uid}::${EventHandler.uidEvent++}`) || element.uidEvent || EventHandler.uidEvent++;
  }

  static getEvent(element): any {
    const uid = EventHandler.getUidEvent(element);
    element.uidEvent = uid;

    // eslint-disable-next-line no-return-assign
    return (EventHandler.EVENTREGISTRY[uid] = EventHandler.EVENTREGISTRY[uid] || {});
  }

  static fixEvent(event, element): void {
    // Add which for key events
    if (event.which === null && EventHandler.KEYEVENT_REGEX.test(event.type)) {
      event.which = event.charCode !== null ? event.charCode : event.keyCode;
    }

    event.delegateTarget = element;
  }

  static njHandler(element, fn): Handler {
    const handler: Handler = (event) => {
      EventHandler.fixEvent(event, element);
      if (handler.oneOff) {
        EventHandler.off(element, event.type, fn);
      }

      return fn.apply(element, [event]);
    };

    return handler;
  }

  static njDelegationHandler(element, selector, fn): Handler {
    const handler: Handler = (event) => {
      const domElements = element.querySelectorAll(selector);
      for (let target = event.target; target && target !== this; target = target.parentNode) {
        for (let i = domElements.length; i >= 0; i--) {
          if (domElements[i] === target) {
            EventHandler.fixEvent(event, target);
            if (handler.oneOff) {
              EventHandler.off(element, event.type, fn);
            }

            return fn.apply(target, [event]);
          }
        }
      }

      // To please ESLint
      return null;
    };

    return handler;
  }

  static findHandler(events, handler, delegationSelector = null): any {
    for (const uid in events) {
      if (!Object.prototype.hasOwnProperty.call(events, uid)) {
        continue;
      }

      const event = events[uid];
      if (event.originalHandler === handler && event.delegationSelector === delegationSelector) {
        return events[uid];
      }
    }

    return null;
  }

  static normalizeParams(
    originalTypeEvent: string,
    handler?: THandler,
    delegationFn?: () => any
  ): [boolean, () => any, string] {
    const hasDelegation = typeof handler === 'string';
    const originalHandler = hasDelegation ? delegationFn : (handler as () => any);

    // allow to get the native events from namespaced events ('click.bs.button' --> 'click')
    let typeEvent = originalTypeEvent.replace(EventHandler.STRIPNAME_REGEX, '');

    const custom = CustomEvent[typeEvent];
    if (custom) {
      typeEvent = custom;
    }

    const isNative = typeof NativeEvent[typeEvent] === 'string';
    if (!isNative) {
      typeEvent = originalTypeEvent;
    }

    return [hasDelegation, originalHandler, typeEvent];
  }

  static addHandler(element, originalTypeEvents: string, handler?: THandler, delegationFn?: () => any, oneOff?): void {
    if (typeof originalTypeEvents !== 'string' || typeof element === 'undefined' || element === null) {
      return;
    }

    if (!handler) {
      handler = delegationFn;
      delegationFn = null;
    }

    const events = EventHandler.getEvent(element);

    for (const originalTypeEvent of originalTypeEvents.split(' ')) {
      const [hasDelegation, originalHandler, typeEvent] = EventHandler.normalizeParams(
        originalTypeEvent,
        handler,
        delegationFn
      );

      const handlers = events[typeEvent] || (events[typeEvent] = {});
      const previousFn = EventHandler.findHandler(handlers, originalHandler, hasDelegation ? handler : null);

      if (previousFn) {
        previousFn.oneOff = previousFn.oneOff && oneOff;
        return;
      }

      const uid = EventHandler.getUidEvent(
        originalHandler,
        originalTypeEvent.replace(EventHandler.NAMESPACE_REGEX, '')
      );
      const fn = !hasDelegation
        ? EventHandler.njHandler(element, handler)
        : EventHandler.njDelegationHandler(element, handler, delegationFn);

      fn.delegationSelector = hasDelegation ? handler : null;
      fn.originalHandler = originalHandler;
      fn.oneOff = oneOff;
      fn.uidEvent = uid;
      handlers[uid] = fn;

      element.addEventListener(typeEvent, fn, hasDelegation);
    }
  }

  static removeHandler(element, events, typeEvent, handler, delegationSelector): void {
    const fn = EventHandler.findHandler(events[typeEvent], handler, delegationSelector);

    if (fn === null) {
      return;
    }

    element.removeEventListener(typeEvent, fn, Boolean(delegationSelector));
    delete events[typeEvent][fn.uidEvent];
  }

  static removeNamespacedHandlers(element, events, typeEvent, namespace): void {
    const storeElementEvent = events[typeEvent] || {};

    for (const handlerKey in storeElementEvent) {
      if (!Object.prototype.hasOwnProperty.call(storeElementEvent, handlerKey)) {
        continue;
      }

      if (handlerKey.indexOf(namespace) > -1) {
        const event = storeElementEvent[handlerKey];
        EventHandler.removeHandler(element, events, typeEvent, event.originalHandler, event.delegationSelector);
      }
    }
  }

  static on(element: Document | Element, events: string, handler?: THandler, delegationFn?): void {
    EventHandler.addHandler(element, events, handler, delegationFn, false);
  }

  static one(element: Document | Element, events: string, handler?: THandler, delegationFn?): void {
    EventHandler.addHandler(element, events, handler, delegationFn, true);
  }

  static off(element: Document | Element, originalTypeEvent: string, handler?: THandler, delegationFn?): void {
    if (typeof originalTypeEvent !== 'string' || typeof element === 'undefined' || element === null) {
      return;
    }

    const [delegation, originalHandler, typeEvent] = EventHandler.normalizeParams(
      originalTypeEvent,
      handler,
      delegationFn
    );

    const inNamespace = typeEvent !== originalTypeEvent;
    const events = EventHandler.getEvent(element);

    if (typeof originalHandler !== 'undefined') {
      // Simplest case: handler is passed, remove that listener ONLY.
      if (!events || !events[typeEvent]) {
        return;
      }

      EventHandler.removeHandler(element, events, typeEvent, originalHandler, delegation ? handler : null);
      return;
    }

    const isNamespace = originalTypeEvent.charAt(0) === '.';
    if (isNamespace) {
      for (const elementEvent in events) {
        if (!Object.prototype.hasOwnProperty.call(events, elementEvent)) {
          continue;
        }

        EventHandler.removeNamespacedHandlers(element, events, elementEvent, originalTypeEvent.substr(1));
      }
    }

    const storeElementEvent = events[typeEvent] || {};
    for (const keyHandlers in storeElementEvent) {
      if (!Object.prototype.hasOwnProperty.call(storeElementEvent, keyHandlers)) {
        continue;
      }

      const handlerKey = keyHandlers.replace(EventHandler.STRIPUID_REGEX, '');
      if (!inNamespace || originalTypeEvent.indexOf(handlerKey) > -1) {
        const event = storeElementEvent[keyHandlers];
        EventHandler.removeHandler(element, events, typeEvent, event.originalHandler, event.delegationSelector);
      }
    }
  }

  static trigger(element, event, args?): any {
    if (typeof event !== 'string' || typeof element === 'undefined' || element === null) {
      return null;
    }

    const typeEvent = event.replace(EventHandler.STRIPNAME_REGEX, '');
    const isNative = typeof NativeEvent[typeEvent] === 'string';

    const bubbles = true;
    const nativeDispatch = true;
    const defaultPrevented = false;

    let evt = null;
    if (isNative) {
      evt = document.createEvent('HTMLEvents');
      evt.initEvent(typeEvent, bubbles, true);
    } else {
      // window.CustomEvent because CustomEvent is a top-level import
      evt = new window.CustomEvent(event, {
        bubbles,
        cancelable: true
      });
    }

    // merge custom informations in our event
    if (typeof args !== 'undefined') {
      Object.keys(args).forEach((key) => {
        Object.defineProperty(evt, key, {
          get() {
            return args[key];
          }
        });
      });
    }

    if (defaultPrevented) {
      evt.preventDefault();
    }

    if (nativeDispatch) {
      element.dispatchEvent(evt);
    }

    return evt;
  }
}

type THandler = string | ((event: any) => any);
