export enum EventName {
  beforehide = 'beforehide',
  click = 'click',
  close = 'close',
  closed = 'closed',
  hide = 'hide',
  hidden = 'hidden',
  input = 'input',
  keydown = 'keydown',
  keyup = 'keyup',
  onchange = 'onchange',
  show = 'show',
  shown = 'shown',
  inserted = 'inserted',
  focusin = 'focusin',
  focusout = 'focusout',
  mouseenter = 'mouseenter',
  mouseleave = 'mouseleave',
  mouseup = 'mouseup',
  mousedown = 'mousedown',
  decrement = 'decrement',
  increment = 'increment'
}
