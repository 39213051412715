import { Formik } from "formik";
import { Box } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
	colorPaletteBlue600,
	textNeutralTertiary,
} from "../../../../../core/theme";
import Modal from "../../../../../common/components/Modal";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";
import { Spacer } from "../../../../../common/components/Spacer";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
const style = {
	container: {
		width: "580px",
		paddingLeft: "24px",
		paddingRight: "24px",
		paddingBottom: "24px",
	},
	titleIcon: {
		color: colorPaletteBlue600,
	},
	description: {
		color: textNeutralTertiary,
		fontSize: 20,
		fontWeight: 400,
		fontFamily: "Lato",
	},
	inputsContainer: {
		gap: "40px",
		display: "flex",
	},
};

export interface GenerateCurtailmentObjectsProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: ({
		startDate,
		endDate,
	}: {
		startDate: string;
		endDate: string;
	}) => void;
}

export const GenerateCurtailmentObjects = ({
	isOpen,
	onClose,
	onSubmit,
}: GenerateCurtailmentObjectsProps) => {
	return (
		<Formik
			enableReinitialize
			initialValues={{
				startDate: "",
				endDate: "",
			}}
			onSubmit={onSubmit}
		>
			{({
				handleChange,
				submitForm,
				resetForm,
				values
			}) => (

				<Modal
					open={isOpen}
					onClose={onClose}
					title={"Generate Curtailment Objects"}
					sx={style.container}
					icon={
						<span style={style.titleIcon}>
							<AddCircleOutlineOutlinedIcon />
						</span>
					}
					actions={
						<>
							<PrimaryButton
								onClick={() => {
									onClose()
									resetForm()
								}}
								text="Cancel"
								type="button"
								color="secondary"
							/>
							<PrimaryButton
								onClick={() => {
									submitForm();
								}}
								text="Generate"
								type="button"
								color="primary"
								startIcon={<AddCircleOutlineOutlinedIcon />}
							/>
						</>
					}
				>
					<Box sx={style.description}>
						Please specify the start and end dates for the period you want
						to generate curtailment objects.
					</Box>
					<Spacer gap={32} />
					<Box sx={style.inputsContainer}>
						<FluidDatePicker
							onChange={handleChange}
							name={"startDate"}
							label={"Start Date (optional)"}
							value={values.startDate}
						/>
						<FluidDatePicker
							onChange={handleChange}
							name={"endDate"}
							label={"End Date (optional)"}
							value={values.endDate}
						/>
					</Box>
					<Spacer gap={32} />
				</Modal>
			)}
		</Formik>
	);
};
