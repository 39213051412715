import GenericTable from "./GenericTable";
import { TriggerUnfoldType } from "./GenericTableRow";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../hooks/default";
import {
	deletePortfolio,
	deleteSiteOnTender,
	refreshSiteCluster,
} from "../../features/tender_form/tenderForm.thunk";

import PortfolioModal from "../../features/tender_form/components/PortfolioModal";
import { useTenderFormSiteTableSubModel } from "../TableGenericModel/useTenderFormSiteTableSubModel";
import { useTenderFormSiteTableModel } from "../TableGenericModel/useTenderFormSiteTableModel";
import {
	Site,
	Portfolio,
	isPortfolio,
} from "../../features/sites/sites.module";
import { deleteSiteFromPortfolio } from "../../features/tender_form/tenderForm.slice";
import { WsMessage } from "../../core/socketProvider";
import { Box } from "@mui/material";
import _ from "lodash";

interface SiteTableProps {
	showRefreshCluster?: boolean;
	model: (Site | Portfolio)[];
	page: number;
	isSelectable?: boolean;
	virtualScrollable: boolean;
	loading?: boolean;
	triggerUnfoldBy?: TriggerUnfoldType.Click | TriggerUnfoldType.Icon;
	showActions?: boolean;
	onOpenPortfolioSiteModal: (id: number) => void;
	isSiteOrPortfolioSelectable?: (
		siteOrPortfolio: Site | Portfolio
	) => boolean;
	getSelectedSites: (data: Site[]) => void;
	updatePage: (page: number) => void;
	updateMessages?: WsMessage<any>[];
	disabled?: boolean;
}

export default function SiteAndPortfolioTable({
	model,
	updatePage,
	page,
	isSelectable,
	virtualScrollable,
	getSelectedSites,
	onOpenPortfolioSiteModal,
	isSiteOrPortfolioSelectable = (siteOrPortfolio: Site | Portfolio) =>
		!!siteOrPortfolio,
	loading = false,
	triggerUnfoldBy = TriggerUnfoldType.Click,
	showActions = false,
	showRefreshCluster = true,
	updateMessages = [],
	disabled = false,
}: SiteTableProps) {
	const [selectedPortfolio, setSelectedPortfolio] = useState<Portfolio>();
	const [openPortfolioModal, setOpenPortfolioModal] =
		useState<boolean>(false);

	const [sortParam, setSortParam] = useState<{
		sortBy: string;
		order: "asc" | "desc";
	}>({
		sortBy: "",
		order: "asc",
	});
	const [modelState, setModelState] = useState<(Site | Portfolio)[]>(
		JSON.parse(JSON.stringify(model))
	);
	const dispatch = useAppDispatch();

	useEffect(() => {
		let arrayCopy = JSON.parse(JSON.stringify(model));
		setModelState(arrayCopy);
	}, [model]);

	const handleSelection = (data: (Site | Portfolio)[]) => {
		if ("children" in data) getSelectedSites(data.children as Site[]);
		else getSelectedSites(data as Site[]);
	};

	const table = useTenderFormSiteTableModel({
		showActions: showActions && !disabled,
		showRefreshCluster: showRefreshCluster,
		onDeletePortfolio: (m: Portfolio) => dispatch(deletePortfolio(m.id)),
		onDeleteSite: (m: Site) => dispatch(deleteSiteOnTender(m)),
		selectPortfolio: (m: Portfolio) => setSelectedPortfolio(() => m),
		renamePortfolio: (rename) => setOpenPortfolioModal(rename),
		openPortfolioSiteModal: (id: number) => {
			onOpenPortfolioSiteModal(id);
		},
		refreshSiteCluster: (m: Site) => dispatch(refreshSiteCluster(m.id)),
	});
	const subTable = useTenderFormSiteTableSubModel({
		showActions: showActions && !disabled,
		showRefreshCluster: showRefreshCluster,
		deleteAction: (parent, m) =>
			dispatch(
				deleteSiteFromPortfolio({
					portfolioId: parent.id,
					site: m as Site,
				})
			),
	});

	useEffect(() => {
		if (!updateMessages && !model) {
			return;
		}
		updateMessages.forEach((m) => {
			let indexes: any[] = [];
			const siteIndex = modelState.findIndex((s) => s.id === m.data.id);
			if (siteIndex < 0) {
				return;
			}

			indexes.push(modelState[siteIndex] as Site);
			modelState
				.flatMap((f) => f.children)
				.filter((f) => f)
				.forEach((f) => {
					if ((f as Site).id === m.data.id) {
						indexes.push(f as Site);
					}
				});

			indexes.forEach((_element, index) => {
				indexes[index].cluster_names = m.data.cluster_names;
				indexes[index].proxy_generation_date =
					m.data.proxy_generation_date;
				indexes[index].proxy_generation_errors =
					m.data.proxy_generation_errors;
				indexes[index].proxy_generation_status =
					m.data.proxy_generation_status;
				indexes[index].could_refresh_cluster_status =
					m.data.could_refresh_cluster_status;
				indexes[index].isNewUpdate = true;
			});

			setModelState([...modelState]);
		});
	}, [updateMessages]);

	useEffect(() => {
		let sortedModelState = modelState;
		if (sortParam?.order && sortParam.sortBy) {
			sortedModelState = _.orderBy(
				modelState,
				[sortParam.sortBy],
				[sortParam.order]
			);
		}
		if (sortedModelState.length > 0) {
			setModelState(sortedModelState);
		}
	}, [sortParam]);

	return (
		<Box sx={{ minWidth: 2200 }}>
			<GenericTable<Site | Portfolio, Site>
				keyExtractor={(data: Site | Portfolio) => {
					if (isPortfolio(data)) {
						return `portfolio_${data.id}`;
					}
					return `site_${data.id}`;
				}}
				virtualScrollable={virtualScrollable}
				selection={handleSelection}
				itemName="Sites"
				updatePage={updatePage}
				page={page}
				model={modelState}
				rowModel={table}
				isSelectable={isSelectable && !disabled}
				loading={loading}
				subRowModel={subTable}
				triggerUnfoldBy={triggerUnfoldBy}
				isRowSelectable={isSiteOrPortfolioSelectable}
				updateSortParam={setSortParam}
				sortParam={sortParam}
			/>
			<PortfolioModal
				open={openPortfolioModal}
				closeModal={() => setOpenPortfolioModal(false)}
				editMode={true}
				portfolio={selectedPortfolio}
			/>
		</Box>
	);
}
