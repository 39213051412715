/**
 * --------------------------------------------------------------------------
 * NJ: sidebar.ts
 * --------------------------------------------------------------------------
 */
import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';
import Util from '../../globals/ts/util';
export default class Sidebar extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-sidebar`;
  public static readonly DATA_KEY = `${Core.KEY_PREFIX}.sidebar`;
  protected static readonly EVENT_KEY = `.${Sidebar.DATA_KEY}`;
  protected static readonly DATA_API_KEY = Core.KEY_PREFIX;
  public static hasInit = false;
  public static readonly CLASS_NAME = {
    folding: `${Core.KEY_PREFIX}-sidebar--folding`,
    folded: `${Core.KEY_PREFIX}-sidebar--folded`
  };

  public static readonly EVENT = {
    show: `${EventName.show}${Sidebar.EVENT_KEY}`,
    shown: `${EventName.shown}${Sidebar.EVENT_KEY}`,
    hide: `${EventName.hide}${Sidebar.EVENT_KEY}`,
    hidden: `${EventName.hidden}${Sidebar.EVENT_KEY}`,
    clickDataApi: `${EventName.click}${Sidebar.EVENT_KEY}${Sidebar.DATA_API_KEY}`
  };

  protected static readonly DEFAULT_OPTIONS = {
    folded: false
  };

  private static readonly DEFAULT_TYPE = {
    folded: 'boolean'
  };

  protected static readonly SELECTOR = {
    default: `.${Sidebar.NAME}`,
    actives: `.${Sidebar.CLASS_NAME.folding}, .${Sidebar.NAME}`,
    dataToggle: '[data-toggle="sidebar"]'
  };

  private isTransitioning: boolean;
  private triggerArray: Element[];

  private selector: string | null;

  constructor(element: HTMLElement, options = {}) {
    super(Sidebar, element, Sidebar.getOptions(options));

    this.element = element;
    this.isTransitioning = false;
    this.triggerArray = Util.makeArray(
      document.querySelectorAll(
        `${Sidebar.SELECTOR.dataToggle}[href="#${element.id}"],` +
          `${Sidebar.SELECTOR.dataToggle}[data-target="#${element.id}"]`
      )
    );

    const toggleList = Util.makeArray(document.querySelectorAll(Sidebar.SELECTOR.dataToggle));

    for (let i = 0, len = toggleList.length; i < len; i++) {
      const elem = toggleList[i];
      const selector = Util.getSelectorFromElement(elem);

      const filterElement = Util.makeArray(document.querySelectorAll(selector)).filter(
        (foundElem) => foundElem === element
      );

      if (selector !== null && filterElement.length) {
        this.selector = selector;
        this.triggerArray.push(elem);
      }
    }

    Data.setData(element, Sidebar.DATA_KEY, this);

    if (this.options.toggle) {
      this.toggle();
    }

    Data.setData(element, Sidebar.DATA_KEY, this);

    if (!Sidebar.hasInit) {
      Sidebar.hasInit = true;
      this.registerEvents();
    }

    if (element.dataset.closeOnInteractOut === 'true' || !element.dataset.closeOnInteractOut) {
      document.addEventListener('click', this.closeOnInteractOut);
      document.addEventListener('keydown', this.closeOnInteractOut);
    }
  }

  toggle(): void {
    if (!this.element.classList.contains(Sidebar.CLASS_NAME.folded)) {
      this.open();
    } else {
      this.close();
    }
  }

  open(): void {
    if (this.isTransitioning || this.element.classList.contains(Sidebar.CLASS_NAME.folded)) {
      return;
    }

    let actives: Element[] | null;
    let activesData;

    const container = document.querySelector(this.selector);
    if (actives) {
      const tempActiveData = actives.filter((elem) => container !== elem);
      activesData = tempActiveData[0] ? Data.getData(tempActiveData[0], Sidebar.DATA_KEY) : null;

      if (activesData && activesData.isTransitioning) {
        return;
      }
    }

    const startEvent = EventHandler.trigger(this.element, Sidebar.EVENT.show);
    if (startEvent.defaultPrevented) {
      return;
    }

    if (actives) {
      actives.forEach((elemActive) => {
        if (container !== elemActive) {
          Sidebar.expandInterface(elemActive, 'hide');
        }

        if (!activesData) {
          Data.setData(elemActive, Sidebar.DATA_KEY, null);
        }
      });
    }

    this.element.classList.remove(Sidebar.CLASS_NAME.folded);
    this.element.classList.add(Sidebar.CLASS_NAME.folding);

    if (this.triggerArray.length) {
      this.triggerArray.forEach((element) => {
        element.classList.remove(Sidebar.CLASS_NAME.folded);
        element.setAttribute('aria-folded', 'true');
      });
    }

    this.setTransitioning(true);

    const complete = (): void => {
      this.element.classList.remove(Sidebar.CLASS_NAME.folding);
      this.element.classList.add(Sidebar.CLASS_NAME.folded);

      if (this.element.parentElement && this.element.parentElement.tagName.toLowerCase() === Sidebar.NAME)
        this.element.parentElement.setAttribute('folded', '');

      this.setTransitioning(false);

      EventHandler.trigger(this.element, Sidebar.EVENT.shown);
    };

    const transitionDuration = Util.getTransitionDurationFromElement(this.element);

    EventHandler.one(this.element, Util.TRANSITION_END, complete);

    Util.emulateTransitionEnd(this.element, transitionDuration);
  }

  close(): void {
    if (this.isTransitioning || !this.element.classList.contains(Sidebar.CLASS_NAME.folded)) {
      return;
    }

    const startEvent = EventHandler.trigger(this.element, Sidebar.EVENT.hide);
    if (startEvent.defaultPrevented) {
      return;
    }

    Util.reflow(this.element);

    this.element.classList.add(Sidebar.CLASS_NAME.folding);
    this.element.classList.remove(Sidebar.CLASS_NAME.folded);

    const triggerArrayLength = this.triggerArray.length;
    if (triggerArrayLength > 0) {
      for (let i = 0; i < triggerArrayLength; i++) {
        const trigger = this.triggerArray[i];
        const selector = Util.getSelectorFromElement(trigger);

        if (selector !== null) {
          const elem = document.querySelector(selector);

          if (!elem.classList.contains(Sidebar.CLASS_NAME.folded)) {
            trigger.classList.remove(Sidebar.CLASS_NAME.folded);
            trigger.setAttribute('aria-folded', 'false');
          }
        }
      }
    }

    this.setTransitioning(true);

    const complete = (): void => {
      if (this.element.parentElement && this.element.parentElement.tagName.toLowerCase() === Sidebar.NAME)
        this.element.parentElement.removeAttribute('folded');

      this.setTransitioning(false);
      this.element.classList.remove(Sidebar.CLASS_NAME.folding);
      EventHandler.trigger(this.element, Sidebar.EVENT.hidden);
    };

    const transitionDuration = Util.getTransitionDurationFromElement(this.element);

    EventHandler.one(this.element, Util.TRANSITION_END, complete);
    Util.emulateTransitionEnd(this.element, transitionDuration);
  }

  setTransitioning(isTransitioning: boolean): void {
    this.isTransitioning = isTransitioning;
  }

  closeOnInteractOut = (e): void => {
    if (!e.key || e.key === 'Escape') {
      if (e.key || !e.target.closest(Sidebar.SELECTOR.default)) this.open();
    }
  };

  dispose(): void {
    document.removeEventListener('click', this.closeOnInteractOut);
    document.removeEventListener('keydown', this.closeOnInteractOut);

    EventHandler.off(document, Sidebar.EVENT.clickDataApi, Sidebar.SELECTOR.dataToggle);
    Data.removeData(this.element, Sidebar.DATA_KEY);

    this.options = null;
    this.element = null;
    this.triggerArray = null;
    this.isTransitioning = null;
  }

  addAriaAndExpandedClass(element, triggerArray): void {
    if (element) {
      const isExpanded = element.classList.contains(Sidebar.CLASS_NAME.folded);

      if (triggerArray.length) {
        triggerArray.forEach((elem) => {
          if (!isExpanded) {
            elem.classList.add(Sidebar.CLASS_NAME.folded);
          } else {
            elem.classList.remove(Sidebar.CLASS_NAME.folded);
          }
          elem.setAttribute('aria-folded', isExpanded);
        });
      }
    }
  }

  private static getOptions(options): any {
    options = {
      ...Sidebar.DEFAULT_OPTIONS,
      ...options
    };
    options.toggle = Boolean(options.toggle); // Coerce string values
    Util.typeCheckConfig(Sidebar.NAME, options, Sidebar.DEFAULT_TYPE);

    return options;
  }

  static getTargetFromElement(element): Element | null {
    const selector = Util.getSelectorFromElement(element);

    return selector ? document.querySelector(selector) : null;
  }

  static expandInterface(element, options): void {
    let data = Data.getData(element, Sidebar.DATA_KEY);
    const cfg = {
      ...Sidebar.DEFAULT_OPTIONS,
      ...Manipulator.getDataAttributes(element),
      ...(typeof options === 'object' && options ? options : {})
    };

    if (!data && cfg.toggle && /show|hide/.test(options)) {
      cfg.toggle = false;
    }

    if (!data) {
      data = new Sidebar(element, cfg);
    }

    if (typeof options === 'string') {
      if (typeof data[options] === 'undefined') {
        throw new Error(`No method named "${options}"`);
      }
      data[options]();
    }
  }

  static getInstance(element: HTMLElement): Sidebar {
    return Data.getData(element, Sidebar.DATA_KEY) as Sidebar;
  }

  static init(options = {}): Sidebar[] {
    return super.init(this, options, Sidebar.SELECTOR.default) as Sidebar[];
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */
  private registerEvents(): void {
    EventHandler.on(document, Sidebar.EVENT.clickDataApi, Sidebar.SELECTOR.dataToggle, function (event) {
      // preventDefault only for <a> elements (which change the URL) not inside the collapsible element
      if (event.target.tagName === 'A') {
        event.preventDefault();
      }

      const triggerData = Manipulator.getDataAttributes(this);
      const selector = Util.getSelectorFromElement(this);
      const selectorElements = Util.makeArray(document.querySelectorAll(selector)) as HTMLElement[];

      selectorElements.forEach((element) => {
        const data = Sidebar.getInstance(element);
        const options = data ? 'toggle' : triggerData;
        Sidebar.expandInterface(element, options);
      });
    });
  }
}
