import { ServicePointType } from "../../../../../requests_geco/sitesApi/sitesApi.types";

type SelectItemsType = { value: string; label: string }[];

export const initialValues = (servicePoint: ServicePointType) => ({
	start_date: servicePoint.start_date ?? "",
	end_date: servicePoint.end_date ?? "",
	name: servicePoint.name ?? "",
	type: String(servicePoint.type?.id) ?? "",
	code_type: String(servicePoint.code_type?.id) ?? "",
	code: servicePoint.code ?? "",
	code_type_2: String(servicePoint.code_type_2?.id) ?? "",
	code_2: "",
	localconso: servicePoint.localconso ? true : false,
	tso: String(servicePoint.tso.id) ?? "",
	dso: String(servicePoint.dso.id) ?? "",
	eic_balancing_group: String(servicePoint.eic_balancing_group?.id) ?? "",
	marketing_type: servicePoint.marketing_type,
});

export const onSubmitForm = (
	values: any,
	servicePointTypes: SelectItemsType,
	servicePointCodeTypes: SelectItemsType,
	tsos: SelectItemsType,
	dsos: SelectItemsType,
	eicBalancingGroups: SelectItemsType,
	onSubmit: (values: any) => void,
	code2list: string[]
) => {
	const selectedType =
		servicePointTypes.find((type) => type.value === values.type) ?? null;
	const selectedCodeType =
		servicePointCodeTypes.find(
			(codeType) => codeType.value === values.code_type
		) ?? null;
	const selectedCodeType2 =
		servicePointCodeTypes.find(
			(codeType) => codeType.value === values.code_type_2
		) ?? null;
	const selectedTso = tsos.find((tso) => tso.value === values.tso) ?? null;
	const selectedDso = dsos.find((dso) => dso.value === values.dso) ?? null;
	const selectedEicBalancingGroup =
		eicBalancingGroups.find(
			(eicBalancingGroup) =>
				eicBalancingGroup.value === values.eic_balancing_group
		) ?? null;

	onSubmit({
		...values,
		code_2: code2list,
		type:
			selectedType !== null
				? {
						id: Number(selectedType.value),
						name: selectedType.label,
				  }
				: null,
		code_type:
			selectedCodeType !== null
				? {
						id: Number(selectedCodeType.value),
						name: selectedCodeType.label,
				  }
				: undefined,
		code_type_2:
			selectedCodeType2 !== null
				? {
						id: Number(selectedCodeType2.value),
						name: selectedCodeType2.label,
				  }
				: undefined,
		tso:
			selectedTso !== null
				? {
						id: Number(selectedTso.value),
						name: selectedTso.label,
				  }
				: null,
		dso:
			selectedDso !== null
				? {
						id: Number(selectedDso.value),
						name: selectedDso.label,
				  }
				: null,
		eic_balancing_group:
			selectedEicBalancingGroup !== null
				? {
						id: Number(selectedEicBalancingGroup.value),
						name: selectedEicBalancingGroup.label,
				  }
				: null,
		marketing_type: values.marketing_type || null,
	});
};
