import Autocomplete from './autocomplete';
import PasswordInput from './password-input';
import SearchInput from './search-input';
import TextInput from './text-input';
import TextareaInput from './textarea-input';

export default class Form {
  static TextInput = TextInput;
  static SearchInput = SearchInput;
  static PasswordInput = PasswordInput;
  static TextareaInput = TextareaInput;
  static Autocomplete = Autocomplete;

  protected static readonly SELECTOR = {
    default: `${TextInput.SELECTOR.default}, ${SearchInput.SELECTOR.default}, ${TextareaInput.SELECTOR.default}, ${Autocomplete.SELECTOR.default}`
  };

  static init(optionsPassword = {}, optionsSearch = {}, optionsText = {}, optionsTextarea = {}): Form[] {
    const passwordInput = PasswordInput.init(optionsPassword);
    const searchInput = SearchInput.init(optionsSearch);
    const textInput = TextInput.init(optionsText);
    const textareaInput = TextareaInput.init(optionsTextarea);
    const autocomplete = Autocomplete.init();

    return [passwordInput, searchInput, textInput, textareaInput, autocomplete];
  }
}
