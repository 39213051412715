import { Theme, Typography, Checkbox, Tooltip, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Fragment, useState } from "react";
import { SystemProps } from "@mui/system";
import { GenericModel } from "./GenericTable";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { darkGrey, primaryColor } from "../../core/theme";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import GenericHeaderCell from "./GenericHeaderCell";
import { If } from "./If";

export interface ColModel<TModel> {
	id: string;
	header: () => any;
	filterBy?: boolean;
	sortable?: boolean;
	xs?: number;
	align?: string;
	sx?: SystemProps<Theme>;
	accessor: (model: TModel, index: number) => any;
	hidden?: boolean;
	updateSortParam?: (sort: { sortBy: string; order: "asc" | "desc" }) => void;
	sortParam?: { sortBy: string; order: "asc" | "desc" };
}

export interface ColSubModel<TSubModel> {
	id: string;
	header: () => any;
	filterBy?: boolean;
	sortable?: boolean;
	xs?: number;
	align?: string;
	sx?: SystemProps<Theme>;
	accessor: (model: TSubModel, index: number, parent?: TSubModel) => any;
	hidden?: boolean;
}

export enum TriggerUnfoldType {
	Icon,
	Click,
}

const style: any = {
	tableCell: {
		display: "flex",
		alignItems: "center",

		"& h6": {
			// width: "80%",
		},
		"& div": {
			display: "flex",
			alignItems: "center",
		},
		paddingLeft: "0px",
		"& svg, & img": {
			float: "left",
			padding: "2px 5px 0 0",
		},
	},
};

function GenericTableRow<
	TModel extends GenericModel,
	TSubModel extends GenericModel
>(props: {
	isSelected: boolean;
	isSelectable?: boolean;
	isRowSelectable?: (dataModel: TModel) => boolean;
	data: TModel;
	cols: ColModel<TModel>[];
	subCols?: ColSubModel<TSubModel>[];
	handleRowSelection: (data: TModel, selected: boolean) => void;
	index: number;
	displaySubRowheader?: boolean;
	triggerUnfoldBy?: TriggerUnfoldType.Click | TriggerUnfoldType.Icon;
}) {
	const {
		data,
		cols,
		subCols,
		isSelectable,
		isSelected,
		handleRowSelection,
		isRowSelectable = (dataModel: TModel) => !!dataModel,
		displaySubRowheader,
		triggerUnfoldBy,
	} = props;
	const [open, setOpen] = useState<boolean>(false);
	return (
		<>
			<Fragment>
				{isSelectable && (
					<Grid key={-1} item xs={0.3} sx={style.tableCell}>
						<If condition={isRowSelectable(data)}>
							<Checkbox
								checked={isSelected}
								onChange={() => {
									handleRowSelection(data, !isSelected);
								}}
							/>
						</If>
						<If condition={!isRowSelectable(data)}>
							<Box component={"span"} sx={{ padding: "9px" }}>
								<Tooltip
									sx={{
										marginLeft: "3px",
										marginTop: "-3px",
									}}
									title={
										("missing_data" in data
											? (
													data.missing_data as string
											  ).slice(0, -2)
											: "") + " not found."
									}
								>
									<HelpOutlineIcon
										sx={{ color: darkGrey }}
									></HelpOutlineIcon>
								</Tooltip>
							</Box>
						</If>
					</Grid>
				)}
				<Grid key={0} item xs={0.3} sx={style.tableCell}>
					{"children" in data &&
						triggerUnfoldBy == TriggerUnfoldType.Icon && (
							<IconButton
								aria-label="expand row"
								size="small"
								onClick={(event) => {
									event.stopPropagation();
									setOpen(!open);
								}}
							>
								{open ? (
									<KeyboardArrowDownIcon
										sx={{ color: primaryColor }}
									/>
								) : (
									<KeyboardArrowRightIcon
										sx={{ color: primaryColor }}
									/>
								)}
							</IconButton>
						)}
				</Grid>

				{cols.map((col: ColModel<TModel>, i) => (
					<Fragment key={i}>
						{!col.hidden && (
							<Grid
								key={i}
								item
								xs={col.xs}
								sx={{
									...style.tableCell,
									minHeight: "44px",
									maxWidth: "100%",
									cursor:
										triggerUnfoldBy ==
											TriggerUnfoldType.Click &&
										"children" in data
											? "pointer"
											: "",
								}}
								onClick={() =>
									triggerUnfoldBy ==
										TriggerUnfoldType.Click &&
									"children" in data
										? setOpen(!open)
										: ""
								}
							>
								<Typography
									variant="subtitle2"
									sx={{ maxWidth: "100%" }}
								>
									{col.accessor(data, props.index)}
								</Typography>
							</Grid>
						)}
					</Fragment>
				))}
			</Fragment>
			{open && (
				<Fragment>
					{displaySubRowheader && (
						<Grid key={0} item xs={0.6} sx={style.tableCell}>
							<Typography
								paragraph
								sx={{ marginBottom: "40px" }}
							/>
						</Grid>
					)}
					{displaySubRowheader &&
						subCols &&
						subCols.map((headerCell: ColModel<TSubModel>) => (
							<GenericHeaderCell
								key={headerCell.id}
								{...headerCell}
							/>
						))}

					{"children" in data &&
						data.children &&
						data.children.map((m: GenericModel, j) => (
							<Fragment key={j}>
								{triggerUnfoldBy == TriggerUnfoldType.Icon &&
									isSelectable && (
										<Grid
											key={`sub-1`}
											item
											xs={0.3}
											sx={{
												...style.tableCell,
												minHeight: "44px",
											}}
										>
											<Typography
												paragraph
												sx={{ marginBottom: "40px" }}
											/>
										</Grid>
									)}
								<Grid
									key={`sub-0`}
									item
									xs={0.3}
									sx={style.tableCell}
								>
									<Typography
										paragraph
										sx={{ marginBottom: "40px" }}
									/>
								</Grid>
								{subCols &&
									subCols.map(
										(col: ColSubModel<TSubModel>, i) => (
											<Fragment key={`sub-${i}`}>
												{!col.hidden && (
													<Grid
														key={i}
														item
														xs={col.xs}
														sx={style.tableCell}
													>
														<Typography
															variant="subtitle2"
															component="p"
															sx={{
																maxWidth:
																	"100%",
															}}
														>
															{col.accessor(
																m as TSubModel,
																props.index,
																data as any
															)}
														</Typography>
													</Grid>
												)}
											</Fragment>
										)
									)}
							</Fragment>
						))}
				</Fragment>
			)}
		</>
	);
}

export default GenericTableRow;
