/**
 * --------------------------------------------------------------------------
 * NJ : CollapseSearchBar.ts
 * --------------------------------------------------------------------------
 */
import { Core, EventName } from '../../globals/ts/enum';
import AbstractFormBase from '../../globals/ts/abstract-form-base';
import Collapse from '../collapse';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';
import Testing from '../../globals/ts/testing';

export default class CollapseSearchBar extends AbstractFormBase {
  protected static readonly NAME = 'collapseSearchBar';
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.${CollapseSearchBar.NAME}`;

  static readonly SELECTOR = {
    default: `.${Core.KEY_PREFIX}-navbar__search`,
    formGroup: AbstractFormBase.SELECTOR.formGroup,
    anyInput: 'input, select, textarea',
    target: 'data-target'
  };

  protected static readonly DEFAULT_OPTIONS = {
    njFormGroup: {
      required: false
    }
  };

  private triggerElement: Element;
  private input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

  constructor(element: HTMLElement, options = {}) {
    super(CollapseSearchBar, element, Manipulator.extend(true, CollapseSearchBar.DEFAULT_OPTIONS, options));

    this.triggerElement =
      this.element.parentElement.querySelector(
        `[data-toggle="collapse"][${CollapseSearchBar.SELECTOR.target}="#${this.element.id}"]`
      ) || this.element.parentElement.querySelector(`[data-toggle="collapse"][href="#${this.element.id}"]`);

    const closedElement = this.element.querySelector(`[data-dismiss="#${this.element.id}"]`);
    if (closedElement) {
      closedElement.addEventListener('click', this.dismissHandler.bind(this));
    }

    Testing.assert(element, !this.triggerElement, `Cannot find collapse trigger for ${Testing.describe(element)}`);

    Testing.assert(
      element,
      !this.element.classList.contains(Collapse.CLASS_NAME.collapse),
      `${Testing.describe(element)} is expected to have the '${
        Collapse.CLASS_NAME.collapse
      }' class.  It is being targeted by ${Testing.describe(element)}`
    );

    this.input = this.element.querySelector(CollapseSearchBar.SELECTOR.anyInput);

    if (this.input) {
      // add a listener to set focus
      EventHandler.on(this.element, `${EventName.shown}.${Collapse.DATA_KEY}`, () => {
        this.input.focus();
      });

      // add a listener to collapse field
      EventHandler.on(this.input, 'blur', (event) => {
        this.dismissHandler(event);
      });
    }
  }

  getElement(): HTMLElement {
    return this.element;
  }

  dispose(): void {
    Data.removeData(this.element, CollapseSearchBar.DATA_KEY);
    this.element = null;
  }

  static init(options = {}): CollapseSearchBar[] {
    return super.init(this, options, CollapseSearchBar.SELECTOR.default) as CollapseSearchBar[];
  }

  static getInstance(element: HTMLElement): CollapseSearchBar | Collapse {
    const data = Data.getData(element, CollapseSearchBar.DATA_KEY) as CollapseSearchBar;

    return data ? data : Collapse.getInstance(element);
  }

  dismissHandler(event): void {
    event.preventDefault();
    if (
      event.target.closest(`[data-dismiss="#${this.element.id}"]`) ||
      event.target.closest(`[href="#${this.element.id}"]`)
    ) {
      EventHandler.trigger(this.triggerElement, EventName.click);
    }
  }
}
