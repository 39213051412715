import Button, { ButtonProps } from "@mui/material/Button";
import { ReactNode } from "react";
import { SxProps, Theme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

const style: { [key: string]: SxProps<Theme> } = {
	primaryButton: {
		boxShadow: "none",
		borderRadius: 0,
		textTransform: "capitalize",
		fontSize: 14,
		minWidth: 167,
		height: 40,
		"&.MuiButton-containedPrimary:focus,&.MuiButton-containedPrimary:hover":
			{
				color: "#fff",
			},
		"&.MuiButton-containedInfo:focus,&.MuiButton-containedInfo:hover": {
			color: "#171D21",
		},
		"&.MuiButton-containedSecondary:focus,&.MuiButton-containedSecondary:hover,&.MuiButton-containedSecondary:visited":
			{
				color: "#000",
				textDecoration: "none",
			},
	},
};

interface CustomButtonProps extends ButtonProps {
	children?: ReactNode;
	text?: string;
	loader?: boolean;
	testId?: string;
	isDisabled?: boolean;
	target?: string;
}

export function PrimaryButton(props: CustomButtonProps) {
	const { text, loader, sx, isDisabled, ...other } = props;
	return (
		<Button
			data-testid={props.testId}
			variant="contained"
			startIcon={props.children}
			{...other}
			sx={{ ...style.primaryButton, ...sx } as any}
			disabled={isDisabled}
		>
			{loader && <CircularProgress size="1rem" />}
			{!loader && text}
		</Button>
	);
}
