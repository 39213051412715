import { secondaryColor } from "../../core/theme";
import { Box, SxProps, Theme } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { ColModel } from "./GenericTableRow";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
const style: {
	[key: string]: SxProps<Theme>;
} = {
	headerCellContainer: {
		backgroundColor: secondaryColor,
		color: "#000",
		fontWeight: 700,
		fontSize: 12,
		paddingLeft: 0,
		display: "flex",
		alignItems: "center",
		"& div": {
			display: "flex",
			alignItems: "center",
		},
	},
	orderByButton: {
		color: "#000",
		padding: 0,
		backgroundColor: secondaryColor,
		marginRight: 0,
	},
};

function GenericHeaderCell<TModel>(props: ColModel<TModel>) {
	const applySorting = () => {
		if (props.updateSortParam && props.sortable) {
			if (
				props.sortParam?.order === "asc" &&
				props.sortParam?.sortBy === props.id
			) {
				props.updateSortParam({
					sortBy: props.id,
					order: "desc",
				});
			} else if (
				props.sortParam?.order === "desc" &&
				props.sortParam?.sortBy === props.id
			) {
				props.updateSortParam({
					sortBy: "",
					order: "asc",
				});
			} else {
				props.updateSortParam({
					sortBy: props.id,
					order: "asc",
				});
			}
		}
	};

	return (
		<>
			{!props.hidden && (
				<Grid
					item
					xs={props.xs ? props.xs : 1}
					sx={{ ...style.headerCellContainer, ...props.sx }}
				>
					<Box
						sx={{
							justifyContent:
								props.align === "right" ? "end" : "start",
							cursor: "pointer",
						}}
						onClick={applySorting}
					>
						<>
							{props.sortable &&
								props.sortParam?.sortBy === props.id && (
									<IconButton
										aria-label="orderBy"
										sx={style.orderByButton}
									>
										{props.sortParam?.order === "asc" && (
											<KeyboardDoubleArrowDownIcon
												sx={{ fontSize: 20 }}
											/>
										)}
										{props.sortParam?.order === "desc" && (
											<KeyboardDoubleArrowUpIcon
												sx={{ fontSize: 20 }}
											/>
										)}
									</IconButton>
								)}
							{props.header()}
						</>
						{!!props.filterBy && (
							<ArrowDropDownIcon sx={{ fontSize: 20 }} />
						)}
					</Box>
				</Grid>
			)}
		</>
	);
}

export default GenericHeaderCell;
