import Data from './data';

/**
 * --------------------------------------------------------------------------
 * NJ : abstract-component.ts
 * --------------------------------------------------------------------------
 */

export default abstract class AbstractComponent {
  // Constants
  protected static DATA_KEY: string; // `${coreConfig.KEY_PREFIX}.componentName`
  protected static DATA_API_KEY: string; // coreConfig.KEY_PREFIX
  protected static DEFAULT_OPTIONS: { [key: string]: any };
  protected static EVENT_KEY: string; // `.${DATA_KEY}`
  protected static NAME: string; // 'componentName'
  protected static SELECTOR: { [key: string]: string };

  protected options: any;
  protected element: HTMLElement;

  protected constructor(Component: any, element?: HTMLElement, options = {}) {
    // Display error if el is valid HTML Element
    if (element && !(element instanceof Element)) {
      console.error(Error(`${element} is not an HTML Element`));
    }

    this.options = options;
    this.element = element;
  }

  protected static init(Component: any, options = {}, selector: string): any[] {
    const instances = [];
    const components = document.querySelectorAll(selector);
    for (let i = 0; i < components.length; i++) {
      const el: AbstractElement = components[i];
      if (!el.key || el.key.key !== Component.DATA_KEY) {
        const instance = new Component(components[i], options);
        if (!el.key) Data.setData(components[i], Component.DATA_KEY, instance);
        instances.push(instance);
      }
    }

    return instances;
  }

  /**
   * Destroys an element's component
   */
  abstract dispose(): void;
}

interface AbstractElement extends Element {
  [key: string]: any;
}
