import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
	Box,
	Chip,
	FormGroup,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { If } from "../../../../common/components/If";
import { Tender } from "../../../tender_page/tender.module";
import { PricingStatus, Action, Pricing } from "../../pricingListSlice";
import useIsAuthorized from "../../../../common/hooks/useIsAuthorized";
import { PricingRunStatusTransitions } from "../../pricingList.constant";
import { isUserAuthorizedToCreatePricings } from "../../../../common/authorization/tender.authorization";
import { TenderActionIcon } from "./TenderActionIcon";
import TuneIcon from "@mui/icons-material/Tune";
import { useAppDispatch } from "../../../../common/hooks/default";
import { Dispatch } from "react";
import { setFiltersDrawerOpened } from "../../../filters/filtersSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { getPathWithParameters, PATH } from "../../../../router-path";
import {
	Column,
	ColumnsSelector,
} from "../../../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../../hooks/columns/common";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { ShowHideProvisionColumnsButton } from "../../../../common/components/ShowHideProvisionColumnsButton/ShowHideProvisionColumnsButton";

interface TenderActionsProps {
	valueOrPercent: string;
	setValueOrPercent: (newValue: "value" | "percent") => void;
	onPricingsDownload: (
		tender_id: number,
		status_in: PricingStatus[],
		format: string,
		id_in?: number[] | null
	) => void;
	canPrintUnderPricing: boolean;
	tender: Tender | undefined;
	pricings: Pricing[] | null | undefined;
	filterCount: number;
	actOnPricings: (action: Action, pricingIds?: number[]) => void;
	selectedColumns: Column<ColumnIds>[];
	onColumnToggled: (column: Column<ColumnIds>) => void;
	availableColumns: Column<ColumnIds>[];
}

export function TenderActions(props: TenderActionsProps) {
	const {
		valueOrPercent,
		setValueOrPercent,
		onPricingsDownload,
		canPrintUnderPricing,
		tender,
		pricings,
		filterCount,
		actOnPricings,
		selectedColumns,
		onColumnToggled,
		availableColumns,
	} = props;
	const dispatch: Dispatch<any> = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const canCreatePricings = useIsAuthorized<Tender>(
		isUserAuthorizedToCreatePricings,
		tender
	);

	let pageUrl = `${getPathWithParameters(PATH.MAXIMIZED_TENDER_PRICING_RUN, {
		tenderId: tender?.id,
	})}`;

	if (location.pathname.includes("/maximized")) {
		pageUrl = `${getPathWithParameters(PATH.TENDER_PRICING_RUN, {
			tenderId: tender?.id,
		})}`;
	}

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			sx={{ width: "100%", marginTop: "20px" }}
		>
			<Box display="flex" justifyContent="flex-start" alignItems="center">
				<ToggleButtonGroup
					sx={{
						height: "25px",
						paddingRight: "30px",
						marginTop: "7px",
					}}
					value={valueOrPercent}
					exclusive
					onChange={(_, choice) => {
						if (choice !== null) {
							setValueOrPercent(choice);
						}
					}}
					aria-label="value-or-percentage"
				>
					<ToggleButton
						value="value"
						aria-label="value"
						sx={{
							borderRadius: 0,
							textTransform: "none",
						}}
					>
						Value
					</ToggleButton>
					<ToggleButton
						value="percent"
						aria-label="percentage"
						sx={{ borderRadius: 0 }}
					>
						%
					</ToggleButton>
				</ToggleButtonGroup>
				{/* <ToggleButtonGroup
					sx={{
						height: "25px",
						paddingRight: "30px",
						marginTop: "7px",
					}}
					value={activePricings}
					exclusive
					onChange={(_, choice) => {
						setActivePricings(choice);
					}}
					aria-label="Active Pricings"
				>
					<ToggleButton
						value="activeonly"
						aria-label="Active only"
						sx={{ borderRadius: 0, textTransform: "none" }}
					>
						Active only
					</ToggleButton>
					<ToggleButton
						value="all"
						aria-label="value"
						sx={{
							borderRadius: 0,
							textTransform: "none",
						}}
					>
						All
					</ToggleButton>
				</ToggleButtonGroup> */}
				<FormGroup>
					<PrimaryButton
						sx={{
							width: 140,
							marginRight: "30px",
						}}
						startIcon={<OpenInFullIcon />}
						type="button"
						color="secondary"
						id="column-maximized-button"
						role="link"
						href={pageUrl}
						text="Full screen"
						onClick={(e) => {
							e.preventDefault();
							navigate(pageUrl);
							return false;
						}}
					/>
				</FormGroup>
				<FormGroup>
					<PrimaryButton
						sx={{ marginRight: "30px" }}
						text="Export Pricings"
						type="button"
						color="secondary"
						disabled={pricings?.length === 0}
						onClick={() => {
							onPricingsDownload(
								tender?.id || 0,
								[],
								valueOrPercent
							);
						}}
					></PrimaryButton>
				</FormGroup>
				<If condition={canPrintUnderPricing}>
					<FormGroup>
						<PrimaryButton
							sx={{ marginRight: "30px" }}
							text="Export Under Offer"
							type="button"
							color="secondary"
							onClick={() => {
								onPricingsDownload(
									tender?.id || 0,
									[PricingStatus.UNDER_OFFER],
									valueOrPercent
								);
							}}
						></PrimaryButton>
					</FormGroup>
				</If>
				<FormGroup>
					<PrimaryButton
						text="Filters"
						type="button"
						color="secondary"
						data-testid="open-filters"
						sx={{
							width: 120,
							marginLeft: "10px",
						}}
						onClick={() =>
							dispatch(
								setFiltersDrawerOpened({
									opened: true,
								})
							)
						}
						startIcon={<TuneIcon />}
						endIcon={
							<Chip
								label={
									<div style={{ fontSize: "13px" }}>
										{filterCount}
									</div>
								}
								size="small"
							/>
						}
					/>
				</FormGroup>
				<FormGroup>
					<ColumnsSelector
						availableColumns={availableColumns}
						selectedColumns={selectedColumns}
						onColumnToggled={onColumnToggled}
					/>
				</FormGroup>
				<FormGroup>
					<ShowHideProvisionColumnsButton
						availableColumns={availableColumns}
						selectedColumns={selectedColumns}
						onColumnToggled={onColumnToggled}
					/>
				</FormGroup>
				<FormGroup>
					<PrimaryButton
						text="Pricing bricks description"
						type="button"
						role="link"
						href="https://confluence.tools.digital.engie.com/x/aq1LEw"
						color="secondary"
						sx={{
							marginLeft: "16px",
							width: 230,
						}}
						target="_blank"
					>
						<HelpOutlineOutlinedIcon />
					</PrimaryButton>
				</FormGroup>
				{(tender?.actions || []).map((action, index) => (
					<PrimaryButton
						key={index}
						sx={{ marginLeft: "15px" }}
						text={action.display_name}
						type="submit"
						color="primary"
						onClick={() => {
							actOnPricings(action);
						}}
					>
						<TenderActionIcon
							transition={
								action.action as PricingRunStatusTransitions
							}
						/>
					</PrimaryButton>
				))}
				{canCreatePricings && (
					<PrimaryButton
						text="Add pricing"
						type="submit"
						color="primary"
						role="link"
						sx={{ marginLeft: "15px" }}
						href={`${getPathWithParameters(PATH.PRICING_REQUEST, {
							tenderId: tender?.id,
						})}?backPage=${location.pathname}`}
						onClick={(e) => {
							e.preventDefault();
							navigate(
								`${getPathWithParameters(PATH.PRICING_REQUEST, {
									tenderId: tender?.id,
								})}?backPage=${location.pathname}`
							);
							return false;
						}}
					>
						<AddCircleIcon sx={{ color: "#fff" }} />
					</PrimaryButton>
				)}
			</Box>
		</Box>
	);
}
