/**
 * --------------------------------------------------------------------------
 * NJ : abstract-form-base.ts
 * --------------------------------------------------------------------------
 */

import AbstractComponent from './abstract-component';
import Testing from './testing';

export default abstract class AbstractFormBase extends AbstractComponent {
  protected static readonly CLASS_NAME: any = {
    njFormGroup: 'nj-form-group',
    isFilled: 'is-filled',
    isFocused: 'is-focused'
  };

  protected static readonly SELECTOR = {
    formGroup: `.${AbstractFormBase.CLASS_NAME.njFormGroup}`
  };

  protected element: HTMLInputElement;
  protected njFormGroup: any;

  constructor(component, element: HTMLElement, options = {}, properties = {}) {
    super(component, element, options);

    // set properties for use in the constructor initialization
    for (const key in properties) {
      if ({}.hasOwnProperty.call(properties, key)) {
        this[key] = properties[key];
      } else {
        console.error(`${key} does not exist in properties`);
      }
    }
  }

  addFormGroupFocus(): void {
    if (!this.element) {
      return;
    }
    if (!this.element.disabled === true) {
      this.njFormGroup.classList.add(AbstractFormBase.CLASS_NAME.isFocused);
    }
  }

  removeFormGroupFocus(): void {
    this.njFormGroup.classList.remove(AbstractFormBase.CLASS_NAME.isFocused);
  }

  addIsFilled(): void {
    this.njFormGroup.classList.add(AbstractFormBase.CLASS_NAME.isFilled);
  }

  removeIsFilled(): void {
    this.njFormGroup.classList.remove(AbstractFormBase.CLASS_NAME.isFilled);
  }

  findFormGroup(raiseError = true): Element | null {
    const formGroup = this.element.closest(AbstractFormBase.SELECTOR.formGroup);

    if (formGroup === null && raiseError) {
      console.error(`Failed to find ${AbstractFormBase.SELECTOR.formGroup} for ${Testing.describe(this.element)}`);
    }

    return formGroup;
  }
}
