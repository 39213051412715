/**
 * --------------------------------------------------------------------------
 * NJ: password-input.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';
export default class PasswordInput extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-form-input-password`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.password-input`;
  static readonly SELECTOR = {
    default: `.${PasswordInput.NAME}`
  };
  private static readonly INPUT_CLASS = `${Core.KEY_PREFIX}-form-control`;
  private static readonly REVEAL_BUTTON_CLASS = `${Core.KEY_PREFIX}-form-control__password-off`;
  private static readonly HIDE_BUTTON_CLASS = `${Core.KEY_PREFIX}-form-control__password-on`;
  protected static readonly DEFAULT_OPTIONS = {
    selector: PasswordInput.SELECTOR
  };

  constructor(element: HTMLElement, options = {}) {
    super(PasswordInput, element, Manipulator.extend(true, PasswordInput.DEFAULT_OPTIONS, options));

    this.setListeners();
    Data.setData(element, PasswordInput.DATA_KEY, this);
  }

  static init(options = {}): PasswordInput[] {
    return super.init(this, options, PasswordInput.SELECTOR.default) as PasswordInput[];
  }

  static getInstance(element: HTMLElement): PasswordInput {
    return Data.getData(element, PasswordInput.DATA_KEY) as PasswordInput;
  }

  dispose(): void {
    Data.removeData(this.element, PasswordInput.DATA_KEY);
    this.element = null;
  }

  private setListeners(): void {
    const input = this.element.getElementsByClassName(PasswordInput.INPUT_CLASS)[0] as HTMLInputElement;

    const revealButton = this.element.getElementsByClassName(PasswordInput.REVEAL_BUTTON_CLASS)[0];
    EventHandler.on(revealButton, 'click', () => {
      this.element.classList.add('is-visible');
      input.type = 'text';
    });

    const hideButton = this.element.getElementsByClassName(PasswordInput.HIDE_BUTTON_CLASS)[0];
    EventHandler.on(hideButton, 'click', () => {
      this.element.classList.remove('is-visible');
      input.type = 'password';
    });
  }
}
