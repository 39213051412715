import AbstractComponent from './abstract-component';
window.NJStore = window.NJStore || [];

const mapData = ((): { set; get; delete: (element: any, key: string) => void } => {
  const storeData = window.NJStore;
  return {
    set(element, key: string, data: AbstractComponent): void {
      if (typeof element.key === 'undefined') {
        element.key = {
          key,
          id: storeData.length
        };
      }

      storeData[element.key.id] = data;
    },
    get(element, key: any): AbstractComponent {
      if (element.key && key && typeof key.id === 'undefined') key = element.key;
      if (key && typeof key.id !== 'undefined') {
        return storeData[key.id];
      }
      return null;
    },
    delete(element, key: string): void {
      if (typeof element.key === 'undefined') {
        return;
      }

      const keyProperties = element.key;
      if (keyProperties.key === key) {
        delete storeData[keyProperties.id];
        delete element.key;
      }
    }
  };
})();

const Data = {
  setData(instance, key: string, data: AbstractComponent): void {
    mapData.set(instance, key, data);
  },
  getData(element, key: any): AbstractComponent {
    return mapData.get(element, key);
  },
  removeData(instance, key: string): void {
    mapData.delete(instance, key);
  }
};

export default Data;

declare global {
  interface Window {
    NJStore: any;
  }
}
