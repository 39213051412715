import React, { useCallback, useMemo, useState } from "react";
import { GroupedField } from "../grouping/grouping.module";
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import * as _ from "lodash";
import { If } from "../../common/components/If";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PricingList } from "./pricingList";
import { lightGrey } from "../../core/theme";
import { FlashUpdate } from "../../common/components/FlashUpdate";
import { LinqPredicate } from "../filters/utils";
import { Column } from "../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../pricing_list/hooks/columns/common";
import { PricingStatus } from "../pricing_list/pricingListSlice";

const styles = {
	icon: {
		fontSize: "14px",
	},
	iconExpanded: {
		transform: "rotate(90deg)",
	},
	valueCellWrapper: {
		position: "relative",
		height: "1em",
		padding: "8px",
	},
	cellWrapper: {
		gap: "8px",
		alignItems: "center",
		display: "flex",
		flexDirection: "row",
		position: "absolute",
		top: 0,
		bottom: 0,
		whiteSpace: "nowrap",
		maxHeight: "34px",
	},
	clickable: {
		cursor: "pointer",
	},
	hidden: {
		display: "none",
	},
};

export interface PricingGroupProps {
	groupedField: GroupedField;
	depth: number;
	maxDepth: number;
	tenderId?: number;
	hide?: boolean;
	odataQuery?: string;
	rootPredicates?: LinqPredicate[];
	selectedColumns: Column<ColumnIds>[];
	onPricingsDownload: (
		tender_id: number,
		status_in: PricingStatus[],
		format: string,
		id_in?: number[] | null
	) => void;
}

export function PricingGroup(props: PricingGroupProps) {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggle = useCallback(
		() => setIsExpanded((prev) => !prev),
		[setIsExpanded]
	);
	const arrowStyle = useMemo(() => {
		let style = { ...styles.icon };
		if (isExpanded) {
			style = { ...style, ...styles.iconExpanded };
		}
		return style;
	}, [isExpanded]);

	return (
		<>
			<TableRow
				sx={{
					maxHeight: "34px",
					...styles.clickable,
					...(props.hide ? styles.hidden : {}),
				}}
				onClick={toggle}
			>
				{_.range(props.depth).map((index) => (
					<TableCell key={index} />
				))}
				<TableCell
					sx={styles.valueCellWrapper}
					data-testid={`group-${props.groupedField.field_key}-${props.groupedField.field_value}`}
					data-toggle={isExpanded ? "open" : "false"}
				>
					<FlashUpdate
						field={props.groupedField.count}
						isUpdate={props.groupedField.isNewUpdate}
						sx={styles.cellWrapper}
					>
						<ArrowForwardIosIcon sx={arrowStyle} />
						<Typography
							sx={{ fontSize: "16px", userSelect: "none" }}
						>
							{props.groupedField.field_value}(
							{props.groupedField.count})
						</Typography>
					</FlashUpdate>
				</TableCell>
				{_.range(props.maxDepth - props.depth - 1).map((index) => (
					<TableCell key={index} />
				))}
				<TableCell sx={{ width: "100%" }}></TableCell>
			</TableRow>
			{props.groupedField.children?.map((child) => (
				<PricingGroup
					onPricingsDownload={props.onPricingsDownload}
					selectedColumns={props.selectedColumns}
					odataQuery={props.odataQuery}
					hide={!isExpanded || props.hide}
					tenderId={props.tenderId}
					key={child.field_key + child.field_value}
					depth={props.depth + 1}
					maxDepth={props.maxDepth}
					groupedField={child}
				/>
			))}
			<If
				condition={
					isExpanded &&
					!props.hide &&
					!props.groupedField.children?.length
				}
			>
				<TableRow
					sx={{
						padding: 0,
						maxWidth: "100%",
					}}
				>
					<TableCell
						colSpan={props.maxDepth + 1}
						sx={{
							margin: 0,
							padding: 0,
							backgroundColor: lightGrey,
						}}
					>
						<Box
							sx={{
								maxWidth: "100%",
								height: "100%",
							}}
						>
							<PricingList
								onPricingsDownload={props.onPricingsDownload}
								selectedColumns={props.selectedColumns}
								rootPredicates={props.rootPredicates}
								odataQuery={props.odataQuery}
								tenderId={props.tenderId}
								group={props.groupedField}
							/>
						</Box>
					</TableCell>
				</TableRow>
			</If>
		</>
	);
}
