import Modal from "../../../../common/components/Modal";
import { Box, FormControlLabel, Switch } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { colorPaletteBlue600 } from "../../../../core/theme";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { Formik } from "formik";
import { ControlObject } from "../../../../requests_geco/sitesApi/sitesApi.types";
import React from "react";
import { FluidTextField } from "../../../../common/components/FluidTextField";
import FluidDatePicker from "../../../../common/components/FluidDatePicker";
import { FluidNumber } from "../../../../common/components/FluidNumberField";

const EditControlObjectModal = ({
	open,
	onClose,
	controlObject,
	onSubmit,
}: {
	open: boolean;
	onClose: () => void;
	controlObject?: ControlObject;
	onSubmit: (values: any) => void;
}) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			title={
				<Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
					<AddCircleOutlineIcon sx={{ color: colorPaletteBlue600 }} />{" "}
					{controlObject?.id ? "Edit" : "Add"} Control Object
				</Box>
			}
		>
			<Formik
				initialValues={{
					end_date: controlObject?.end_date ?? "",
					minimum_duration: controlObject?.minimum_duration ?? 0,
					minimum_rest_time: controlObject?.minimum_rest_time ?? 0,
					minimum_value: controlObject?.minimum_value ?? 0,
					name: controlObject?.name ?? "",
					night_end: controlObject?.night_end ?? "",
					night_set_point: controlObject?.night_set_point ?? 0,
					night_start: controlObject?.night_start ?? "",
					ramp_down: controlObject?.ramp_down ?? 0,
					ramp_up: controlObject?.ramp_up ?? 0,
					software_provider: controlObject?.software_provider ?? "",
					start_date: controlObject?.start_date ?? "",
					test: controlObject?.test ?? false,
				}}
				onSubmit={(values) => {
					const formattedValues = {
						...values,
						end_date: values.end_date || null,
						files: [],
						id: controlObject?.id ?? null,
						night_end: values.night_end || null,
						night_start: values.night_start || null,
						software_provider: values.software_provider || null,
						start_date: values.start_date || null,
					};

					onSubmit(formattedValues);
					onClose();
				}}
			>
				{({ handleChange, submitForm, values }) => (
					<form>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							<Box sx={{ display: "flex", gap: 2 }}>
								<FluidDatePicker
									onChange={handleChange}
									name={"start_date"}
									label={"Start Date"}
									value={values.start_date}
								/>
								<FluidDatePicker
									onChange={handleChange}
									name={"end_date"}
									label={"End Date"}
									value={values.end_date}
								/>
							</Box>
							<FluidTextField
								onChange={handleChange}
								value={values.name}
								name={"name"}
								label={"Name"}
							/>
							<FluidDatePicker
								onChange={handleChange}
								name="night_start"
								label="Night Start"
								value={values.night_start}
								type="time"
							/>
							<FluidDatePicker
								onChange={handleChange}
								name="night_end"
								label="Night End"
								value={values.night_end}
								type="time"
							/>
							<Box>
								<FluidNumber
									onChange={handleChange}
									name="night_set_point"
									title="Night Set Point"
									value={values.night_set_point}
								/>
								If the set-point at night is not maximum, it is
								this value (kW)
							</Box>
							<Box>
								<FormControlLabel
									control={
										<Switch
											name="test"
											checked={values.test}
											onChange={handleChange}
										/>
									}
									label="Test"
								/>
								<br />
								Flag indicating the Site has been tested and is
								controllable
							</Box>
							<Box>
								<FluidNumber
									onChange={handleChange}
									name="minimum_value"
									title="Minimum Value"
									value={Number(values.minimum_value)}
								/>
								The minimum setpoint that must be respected in
								kW
							</Box>
							<Box>
								<FluidNumber
									onChange={handleChange}
									name="minimum_duration"
									title="Minimum Duration"
									value={Number(values.minimum_duration)}
								/>
								Minimum duration of a curtailment, in minutes
							</Box>
							<Box>
								<FluidNumber
									onChange={handleChange}
									name="minimum_rest_time"
									title="Minimum Rest Time"
									value={Number(values.minimum_rest_time)}
								/>
								Minimum duration between two curtailments, in
								minutes
							</Box>
							<Box>
								<FluidNumber
									onChange={handleChange}
									name="ramp_down"
									title="Ramp Down"
									value={values.ramp_down}
								/>
								The period in minutes it takes to ramp down
								production
							</Box>
							<Box>
								<FluidNumber
									onChange={handleChange}
									name="ramp_up"
									title="Ramp Up"
									value={values.ramp_up}
								/>
								The period in minutes it takes to ramp up
								production
							</Box>
							<Box>
								<FluidTextField
									onChange={handleChange}
									value={values.software_provider}
									name={"software_provider"}
									label={"Software Provider"}
								/>
								Name of the provider of the software that allows
								controlling the Site
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								gap: 2,
								marginTop: 2,
							}}
						>
							<PrimaryButton
								onClick={onClose}
								text="Cancel"
								type="button"
								color="secondary"
							/>
							<PrimaryButton
								onClick={submitForm}
								text={controlObject?.id ? "Save" : "Create"}
								type="button"
								color="primary"
							/>
						</Box>
					</form>
				)}
			</Formik>
		</Modal>
	);
};

export default EditControlObjectModal;
