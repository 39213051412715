/**
 * @function autoInit
 * Initiate Fluid design system javascript components working with vanilla JS, React, Angular and Vue.
 * - add root id to the top container (id="root")
 * - import inside your code or via script tag  lib/fluid-design-system.js then  lib/auto-init.js
 * - do not use custom tag (otherwise you must use custom elements )
 * - no need to import webcomponentsjs and custom elements adapters
 *
 * For fluid developers:
 * do not forget to add New JS component names inside componentNames array. Follow NEW_COMPONENT code tag
 *
 * Add a js config file to setup root Element:
 * window.NJ = window.NJ || {};
 * window.NJ.config = { rootElementId: 'monElementID' } };
 */

const config = (window.NJ && window.NJ.config) || null;

const componentClassNames: Array<string> = [
  'Alert',
  'Checkbox',
  'Collapse',
  'Dropdown',
  'Fab',
  'Form',
  'Form',
  'Form',
  'Form',
  'Form',
  'Header',
  'Modal',
  'Navbar',
  'Radio',
  'Select',
  'Sidebar',
  'Slider',
  'Tab',
  'Tag',
  'Tooltip'
];
// NEW_COMPONENT add library name here
const componentSelector: Array<string> = [
  '.nj-alert',
  '.nj-checkbox',
  '.nj-collapse',
  '.nj-dropdown',
  '.nj-fab-menu',
  '.nj-form-autocomplete',
  '.nj-form-input-password',
  '.nj-form-input-search',
  'input:not([type=hidden]):not([type=checkbox]):not([type=radio]):not([type=file]):not([type=button]):not([type=submit]):not([type=reset]).nj-form-control',
  'textarea.nj-form-control',
  '.nj-header',
  '.nj-modal',
  '.nj-navbar',
  '.nj-radio > label > input[type=radio]',
  'select.nj-form-control',
  '.nj-sidebar',
  '.nj-slider',
  '.nj-tab',
  '.nj-tag'
];

// NEW_COMPONENT add selector name here

const globalSelector = componentSelector.toString();

const autoInit = (function (): void {
  if (typeof window !== 'undefined') {
    function init(componentClassName = null): void {
      if (window.NJ) {
        if (componentClassName) window.NJ[componentClassName].init();
        else window.NJ.AutoInit();
      } else if (componentClassName) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window[componentClassName].init();
      } else {
        const len = componentClassNames.length;
        let i = 0;
        for (; i < len; i++) {
          if (window[componentClassNames[i]]) {
            window[componentClassNames[i]].init();
          }
        }
      }
    }

    function createTooltip(element): void {
      const Tooltip = window.Tooltip || window.NJ.Tooltip;
      // eslint-disable-next-line no-new
      if (Tooltip && !element.getAttribute('aria-describedby') && !element.key) new Tooltip(element);
    }

    function initTooltip(elements: Element[]): void {
      elements.forEach(createTooltip);
    }

    function hasKey(elements: NodeList | Element[]): Array<Node> | boolean {
      const keyElements = [];
      for (const el of elements) {
        const abstractElement = el as Node as AbstractNode;
        if (abstractElement && abstractElement.key) {
          keyElements.push(el);
        }
      }
      if (keyElements.length) return keyElements;
      return false;
    }

    function onAddedNode(mutationsList, i): Record<string, any> {
      const componentsToInit = {};
      if (mutationsList[i].type === 'childList' && mutationsList[i].addedNodes.length) {
        for (let j = 0; j < mutationsList[i].addedNodes.length; j++) {
          const el = mutationsList[i].addedNodes[j] as Element;
          if (el) {
            const elementsTooltip = el.querySelectorAll ? [...el.querySelectorAll('[data-toggle="tooltip"]')] : [];
            if (el.getAttribute && el.getAttribute('data-toggle') === 'tooltip') elementsTooltip.push(el);
            if (elementsTooltip && elementsTooltip.length > 0) initTooltip(elementsTooltip);

            const componentsEngie = [];
            const getComponents = (classSelector: string, index): void => {
              if (el.classList && el.classList.contains(classSelector.substring(1))) componentsEngie[index] = el;
              else if (el.querySelector && el.querySelector(`${classSelector}`))
                componentsEngie[index] = el.querySelector(`${classSelector}`);
            };
            componentSelector.forEach(getComponents);
            let index = -1;
            switch (el.tagName) {
              case 'INPUT':
                const componentClassNamesIndexInput = 8;
                index = componentClassNamesIndexInput;
                break;
              case 'SELECT':
                const componentClassNamesIndexSelect = 14;
                index = componentClassNamesIndexSelect;
                break;
              case 'CHECKBOX':
                const componentClassNamesIndexCheckbox = 9;
                index = componentClassNamesIndexCheckbox;
                break;
              default:
            }
            if (index !== -1) {
              componentsEngie[index] = el;
            }
            if (componentsEngie.length > 0) {
              const keys = hasKey(componentsEngie) as Array<Node>;
              if (!keys || keys.length < componentsEngie.length) {
                componentsEngie.forEach((c, i) => {
                  if (c) {
                    componentsToInit[componentClassNames[i]] = componentClassNames[i];
                  }
                });
              }
            }
          }
        }
      }
      return componentsToInit;
    }

    function onRemovedNode(mutationsList, i): void {
      if (mutationsList[i].type === 'childList' && mutationsList[i].removedNodes.length) {
        for (let j = 0; j < mutationsList[i].removedNodes.length; j++) {
          const el = mutationsList[i].removedNodes[j] as Element;
          if (el) {
            const componentsEngie = el.querySelectorAll ? [...el.querySelectorAll(globalSelector)] : [];
            componentSelector.forEach((classSelector) => {
              if (el.classList && el.classList.contains(classSelector.substring(1))) componentsEngie.push(el);
            });
            if (el.tagName === 'INPUT' || el.tagName === 'SELECT' || el.tagName === 'CHECKBOX')
              componentsEngie.push(el);
            const elementsTooltip = el.querySelectorAll ? [...el.querySelectorAll('[data-toggle="tooltip"]')] : [];
            if (el.getAttribute && el.getAttribute('data-toggle') === 'tooltip') elementsTooltip.push(el);
            if (elementsTooltip.length > 0) componentsEngie.push(...elementsTooltip);

            if (componentsEngie.length > 0) {
              const componentsEngieToDispose = hasKey(componentsEngie) as Array<Node>;
              if (componentsEngieToDispose) {
                const destroyComponents = (el): void => {
                  const abstractEl = el as AbstractNode;
                  window.NJStore[abstractEl.key.id].dispose();
                };
                componentsEngieToDispose.forEach(destroyComponents);
              }
            }
          }
        }
      }
    }

    function initObserver(): void {
      let root = (document.getElementById('root') as Node) || document.body;
      if (config && config.rootElementId) root = (document.getElementById(config.rootElementId) as Node) || root;
      const targetNode: Node = root;

      if (targetNode) {
        const config = { attributes: false, childList: true, subtree: true };

        const callback: MutationCallback = (mutationsList): void => {
          for (let i = 0, len = mutationsList.length; i < len; i++) {
            onRemovedNode(mutationsList, i);
            const componentsToInit = onAddedNode(mutationsList, i);
            // eslint-disable-next-line guard-for-in
            for (const i in componentsToInit) {
              init(componentsToInit[i]);
            }
          }
        };

        const observer: MutationObserver = new MutationObserver(callback);
        observer.observe(targetNode, config);
      }
    }

    const onDomLoaded = (): void => {
      init();
      const tooltips = [...document.querySelectorAll('[data-toggle="tooltip"]')];
      initTooltip(tooltips);
      initObserver();
    };

    document.addEventListener('DOMContentLoaded', onDomLoaded);
  }
})();

export default autoInit;

interface AbstractNode extends Node, ParentNode {
  key: { id: string; key: string };
}
