import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../../common/utils/customBaseQuery";

export interface GetQuickSightDashBoardUrlResponseType {
	embed_url: string;
}
export interface GetQuickSightDashBoardUrlParamsType {
	url: string;
}

const geodeApi = createApi({
	reducerPath: "geode",
	baseQuery: customBaseQuery({
		baseURL: "https://geode-api.ets-infra.net",
	}),
	keepUnusedDataFor: 0,
	endpoints: (builder) => ({
		getQuickSightDashBoardUrl: builder.query<
			GetQuickSightDashBoardUrlResponseType,
			GetQuickSightDashBoardUrlParamsType
		>({
			query: ({ url }) => ({
				url,
				method: "GET",
			}),
		}),
	}),
});

export const { useGetQuickSightDashBoardUrlQuery } = geodeApi;
export default geodeApi;
