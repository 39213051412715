
function prepareOdataValue(value:string | number | boolean) {
	if (typeof value === 'string') {
		return value.replaceAll("'", "''")
	}
	return value
}

export function generateOdataQuery(
	filters: { value: string; field_path: string }[],
	odataQuery: string | undefined
) {
	const groupFilters = filters
		.map((filter) => {
			if (filter.value === null) {
				return `${filter.field_path} eq null`;
			}
			return `${filter.field_path} in ('${encodeURIComponent(
				prepareOdataValue(filter.value)
			)}',)`;
		})
		.join(" AND ");
	if (odataQuery && odataQuery.replace(/"/g, "")) {
		return groupFilters + " AND " + odataQuery.replace(/"/g, "");
	}
	return groupFilters;
}
