import axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";
import { enqueueSnackbar } from "notistack";
import { DEFAULT_ERROR } from "./contants";
import { getOktaToken } from "./getOktaToken";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_PATH,
	// timeout: 1000,
});

axiosInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		return getOktaToken().then((token) => {
			(config.headers as AxiosHeaders).set(
				"Authorization",
				"Bearer " + token
			);
			return Promise.resolve(config);
		});
	},
	(err) => {
		return Promise.reject(err);
	}
);

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (
			error.response &&
			DEFAULT_ERROR.codes.includes(error.response.status)
		) {
			const message =
				error?.response?.data?.reason || DEFAULT_ERROR.message;
			enqueueSnackbar(message, {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
				autoHideDuration: 10000,
			});
		}
		return Promise.reject(error);
	}
);

export const apiPost = (
	url: string,
	data: any,
	config?: Partial<InternalAxiosRequestConfig>
) => {
	return axiosInstance.post(url, data, config);
};

export const apiPut = (
	url: string,
	data: any,
	config?: Partial<InternalAxiosRequestConfig>
) => {
	return axiosInstance.put(url, data, config);
};

export const apiGet = (
	url: string,
	config?: Partial<InternalAxiosRequestConfig>
) => {
	return axiosInstance.get(url, config);
};

export const apiPatch = (
	url: string,
	data: any,
	config?: Partial<InternalAxiosRequestConfig>
) => {
	return axiosInstance.patch(url, data, config);
};
