/**
 * --------------------------------------------------------------------------
 * NJ: text.ts
 * --------------------------------------------------------------------------
 */
import AbstractFormControl from '../../globals/ts/abstract-form-control';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import Manipulator from '../../globals/ts/manipulator';
export default class TextInput extends AbstractFormControl {
  static readonly NAME = `${Core.KEY_PREFIX}-form-input-text`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.text`;

  static SELECTOR = {
    default:
      'input:not([type=hidden]):not([type=checkbox]):not([type=radio]):not([type=file]):not([type=button]):not([type=submit]):not([type=reset]).nj-form-control',
    formGroup: AbstractFormControl.SELECTOR.formGroup
  };

  protected static readonly DEFAULT_OPTIONS = {
    njFormGroup: {
      required: false
    }
  };

  constructor(element: HTMLInputElement, options = {}) {
    super(TextInput, element, Manipulator.extend(true, TextInput.DEFAULT_OPTIONS, options));

    Data.setData(element, TextInput.DATA_KEY, this);
  }

  dispose(): void {
    super.dispose();
    Data.removeData(this.element, TextInput.DATA_KEY);
    this.element = null;
  }

  static init(options = {}): TextInput[] {
    return super.init(this, options, TextInput.SELECTOR.default) as TextInput[];
  }

  static getInstance(element: HTMLInputElement): TextInput {
    return Data.getData(element, TextInput.DATA_KEY) as TextInput;
  }

  static matches(element: Element): boolean {
    return element.getAttribute('type') === 'text';
  }
}
