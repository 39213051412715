/**
 * --------------------------------------------------------------------------
 * NJ : Header.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import Util from '../../globals/ts/util';
export default class Header extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-header`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.header`;
  protected static readonly SELECTOR = {
    default: `.${Header.NAME}`
  };
  private minimizeWindowHeightThreshold: number;
  private static readonly CLASS_NAME = {
    minimize: `${Header.NAME}--sm`,
    minimizeOnScroll: `${Header.NAME}--scroll-sm`,
    panel: `${Header.NAME}__nav--panel`,
    panelShow: `${Header.NAME}__nav--show`,
    backPanel: `${Header.NAME}__menu-return`,
    menuBurger: `${Header.NAME}__nav-burger`,
    closeBurger: `${Header.NAME}__nav-burger--close`,
    openSearch: `${Header.NAME}__search-icon`,
    search: `${Header.NAME}__search`,
    navLink: `${Header.NAME}__nav-link`,
    menuLink: `${Header.NAME}__menu-link`
  };
  private readonly menuBurger: HTMLElement;
  private openSearch: HTMLElement;

  constructor(element: HTMLElement) {
    super(Header, element);
    this.minimizeThreshold = 0.2;
    this.menuBurger = element.querySelector(`.${Header.CLASS_NAME.menuBurger}`);
    this.openSearch = element.querySelector(`.${Header.CLASS_NAME.openSearch}`);

    if (element.classList.contains(Header.CLASS_NAME.minimizeOnScroll) && typeof window !== 'undefined')
      window.addEventListener('scroll', this.onScroll);

    if (this.menuBurger) {
      this.menuBurger.addEventListener('click', this.togglePanelShow.bind(this));
      element.querySelectorAll(`.${Header.CLASS_NAME.panel}`).forEach((el, i) => {
        if (i > 0) el.previousElementSibling.addEventListener('click', this.togglePanelShow.bind(this));
      });

      element.querySelectorAll(`.${Header.CLASS_NAME.backPanel}`).forEach((el) => {
        el.addEventListener('click', this.closeCurrentPanel.bind(this));
      });
    }

    if (element.dataset.closeOnLinkClick === 'true') {
      element.querySelectorAll(`.${Header.CLASS_NAME.navLink}`).forEach((el) => {
        el.addEventListener('click', this.closePanels.bind(this));
      });

      element.querySelectorAll(`.${Header.CLASS_NAME.menuLink}`).forEach((el) => {
        el.addEventListener('click', this.closePanels.bind(this));
      });
    }

    if (this.openSearch) {
      this.openSearch.addEventListener('click', this.focusSearchInput);
    }
  }

  dispose(): void {
    Data.removeData(this.element, Header.DATA_KEY);
    if (this.element.classList.contains(Header.CLASS_NAME.minimize) && typeof window !== 'undefined')
      window.removeEventListener('scroll', this.onScroll);
    this.element = null;
  }

  static getInstance(element: HTMLElement): Header {
    return Data.getData(element, Header.DATA_KEY) as Header;
  }

  static init(options = {}): Header[] {
    return super.init(this, options, Header.SELECTOR.default) as Header[];
  }

  get minimizeThreshold(): number {
    return this.minimizeWindowHeightThreshold;
  }

  set minimizeThreshold(value: number) {
    this.minimizeWindowHeightThreshold = value;
  }

  minimize(): void {
    if (!this.element.classList.contains(Header.CLASS_NAME.minimize))
      this.element.classList.add(Header.CLASS_NAME.minimize);
  }

  maximize(): void {
    if (this.element.classList.contains(Header.CLASS_NAME.minimize))
      this.element.classList.remove(Header.CLASS_NAME.minimize);
  }

  togglePanelShow(e): void {
    e.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();

    if (window.getComputedStyle(this.menuBurger).display !== 'none') {
      const targetEvent = e.currentTarget.parentElement;
      const isBurger = e.currentTarget.classList.contains(Header.CLASS_NAME.menuBurger);
      const panel = targetEvent.querySelector(`.${Header.CLASS_NAME.panel}`);
      const isShow = panel.classList.contains(Header.CLASS_NAME.panelShow);

      // burger toggle panel
      if (isShow && isBurger) {
        this.closePanels();
      } else if (!isShow && isBurger) {
        this.resetPanels();
        e.currentTarget.classList.add(Header.CLASS_NAME.closeBurger);
      }

      // item toggle panel
      if (isShow) panel.classList.remove(Header.CLASS_NAME.panelShow);
      else if (!isShow) panel.classList.add(Header.CLASS_NAME.panelShow);
    }
  }

  closePanels(): void {
    const mainPanel = this.element.querySelector(`.${Header.CLASS_NAME.panel}`);
    this.menuBurger.classList.remove(Header.CLASS_NAME.closeBurger);

    const resetPanelsOnTransitionEnd = () => {
      mainPanel.removeEventListener('transitionend', resetPanelsOnTransitionEnd);
      this.resetPanels();
    };

    mainPanel.addEventListener('transitionend', resetPanelsOnTransitionEnd);
    mainPanel.classList.remove(Header.CLASS_NAME.panelShow);
  }

  closeCurrentPanel(e): void {
    const panel = e.currentTarget.closest(`.${Header.CLASS_NAME.panel}`);
    panel.classList.remove(Header.CLASS_NAME.panelShow);
  }

  resetPanels(): void {
    this.element.querySelectorAll(`.${Header.CLASS_NAME.panel}`).forEach((p) => {
      p.classList.remove(Header.CLASS_NAME.panelShow);
    });
    this.menuBurger.classList.remove(Header.CLASS_NAME.closeBurger);
  }

  onScroll = Util.throttle(
    () => {
      const scrollWindowPercentage = window.scrollY / window.innerHeight;
      if (scrollWindowPercentage > this.minimizeWindowHeightThreshold) this.minimize();
      else this.maximize();
    },
    100,
    true,
    true,
    this
  );

  focusSearchInput = (): void => {
    const input: HTMLInputElement = this.element.querySelector(`.${Header.CLASS_NAME.search} input`);
    input.focus();
  };
}
