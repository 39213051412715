import { backgroundBrandSecondary } from "../../../../../core/theme";

export const style = {
	container: {
		display: "flex",
		gap: 2,
		backgroundColor: backgroundBrandSecondary,
		padding: 2,
		flexShrink: 0,
		col1: { flexShrink: 0, width: "361px" },
		col2: { flexShrink: 0, width: "260px" },
		col3: { flexShrink: 0, width: "170px", paddingTop: "27px" },
	},
	code2: {
		overflow: "hidden",
		padding: "1px",
		height: "42px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		marginTop: "8px",
		width: "500px",
		item: {
			display: "flex",
			alignItems: "flex-end",
		},
	},
	select: {
		width: "auto",
	},
};
