/**
 * --------------------------------------------------------------------------
 * NJ: Tab.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
export default class Tab extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-tab`;
  public static readonly DATA_KEY = `${Core.KEY_PREFIX}.tab`;

  public static CLASS_NAME = {
    component: `.${Tab.NAME}`,
    tabItem: `.${Tab.NAME}__item`,
    tabContent: `.${Tab.NAME}__content`,
    itemActive: `${Tab.NAME}__item--active`,
    contentActive: `${Tab.NAME}__content--active`
  };

  static SELECTOR = {
    default: `.${Tab.NAME}`
  };

  private readonly tabs: NodeListOf<HTMLElement>;
  public currentIndex: number = null;

  constructor(element: HTMLElement) {
    super(Tab, element);
    this.element = element;
    Data.setData(element, Tab.DATA_KEY, this);
    this.tabs = element.querySelectorAll(Tab.CLASS_NAME.tabItem);
    this.tabs.forEach((el, index) => {
      el.addEventListener('click', this.show);
      if (el.classList.contains(Tab.CLASS_NAME.itemActive)) {
        this.currentIndex = index;
      }
      el.addEventListener('keydown', this.onKeyDown);
    });
  }

  public show = (e): void => {
    const interactionElement = e.currentTarget;
    if (!interactionElement.getAttribute('disabled')) {
      this.tabs.forEach((el, index) => {
        if (el === interactionElement) this.currentIndex = index;
        el.classList.remove(Tab.CLASS_NAME.itemActive);
        el.setAttribute('tabindex', '-1');
        el.setAttribute('aria-selected', 'false');
        this.element.querySelectorAll(Tab.CLASS_NAME.tabContent)[index].classList.remove(Tab.CLASS_NAME.contentActive);
      });

      interactionElement.classList.add(Tab.CLASS_NAME.itemActive);
      interactionElement.setAttribute('tabindex', '0');
      interactionElement.setAttribute('aria-selected', 'true');

      const contents = this.element.querySelectorAll(Tab.CLASS_NAME.tabContent);
      if (contents.length > this.currentIndex) {
        this.element
          .querySelectorAll(Tab.CLASS_NAME.tabContent)
          [this.currentIndex].classList.add(Tab.CLASS_NAME.contentActive);
      }
    }
  };

  focusNextFocusableTab() {
    const focusableTabs = Array.from(this.tabs).filter((tab) => !tab.hasAttribute('disabled'));
    const focusedTabIndex = focusableTabs.findIndex((tab) => document.activeElement === tab);
    const nextFocusableTab = focusableTabs.at((focusedTabIndex + 1) % focusableTabs.length);
    nextFocusableTab.focus();
  }

  focusPreviousFocusableTab() {
    const focusableTabs = Array.from(this.tabs).filter((tab) => !tab.hasAttribute('disabled'));
    const focusedTabIndex = focusableTabs.findIndex((tab) => document.activeElement === tab);
    const previousFocusableTab = focusableTabs.at(focusedTabIndex - 1);
    previousFocusableTab.focus();
  }

  public onKeyDown = (e: KeyboardEvent): void => {
    switch (e.key) {
      case 'ArrowRight':
        e.preventDefault();
        this.focusNextFocusableTab();
        break;
      case 'ArrowLeft':
        e.preventDefault();
        this.focusPreviousFocusableTab();
        break;
      case 'Tab':
        const target = e.target as HTMLButtonElement;

        const isSelected = target.getAttribute('aria-selected') === 'true';
        if (!isSelected) {
          return;
        }

        const tabId = target.getAttribute('aria-controls');
        const tabPanel = this.element.querySelector(`[id=${tabId}]`) as HTMLDivElement;

        setTimeout(() => {
          tabPanel.focus();
        });
      case 'Enter':
        break;
      default:
    }
  };

  dispose(): void {
    this.tabs.forEach((el: HTMLElement) => {
      el.removeEventListener('click', this.show);
      el.removeEventListener('keydown', this.onKeyDown);
    });
    Data.removeData(this.element, Tab.DATA_KEY);
  }

  static getInstance(element: HTMLElement): Tab {
    return Data.getData(element, Tab.DATA_KEY) as Tab;
  }

  static init(options = {}): Tab[] {
    return super.init(this, options, Tab.SELECTOR.default) as Tab[];
  }
}
