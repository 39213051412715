import { ValidationError } from "../../../../common/hooks/usePydanticErrorsFormat";
import { Action, Pricing } from "../../pricingListSlice";
import PricingModal from "../PricingModal";
import CustomAutocomplete from "../../../../common/components/CustomAutocomplete";
import { Box, IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { TenderTimeserie } from "../../../tender_page/tender.module";
import { useCallback, useEffect, useState } from "react";
import { strContains } from "../../../../common/utils/strContains";
import * as _ from "lodash";
import { useTemporaryFetch } from "../../../../common/hooks/useTemporaryFetch";

export interface AddRealizedModalProps {
	open: boolean;
	onClose: () => void;
	forPricings: Pricing[];
	onSubmit: (body: any) => void;
	isLoading: boolean;
	action?: Action | null;
	errorMessage: string | ValidationError | undefined | null;
}

export interface TimeSerieOption {
	id: string;
	name: string;
}

function makeTimeserieName(timeserie: TenderTimeserie) {
	return (
		timeserie.timeserie_name +
		" " +
		(timeserie.ts_type === "site" ? "(cleaned)" : "(not cleaned)")
	);
}

export function timeserieToOption(timeserie: TenderTimeserie) {
	return {
		id:
			"" +
			timeserie.ts_type +
			"-" +
			(timeserie?.id || timeserie.timeserie_name),
		name: makeTimeserieName(timeserie),
	};
}

export function optionToTimeserie(
	option: TimeSerieOption | string,
	available: TenderTimeserie[]
): TenderTimeserie {
	if (typeof option === "string") {
		return {
			ts_type: "raw",
			timeserie_name: option,
			cleaned: false,
		};
	}
	const ts = _.find(
		available,
		(item) => timeserieToOption(item)?.id === option?.id
	);
	return ts!!;
}

export function AddRealizedModal(props: AddRealizedModalProps) {
	const {
		open,
		isLoading,
		forPricings,
		action,
		errorMessage,
		onClose,
		onSubmit,
	} = props;
	const { data: timeseries, fetchTemporaryData: fetchTimeseries } =
		useTemporaryFetch<TenderTimeserie[]>();
	const [selectedTimeseries, setSelectedTimeseries] = useState<
		TenderTimeserie[]
	>([]);
	const [currentTimeserie, setCurrentTimeserie] = useState<TenderTimeserie>();

	const fetchData = useCallback(
		(search: string) => {
			return (timeseries || [])
				.filter((timeserie) =>
					strContains(timeserie.timeserie_name, search)
				)
				.map(timeserieToOption);
		},
		[timeseries]
	);

	useEffect(() => {
		if (forPricings[0]) {
			fetchTimeseries(
				`/sites/timeseries/${forPricings[0].site_pricings?.[0]?.site?.id}`
			);
		}
	}, [forPricings]);

	const submit = useCallback(() => {
		onSubmit({ ts_list: selectedTimeseries });
	}, [onSubmit, selectedTimeseries]);

	return (
		<PricingModal
			open={open}
			onClose={onClose}
			onSubmit={submit}
			forPricings={forPricings}
			title={action?.display_name || ""}
			subTitle={"Applicable for the following pricings:"}
			isSubmitDisabled={isLoading || !selectedTimeseries.length}
			isLoading={isLoading}
			errorMessage={errorMessage}
		>
			<>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
					}}
				>
					<CustomAutocomplete<TimeSerieOption>
						sx={{
							"& .MuiInputLabel-shrink": {
								display: "none",
							},
							width: "100%",
							marginBottom: 0,
						}}
						fetchData={fetchData}
						setFieldValue={(_fieldName, value: any) => {
							setCurrentTimeserie(
								optionToTimeserie(value, timeseries || [])
							);
						}}
						data={(timeseries || []).map(timeserieToOption)}
						label={"timeserie"}
						freeSolo={true}
					></CustomAutocomplete>
					<IconButton
						disabled={false}
						type="button"
						color="primary"
						onClick={() => {
							if (currentTimeserie) {
								setSelectedTimeseries((prev) => [
									...prev,
									currentTimeserie,
								]);
							}
							setCurrentTimeserie(undefined);
						}}
					>
						<AddCircleIcon />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: "6px",
						margin: "6px",
					}}
				>
					{selectedTimeseries.map((timeserie) => (
						<p key={makeTimeserieName(timeserie)}>
							- {makeTimeserieName(timeserie)}
						</p>
					))}
				</Box>
			</>
		</PricingModal>
	);
}
