import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import Util from '../../globals/ts/util';

// Source: https://github.com/KittyGiraudel/focusable-selectors
const FOCUSABLE_ELEMENTS_SELECTORS = [
  'a[href]:not([tabindex^="-"]):not([data-trap-anchor])',
  'area[href]:not([tabindex^="-"]):not([data-trap-anchor])',
  'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"]):not([data-trap-anchor])',
  'input[type="radio"]:not([disabled]):not([tabindex^="-"]):not([data-trap-anchor])',
  'select:not([disabled]):not([tabindex^="-"]):not([data-trap-anchor])',
  'textarea:not([disabled]):not([tabindex^="-"]):not([data-trap-anchor])',
  'button:not([disabled]):not([tabindex^="-"]):not([data-trap-anchor])',
  'iframe:not([tabindex^="-"]):not([data-trap-anchor])',
  'audio[controls]:not([tabindex^="-"]):not([data-trap-anchor])',
  'video[controls]:not([tabindex^="-"]):not([data-trap-anchor])',
  '[contenteditable]:not([tabindex^="-"]):not([data-trap-anchor])',
  '[tabindex]:not([tabindex^="-"]):not([data-trap-anchor])'
];

export type BeforeHideEventSource = 'keyboard' | 'button' | 'backdrop';

export default class Modal extends AbstractComponent {
  static readonly LOG_TAG = `${Core.LIBRARY_LOG_TAG}[MODAL]`;
  static readonly NAME = `${Core.KEY_PREFIX}-modal`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.modal`;
  protected static readonly EVENT_KEY = `.${Modal.DATA_KEY}`;
  protected static readonly DATA_API_KEY = Core.KEY_PREFIX;
  private static readonly ESCAPE_KEYCODE = 27; // KeyboardEventName.which value for Escape (Esc) key
  public static hasInit = false;
  static CLASSNAME = {
    backdrop: `${Core.KEY_PREFIX}-modal__backdrop`,
    backdropFitViewport: `${Core.KEY_PREFIX}-modal__backdrop--fit-viewport`,
    fade: 'fade',
    show: 'show',
    visible: `${Modal.NAME}--visible`,
    fitViewport: `${Modal.NAME}--fit-viewport`
  };

  static SELECTOR = {
    default: `.${Modal.NAME}`,
    dataDismiss: '[data-dismiss="modal"]',
    dataToggle: '[data-toggle="modal"]',
    dataAttributeToggleName: 'data-toggle',
    dataAttributeToggleValue: 'modal',
    modalBody: `.${Core.KEY_PREFIX}-modal__body`,
    dialog: `.${Core.KEY_PREFIX}-modal__dialog`
  };

  static readonly EVENT = {
    beforehide: `${EventName.beforehide}${Modal.EVENT_KEY}`,
    show: `${EventName.show}${Modal.EVENT_KEY}`,
    shown: `${EventName.shown}${Modal.EVENT_KEY}`,
    focusin: `${EventName.focusin}${Modal.EVENT_KEY}`,
    hide: `${EventName.hide}${Modal.EVENT_KEY}`,
    hidden: `${EventName.hidden}${Modal.EVENT_KEY}`,
    keydownDismiss: `${EventName.keydown}.dismiss${Modal.EVENT_KEY}`,
    clickDismiss: `${EventName.click}.dismiss${Modal.EVENT_KEY}`,
    clickDataApi: `${EventName.click}${Modal.EVENT_KEY}${Modal.DATA_API_KEY}`,
    mouseupDismiss: `${EventName.mouseup}.dismiss${Modal.EVENT_KEY}`,
    mousedownDismiss: `${EventName.mousedown}.dismiss${Modal.EVENT_KEY}`
  };

  static CAN_MOVE_ELEMENTS_IN_DOM = true;
  static SHOULD_MANAGE_OPENING_STATE = true;

  private backdrop: HTMLElement = null;
  private dialog: Element = null;
  private ignoreBackdropClick = null;
  private isShown = false;
  private isTransitioning = false;
  /** Element that triggered the modal appearing. Will be refocused when the modal is dismissed. */
  private triggerElement: HTMLElement = null;
  private readonly targetedParentElement: Element;
  private readonly shouldFitViewportArea: boolean;
  private readonly trailingFocusTrapAnchorElement: HTMLElement;
  private readonly leadingFocusTrapAnchorElement: HTMLElement;
  private readonly shouldManageOpeningState: boolean;

  constructor(element: HTMLElement) {
    super(Modal, element);

    this.shouldManageOpeningState = Modal.SHOULD_MANAGE_OPENING_STATE;

    this.dialog = this.element.querySelector(Modal.SELECTOR.dialog);

    const { leadingFocusTrap, trailingFocusTrap } = this.createFocusElements();

    this.leadingFocusTrapAnchorElement = leadingFocusTrap;
    this.trailingFocusTrapAnchorElement = trailingFocusTrap;

    this.shouldFitViewportArea = this.element.classList.contains(Modal.CLASSNAME.fitViewport);

    if (Modal.CAN_MOVE_ELEMENTS_IN_DOM) {
      this.targetedParentElement = this.element.parentElement;

      const anchorSelector = this.element.dataset.appendto;

      if (anchorSelector && !this.shouldFitViewportArea) {
        const anchorElement = document.querySelector(anchorSelector);

        if (this.isNodeElement(anchorElement)) {
          this.targetedParentElement = anchorElement;
        } else {
          console.error(
            `${Modal.LOG_TAG} Invalid element: Anchor element '${anchorSelector}' is not a valid node element. Check your selector for [data-appendto]. Appended to parent element instead.`
          );
        }
      }

      const isParentNodeElement = this.isNodeElement(this.targetedParentElement);

      if (this.shouldFitViewportArea || !isParentNodeElement) {
        if (!isParentNodeElement) {
          console.error(
            `${Modal.LOG_TAG}  Invalid element: Parent element is not a valid node element. Appended to body instead.`
          );
        }

        const bodyElement = document.body;

        if (this.isNodeElement(bodyElement)) {
          this.targetedParentElement = bodyElement;
        } else {
          throw new Error(`${Modal.LOG_TAG}  No body element found. Unable to attach modal`);
        }
      }

      if (this.targetedParentElement !== this.element.parentElement) {
        this.targetedParentElement?.appendChild(this.element);
      }
    } else {
      const parentElement = this.element.parentElement;
      if (!this.isNodeElement(parentElement)) {
        throw new Error(`${Modal.LOG_TAG}  Invalid modal's parent element. Unable to initialize modal`);
      }
      this.targetedParentElement = parentElement;
    }

    Data.setData(element, Modal.DATA_KEY, this);

    if (this.shouldManageOpeningState && !Modal.hasInit) {
      Modal.hasInit = true;
      this.registerEvents();
    }
  }

  /**
   * Initialize all modal components in the DOM.
   */
  static init(options = {}): Modal[] {
    return super.init(this, options, Modal.SELECTOR.default) as Modal[];
  }

  static getInstance(element: HTMLElement): Modal {
    return Data.getData(element, Modal.DATA_KEY) as Modal;
  }

  private isNodeElement(element: Element) {
    return element && element.nodeType === Node.ELEMENT_NODE;
  }

  /**
   * Create two focusable divs, which aims to be placed at the beginning and at the end of the
   * modal to intercept the focus leaving the modal.
   * When the anchors are focused, we focus the first or last focusable element in the modal instead.
   * @private
   * @return Elements to be placed before and after the modal
   */
  private createFocusElements(): {
    leadingFocusTrap: HTMLDivElement;
    trailingFocusTrap: HTMLDivElement;
  } {
    function createFocusTrapAnchor(focusEventListener: () => void) {
      const focusTrapAnchorEl = document.createElement('div');
      focusTrapAnchorEl.setAttribute('tabindex', '0');
      focusTrapAnchorEl.setAttribute('aria-hidden', 'true');
      focusTrapAnchorEl.setAttribute('data-trap-anchor', '');
      focusTrapAnchorEl.addEventListener('focusin', focusEventListener);
      return focusTrapAnchorEl;
    }

    return {
      leadingFocusTrap: createFocusTrapAnchor(() => {
        const firstFocusableElement = this.element.querySelector(FOCUSABLE_ELEMENTS_SELECTORS.join(',')) as HTMLElement;
        firstFocusableElement?.focus();
      }),
      trailingFocusTrap: createFocusTrapAnchor(() => {
        const lastFocusableElement = Array.from(
          this.element.querySelectorAll(FOCUSABLE_ELEMENTS_SELECTORS.join(','))
        ).at(-1) as HTMLElement;
        lastFocusableElement?.focus();
      })
    };
  }

  private addFocusTrapElements(): void {
    // Add leading focus trap
    this.element.prepend(this.leadingFocusTrapAnchorElement);
    // Add trailing focus trap
    this.element.append(this.trailingFocusTrapAnchorElement);
  }

  private removeFocusTrapElements(): void {
    this.element.removeChild(this.trailingFocusTrapAnchorElement);
    this.element.removeChild(this.leadingFocusTrapAnchorElement);
  }

  private hideModal(): void {
    if (!this.element) {
      return;
    }
    this.element.classList.remove(Modal.CLASSNAME.visible);
    this.element.setAttribute('aria-hidden', 'true');
    this.element.removeAttribute('aria-modal');
    this.isTransitioning = false;
    this.showBackdrop();
    this.removeFocusTrapElements();
    EventHandler.trigger(this.element, Modal.EVENT.hidden);
  }

  private removeBackdrop(): void {
    if (this.backdrop) {
      this.backdrop.parentNode.removeChild(this.backdrop);
      this.backdrop = null;
    }
  }

  private setEscapeEvent(): void {
    if (this.isShown) {
      EventHandler.on(this.element, Modal.EVENT.keydownDismiss, (event) => {
        if (event.which === Modal.ESCAPE_KEYCODE) {
          this.hide(event, 'keyboard');
        }
      });
    } else {
      EventHandler.off(this.element, Modal.EVENT.keydownDismiss);
    }
  }

  private showBackdrop(callback?: () => void): void {
    if (!this.element) {
      return;
    }
    const animate = this.element.classList.contains(Modal.CLASSNAME.fade) ? Modal.CLASSNAME.fade : '';

    if (this.isShown) {
      this.backdrop = document.createElement('div');
      this.backdrop.className = Modal.CLASSNAME.backdrop;

      if (this.shouldFitViewportArea) {
        this.backdrop.classList.add(Modal.CLASSNAME.backdropFitViewport);
      }

      if (animate) {
        this.backdrop.classList.add(animate);
      }

      this.targetedParentElement?.appendChild(this.backdrop);

      EventHandler.on(this.element, Modal.EVENT.clickDismiss, (event) => {
        if (this.ignoreBackdropClick) {
          this.ignoreBackdropClick = false;
          return;
        }
        if (event.target !== event.currentTarget) {
          return;
        }
        this.hide(null, 'backdrop');
      });

      if (animate) {
        Util.reflow(this.backdrop);
      }

      this.backdrop.classList.add(Modal.CLASSNAME.show);

      if (!callback) {
        return;
      }

      if (!animate) {
        callback();
        return;
      }

      const backdropTransitionDuration = Util.getTransitionDurationFromElement(this.backdrop);

      EventHandler.one(this.backdrop, Util.TRANSITION_END, callback);
      Util.emulateTransitionEnd(this.backdrop, backdropTransitionDuration);
    } else if (!this.isShown && this.backdrop) {
      this.backdrop.classList.remove(Modal.CLASSNAME.show);

      const callbackRemove = (): void => {
        this.removeBackdrop();
        if (callback) {
          callback();
        }
      };

      if (this.element.classList.contains(Modal.CLASSNAME.fade)) {
        const backdropTransitionDuration = Util.getTransitionDurationFromElement(this.backdrop);

        EventHandler.one(this.backdrop, Util.TRANSITION_END, callbackRemove);
        Util.emulateTransitionEnd(this.backdrop, backdropTransitionDuration);
      } else {
        callbackRemove();
      }
    } else if (callback) {
      callback();
    }
  }

  private showElement(): void {
    if (!this.element) {
      return;
    }
    EventHandler.trigger(this.element, Modal.EVENT.show);
    const transition = this.element.classList.contains(Modal.CLASSNAME.fade);

    this.element.classList.add(Modal.CLASSNAME.visible);
    this.element.removeAttribute('aria-hidden');

    this.element.scrollTop = 0;

    if (transition) {
      Util.reflow(this.element);
    }

    this.element.classList.add(Modal.CLASSNAME.show);

    if (document.activeElement) {
      this.triggerElement = document.activeElement as HTMLElement;
    }

    this.addFocusTrapElements();
    this.element.setAttribute('aria-modal', 'true');

    const transitionComplete = (): void => {
      const firstFocusableElement = this.element.querySelector(FOCUSABLE_ELEMENTS_SELECTORS.join(',')) as HTMLElement;
      firstFocusableElement?.focus();

      this.isTransitioning = false;
      EventHandler.trigger(this.element, Modal.EVENT.shown);
    };

    if (transition) {
      const transitionDuration = Util.getTransitionDurationFromElement(this.dialog);

      EventHandler.one(this.dialog, Util.TRANSITION_END, transitionComplete);
      Util.emulateTransitionEnd(this.dialog, transitionDuration);
    } else {
      transitionComplete();
    }
  }

  dispose(): void {
    this.removeBackdrop();
    Data.removeData(this.element, Modal.DATA_KEY);

    /**
     * `document` has 2 events `Modal.EVENT.FOCUSIN` and `Modal.EVENT.CLICK_DATA_API`
     * Do not move `document` in `htmlElements` array
     * It will remove `Modal.EVENT.CLICK_DATA_API` event that should remain
     */
    EventHandler.off(document, Modal.EVENT.focusin);

    this.element = null;
    this.dialog = null;
    this.backdrop = null;
    this.isShown = null;
    this.ignoreBackdropClick = null;
    this.isTransitioning = null;
  }

  /**
   * Hides the modal
   * @param event - The triggering event. Default behavior will be prevented.
   * @param source - A key indicating the trigger source. Will not have effect in standard usage.
   */
  hide(event?: Event, source?: BeforeHideEventSource): void {
    if (event) {
      event.preventDefault();
    }

    if (!this.isShown || this.isTransitioning || !this.element) {
      return;
    }

    if (!this.shouldManageOpeningState && source) {
      EventHandler.trigger(this.element, Modal.EVENT.beforehide, { source });
      return;
    }

    EventHandler.trigger(this.element, Modal.EVENT.hide);

    this.isShown = false;
    const transition = this.element.classList.contains(Modal.CLASSNAME.fade);

    if (transition) {
      this.isTransitioning = true;
    }

    this.setEscapeEvent();

    EventHandler.off(document, Modal.EVENT.focusin);

    this.element.classList.remove(Modal.CLASSNAME.show);

    EventHandler.off(this.element, Modal.EVENT.clickDismiss);
    EventHandler.off(this.dialog, Modal.EVENT.mousedownDismiss);

    if (transition) {
      const transitionDuration = Util.getTransitionDurationFromElement(this.element);

      EventHandler.one(this.element, Util.TRANSITION_END, () => this.hideModal());
      Util.emulateTransitionEnd(this.element, transitionDuration);
    } else {
      this.hideModal();
    }

    if (this.triggerElement) {
      this.triggerElement?.focus();
    }
  }

  show(): void {
    if (this.isShown || this.isTransitioning || !this.element) {
      return;
    }

    if (this.element.classList.contains(Modal.CLASSNAME.fade)) {
      this.isTransitioning = true;
    }

    this.isShown = true;

    this.setEscapeEvent();

    EventHandler.on(this.element, Modal.EVENT.clickDismiss, Modal.SELECTOR.dataDismiss, (event) =>
      this.hide(event, 'button')
    );

    EventHandler.on(this.dialog, Modal.EVENT.mousedownDismiss, () => {
      EventHandler.one(this.element, Modal.EVENT.mouseupDismiss, (event) => {
        if (event.target.isEqualNode(this.element)) {
          this.ignoreBackdropClick = true;
        }
      });
    });

    this.showBackdrop(() => this.showElement());
  }

  toggle(): void {
    if (this.isShown) {
      this.hide();
    } else {
      this.show();
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */
  private registerEvents(): void {
    EventHandler.on(document, Modal.EVENT.clickDataApi, Modal.SELECTOR.dataToggle, (event: Event) => {
      let target: HTMLElement;
      const currentTarget = event.currentTarget as HTMLElement;
      let srcToggleElement: HTMLElement = event.target as HTMLElement;
      if (
        srcToggleElement.getAttribute(Modal.SELECTOR.dataAttributeToggleName) !==
        Modal.SELECTOR.dataAttributeToggleValue
      ) {
        srcToggleElement = srcToggleElement.closest(Modal.SELECTOR.dataToggle);
      }
      const selector = Util.getSelectorFromElement(srcToggleElement);
      if (selector) {
        target = document.querySelector(selector);
      }

      if (currentTarget.tagName === 'A' || currentTarget.tagName === 'AREA') {
        event.preventDefault();
      }

      const component = Modal.getInstance(target);

      if (component) {
        component.toggle();
      }
    });
  }
}
