import {
	TableRow,
	TableCell,
	IconButton,
	CircularProgress,
	SxProps,
	Theme,
	Link,
	Checkbox,
} from "@mui/material";
import { If } from "../../../../common/components/If";
import { primaryColor, white, lightGrey } from "../../../../core/theme";
import {
	Action,
	Pricing,
	PricingRun,
	PricingRunSummaryMetric,
	resetPricingRun,
} from "../../pricingListSlice";
import { useSelector } from "react-redux";
import {
	selectExportLoader,
	selectPricingRuns,
	selectPricingRunsAreLoading,
} from "../../pricingList.selector";
import React, { useCallback, useEffect, useMemo } from "react";
import CommentIcon from "@mui/icons-material/Comment";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import { CollapseArrow } from "../../../../common/components/CollapseArrow";
import usePrevious from "../../../../common/hooks/usePrevious";
import { useAppDispatch } from "../../../../common/hooks/default";
import { loadPricingRuns } from "../../pricingList.thunk";
import { useSearchPaginate } from "../../../../common/hooks/useSearchPaginate";
import { PricingHamburger } from "./PricingHamburger";
import { RootState } from "../../../../core/rootReducers";
import { PricingRunHamburger } from "./PricingRunHamburger";
import { usePricingTableRowModel } from "../../hooks/columns/usePricingTableRowModel";
import { StatelessFlashUpdateTableCell } from "../../../../common/components/StatelessFlashUpdateTableCell";
import { formatPercentage } from "../../../../common/utils/formatPercentage";
import { Column } from "../../../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../../hooks/columns/common";

const style: { [key: string]: SxProps<Theme> } = {
	stickyRow: {
		height: "30px",
		position: "sticky",
		left: 0,
		paddingLeft: "5px !important",
		background: white,
		"& > td": { padding: "0px" },
	},
	subRow: {
		position: "sticky",
		left: 0,
		height: "30px",
		paddingLeft: "75px !important",
		"&.selectable": { paddingLeft: "80px !important" },
		border: 0,
		backgroundColor: lightGrey,
		"& > td": { padding: "0px" },
	},
	selectableSubRow: {
		position: "sticky",
		left: 0,
		height: "30px",
		paddingLeft: "80px !important",
		"&.selectable": { paddingLeft: "80px !important" },
		border: 0,
		backgroundColor: lightGrey,
		"& > td": { padding: "0px" },
	},
	moreRow: {
		height: "30px",
		paddingLeft: "5px !important",
	},
	loadMore: {
		position: "sticky",
		left: 0,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "30px",
	},
};

export interface PricingProps {
	pricing: Pricing;
	isSelected: boolean;
	toggleSelection: (pricing: Pricing) => void;
	statusIsLoading?: boolean;
	valueOrPercent: "percent" | "value";
	onDisplayComment: (comment: string) => void;
	onSubmit: (id: number, action: Action) => void;
	onDownloadRunToExcel: (ids: number[]) => void;
	onDownloadPbParams: (id: number) => void;
	onDownloadJson: (id: number) => void;
	isScrolling?: boolean;
	isOpen: boolean;
	wasOpen: boolean;
	setIsOpen: (value: boolean) => void;
	openGraphModal: (pricing: Pricing | undefined) => void;
	selectedColumns?: Column<ColumnIds>[];
	openWarningModal: (message: string) => void;
}

function PricingRowComponent(props: PricingProps) {
	const dispatch = useAppDispatch();
	const {
		pricing,
		isScrolling,
		isSelected,
		toggleSelection,
		statusIsLoading,
		valueOrPercent,
		onDisplayComment,
		onSubmit,
		onDownloadRunToExcel: onDownloadRunToExcel,
		onDownloadJson: onDownloadJson,
		onDownloadPbParams,
		isOpen,
		wasOpen,
		setIsOpen,
		openGraphModal,
		selectedColumns,
		openWarningModal,
	} = props;

	const pricingRun = useMemo(() => pricing?.pricing_runs?.[0], [pricing]);

	const isExportLoading = useSelector(selectExportLoader);
	const statusWasLoading = usePrevious(statusIsLoading);

	const runs = useSelector((state: RootState) =>
		selectPricingRuns(state, pricing.id)
	);
	const runsAreLoading = useSelector((state: RootState) =>
		selectPricingRunsAreLoading(state, pricing.id)
	);

	const onOpen = useCallback(() => {
		dispatch(loadPricingRuns(pricing.id, 1));
	}, [dispatch, pricing]);

	const onClose = useCallback(() => {
		dispatch(resetPricingRun({ pricingId: pricing.id }));
	}, [pricing]);

	const refresh = useCallback(() => {
		dispatch(resetPricingRun({ pricingId: pricing.id }));
		dispatch(loadPricingRuns(pricing.id, 1));
	}, [pricing]);

	const loadNextPage = useCallback(
		(page: number) => {
			dispatch(loadPricingRuns(pricing.id, page));
		},
		[dispatch, pricing]
	);

	useEffect(() => {
		if (!statusIsLoading && statusWasLoading && isOpen) {
			refresh();
		}
	}, [statusIsLoading, statusWasLoading]);

	const { nextPage, currentPage } = useSearchPaginate(loadNextPage);

	useEffect(() => {
		if (isOpen && !wasOpen) {
			onOpen();
		} else if (!isOpen && wasOpen) {
			onClose();
		}
	}, [isOpen, wasOpen, onOpen, onClose]);

	const rowModel = usePricingTableRowModel(selectedColumns);

	const getValueOrPercent = useCallback(
		(
			metric: PricingRunSummaryMetric | undefined,
			valueFormatter: (value: number | null | undefined) => string
		) => {
			if (!metric) {
				return "-";
			}
			if (
				valueOrPercent === "percent" &&
				typeof metric.percent === "number"
			) {
				if (!metric.percent) {
					return "-";
				}

				return formatPercentage(metric.percent);
			}
			return valueFormatter(metric.value);
		},
		[valueOrPercent]
	);

	return (
		<>
			<TableRow
				key={pricing.id}
				sx={{
					"&:last-child td, &:last-child th": {
						border: "0 !important",
					},
					"& > td": { padding: "0px" },
				}}
			>
				<TableCell sx={style.stickyRow}>
					<TableCell
						align="left"
						sx={{
							border: "0 !important",
							width: 40,
							minWidth: 40,
							maxWidth: 40,
						}}
					>
						<If condition={pricing.actions.length}>
							<Checkbox
								sx={{ height: "24px" }}
								checked={isSelected}
								onChange={() => toggleSelection(pricing)}
							/>
						</If>
					</TableCell>
					<TableCell
						align="left"
						sx={{
							border: "0 !important",
							width: 35,
							height: "24px",
						}}
					>
						<CollapseArrow isOpen={isOpen} setIsOpen={setIsOpen} />
					</TableCell>
					{rowModel.stickyColumns.map((column) => (
						<StatelessFlashUpdateTableCell
							key={column.columnId}
							id={column.cellIdFactory(pricing, pricingRun)}
							field={column.fieldValueExtractor({
								openGraphModal,
								pricing,
								valueOrPercent,
								pricingRun,
								statusIsLoading,
								isScrolling,
								getValueOrPercent,
								openWarningModal,
							})}
							isUpdate={pricing?.isNewUpdate}
							align="left"
							sx={{
								border: "0 !important",
								maxWidth: column.width,
								minWidth: column.width,
							}}
						>
							<column.renderCell
								openGraphModal={openGraphModal}
								pricing={pricing}
								valueOrPercent={valueOrPercent}
								pricingRun={pricingRun}
								statusIsLoading={statusIsLoading}
								isScrolling={isScrolling}
								getValueOrPercent={getValueOrPercent}
								openWarningModal={openWarningModal}
							/>
						</StatelessFlashUpdateTableCell>
					))}
				</TableCell>
				<If condition={!isScrolling}>
					{rowModel.nonStickyColumns.map((column) => (
						<StatelessFlashUpdateTableCell
							key={column.columnId}
							id={column.cellIdFactory(pricing, pricingRun)}
							field={column.fieldValueExtractor({
								openGraphModal,
								pricing,
								valueOrPercent,
								pricingRun,
								statusIsLoading,
								isScrolling,
								getValueOrPercent,
								openWarningModal,
							})}
							isUpdate={pricing?.isNewUpdate}
							align="left"
							sx={{
								border: "0 !important",
								maxWidth: column.width,
								minWidth: column.width,
							}}
						>
							<column.renderCell
								openGraphModal={openGraphModal}
								pricing={pricing}
								valueOrPercent={valueOrPercent}
								pricingRun={pricingRun}
								statusIsLoading={statusIsLoading}
								isScrolling={isScrolling}
								getValueOrPercent={getValueOrPercent}
								openWarningModal={openWarningModal}
							/>
						</StatelessFlashUpdateTableCell>
					))}
					<TableCell sx={{ width: 70, maxWidth: 70 }} align="center">
						{(pricing?.pricing_runs[0]?.status_comment ||
							pricing?.booking_outputs) && (
							<IconButton
								aria-label="comment"
								onClick={() =>
									onDisplayComment(
										pricing?.booking_outputs ||
											pricing?.pricing_runs[0]
												?.status_comment ||
											""
									)
								}
							>
								<CommentIcon
									sx={{
										fontSize: 16,
										color: primaryColor,
									}}
								/>
							</IconButton>
						)}
						{!pricing?.pricing_runs[0]?.status_comment &&
							!pricing?.booking_outputs && (
								<CommentsDisabledIcon
									sx={{
										fontSize: 16,
										color: primaryColor,
									}}
								/>
							)}
					</TableCell>

					<TableCell sx={{ width: 40, maxWidth: 40 }} align="left">
						<PricingHamburger
							pricing={pricing}
							isExportLoading={isExportLoading}
							submitAction={onSubmit}
							onExportRunToExcel={() =>
								onDownloadRunToExcel([
									pricing?.pricing_runs[0]?.id,
								])
							}
							onExportRunToJson={() =>
								onDownloadJson(pricing?.pricing_runs[0]?.id)
							}
							onExportRunPbParams={() =>
								onDownloadPbParams(pricing?.pricing_runs[0]?.id)
							}
						/>
					</TableCell>
				</If>
			</TableRow>
			<If condition={isOpen}>
				{runs.slice(1).map((run: PricingRun) => (
					<TableRow
						key={run.id}
						sx={{
							"&:last-child td, &:last-child th": {
								border: 0,
							},
							"& > td": { padding: "0px" },
						}}
					>
						<TableCell
							sx={
								pricing.actions.length
									? style.selectableSubRow
									: style.subRow
							}
						>
							{rowModel.stickyColumns.map((column) => (
								<StatelessFlashUpdateTableCell
									key={column.columnId}
									id={column.cellIdFactory(pricing, run)}
									field={column.fieldValueExtractor({
										openGraphModal,
										pricing,
										valueOrPercent,
										pricingRun: run,
										statusIsLoading,
										isScrolling,
										getValueOrPercent,
										openWarningModal,
									})}
									isUpdate={pricing?.isNewUpdate}
									align="left"
									sx={{
										border: "0 !important",
										maxWidth: column.width,
										minWidth: column.width,
									}}
								>
									<column.renderCell
										openGraphModal={openGraphModal}
										pricing={pricing}
										valueOrPercent={valueOrPercent}
										pricingRun={run}
										statusIsLoading={statusIsLoading}
										isScrolling={isScrolling}
										getValueOrPercent={getValueOrPercent}
										openWarningModal={openWarningModal}
									/>
								</StatelessFlashUpdateTableCell>
							))}
						</TableCell>
						<If condition={!isScrolling}>
							{rowModel.nonStickyColumns.map((column) => (
								<StatelessFlashUpdateTableCell
									key={column.columnId}
									id={column.cellIdFactory(pricing, run)}
									field={column.fieldValueExtractor({
										openGraphModal,
										pricing,
										valueOrPercent,
										pricingRun: run,
										statusIsLoading,
										isScrolling,
										getValueOrPercent,
										openWarningModal,
									})}
									isUpdate={pricing?.isNewUpdate}
									align="left"
									sx={{
										border: "0 !important",
										maxWidth: column.width,
										minWidth: column.width,
									}}
								>
									<column.renderCell
										openGraphModal={openGraphModal}
										pricing={pricing}
										valueOrPercent={valueOrPercent}
										pricingRun={run}
										statusIsLoading={statusIsLoading}
										isScrolling={isScrolling}
										getValueOrPercent={getValueOrPercent}
										openWarningModal={openWarningModal}
									/>
								</StatelessFlashUpdateTableCell>
							))}
							<TableCell
								sx={{ width: 40, maxWidth: 40 }}
								align="left"
							></TableCell>
							<TableCell
								sx={{ width: 40, maxWidth: 40 }}
								align="left"
							>
								<PricingRunHamburger
									pricingRun={run}
									isExportLoading={isExportLoading}
									onExportRunToExcel={() =>
										onDownloadRunToExcel([run.id])
									}
									onExportRunToJson={() =>
										onDownloadJson(run.id)
									}
								/>
							</TableCell>
						</If>
					</TableRow>
				))}
				<TableRow>
					<TableCell colSpan={18} sx={style.loadMore}>
						{runsAreLoading ? (
							<CircularProgress size={20} />
						) : (
							<Link
								sx={{ cursor: "pointer" }}
								onClick={() => nextPage(currentPage + 1)}
							>
								Load more
							</Link>
						)}
					</TableCell>
				</TableRow>
			</If>
		</>
	);
}

export const PricingRow = React.memo(PricingRowComponent);
