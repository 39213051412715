/**
 * --------------------------------------------------------------------------
 * NJ : Alert.ts
 * --------------------------------------------------------------------------
 */
import '../../globals/js/animation';
import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
export default class Alert extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-alert`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.alert`;
  protected static readonly EVENT_KEY = `.${Alert.DATA_KEY}`;

  protected static readonly SELECTOR = {
    default: `.${Alert.NAME}`,
    dismiss: `.${Alert.NAME}__close`
  };

  private static readonly KEY_FRAMES = [
    {
      transform: 'translateY(0)',
      opacity: 1
    },
    {
      transform: 'translateY(-16px)',
      opacity: 0
    }
  ];

  private static readonly EVENT = {
    click: `${EventName.click}${Alert.EVENT_KEY}`,
    close: `${EventName.close}${Alert.EVENT_KEY}`,
    closed: `${EventName.closed}${Alert.EVENT_KEY}`
  };

  constructor(element: HTMLElement) {
    super(Alert, element);
    Data.setData(element, Alert.DATA_KEY, this);
    this.setListeners();
  }

  closeAndDestroy(): void {
    EventHandler.trigger(this.element, Alert.EVENT.close);

    if (this.element.animate)
      this.element.animate(Alert.KEY_FRAMES, {
        duration: 250,
        delay: 100,
        easing: 'ease-out'
      }).onfinish = (): void => {
        this.destroyElement();
      };
  }

  async close(callback): Promise<any> {
    EventHandler.trigger(this.element, Alert.EVENT.close);
    const duration = 250;
    const delay = 100;

    if (this.element.animate)
      this.element.animate(Alert.KEY_FRAMES, {
        duration,
        delay,
        easing: 'ease-out'
      });

    await new Promise((resolve) => setTimeout(resolve, duration + delay));
    callback();
  }

  dispose(): void {
    const closeButton = this.element.querySelector(Alert.SELECTOR.dismiss);
    EventHandler.off(closeButton, Alert.EVENT.click);
    Data.removeData(this.element, Alert.DATA_KEY);
    this.element = null;
  }

  private destroyElement(): void {
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    EventHandler.trigger(this.element, Alert.EVENT.closed);
  }

  private setListeners(): void {
    const closeButton = this.element.querySelector(Alert.SELECTOR.dismiss);

    EventHandler.on(closeButton, Alert.EVENT.click, () => {
      this.closeAndDestroy();
    });
  }

  static init(options = {}): Alert[] {
    return super.init(this, options, Alert.SELECTOR.default) as Alert[];
  }

  static getInstance(element: HTMLElement): Alert {
    return Data.getData(element, Alert.DATA_KEY) as Alert;
  }
}
