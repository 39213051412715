/**
 * --------------------------------------------------------------------------
 * NJ: autocomplete.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
export default class Autocomplete extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-form-autocomplete`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.autocomplete`;
  static readonly SELECTOR = {
    default: `.${Autocomplete.NAME}`,
    input: `input`,
    list: `.${Autocomplete.NAME}__list`,
    listGroup: `.${Autocomplete.NAME}__list > :first-child`
  };
  private dataList: Array<{ name: string; value: string }>;
  private currentList: Array<{ name: string; value: string }>;
  private readonly listFragment: DocumentFragment;
  public options: { autocomplete: boolean; limit: number | boolean } = { autocomplete: true, limit: false };
  private root: HTMLElement;

  constructor(element: HTMLElement, options = {}) {
    super(Autocomplete, element);
    if (element.querySelector(Autocomplete.SELECTOR.listGroup)) {
      const listTemplate = element.querySelector(`${Autocomplete.SELECTOR.listGroup}`).innerHTML;
      this.listFragment = document.createRange().createContextualFragment(listTemplate);
      this.root =
        element.parentElement.dataset.list || element.parentElement.dataset.options ? element.parentElement : element;

      if (this.root.dataset.list) {
        this.dataList = JSON.parse(this.root.dataset.list);
        this.list = this.dataList;
      }

      if (this.root.dataset.options) this.options = { ...this.options, ...JSON.parse(this.root.dataset.options) };

      this.root[Autocomplete.NAME.replace(/-/g, '_')] = this;

      element.querySelector(Autocomplete.SELECTOR.list).addEventListener('click', this.onSelectListItem);
    }

    element.querySelector(Autocomplete.SELECTOR.input).addEventListener('input', this.onInputChange);
    element.addEventListener('keydown', this.onKeyDown);
    Data.setData(element, Autocomplete.DATA_KEY, this);
  }

  static init(options = {}): Autocomplete[] {
    return super.init(this, options, Autocomplete.SELECTOR.default) as Autocomplete[];
  }

  public dispose(): void {
    if (this.element && this.element.querySelector(Autocomplete.SELECTOR.list))
      this.element.querySelector(Autocomplete.SELECTOR.list).removeEventListener('click', this.onSelectListItem);
    if (this.element && this.element.querySelector(Autocomplete.SELECTOR.input))
      this.element.querySelector(Autocomplete.SELECTOR.input).removeEventListener('input', this.onInputChange);
    this.element.removeEventListener('keydown', this.onKeyDown);
    Data.removeData(this.element, Autocomplete.DATA_KEY);
    this.dataList = null;
    this.currentList = null;
    this.element = null;
  }

  public set data(data: Array<{ name: string; value: string }>) {
    this.dataList = data;
  }

  public get data(): Array<{ name: string; value: string }> {
    return this.dataList;
  }

  public get list(): Array<{ name: string; value: string }> {
    return this.currentList;
  }

  public set list(data: Array<{ name: string; value: string }>) {
    const input: HTMLInputElement = this.element.querySelector(Autocomplete.SELECTOR.input);
    const inputUserValue: string = input.value;

    if (data && data.length) {
      const fragment = document.createDocumentFragment();
      this.currentList = [];
      data.forEach(({ name, value }) => {
        if (!this.options.autocomplete || Autocomplete.compareText(name, inputUserValue)) {
          if (!this.options.limit || fragment.children.length < this.options.limit) {
            const fragmentListElement = this.listFragment.firstElementChild;
            fragmentListElement.setAttribute('data-value', value);
            fragmentListElement.setAttribute('data-name', name);
            fragmentListElement.textContent = name;
            fragment.appendChild(this.listFragment.cloneNode(true));
            this.currentList.push({ name, value });
          }
        }
      });
      this.element.querySelector(`${Autocomplete.SELECTOR.listGroup}`).textContent = '';
      this.element.querySelector(Autocomplete.SELECTOR.listGroup).appendChild(fragment);
    }
  }

  private static compareText(text: string, search: string): boolean {
    text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    search = search.replace(/\(|\)|\\/gi, '');
    const reg = new RegExp(search, 'gi');
    return text.search(reg) !== -1;
  }

  public onInputChange = (): void => {
    this.list = this.dataList;
  };

  public onKeyDown = (e): void => {
    const listContainer: HTMLElement = this.element.querySelector(Autocomplete.SELECTOR.list);
    const listItems: NodeListOf<HTMLElement> = this.element.querySelectorAll(`${Autocomplete.SELECTOR.listGroup}> *`);
    const index: number = parseInt(listContainer.dataset.index, 10);
    const input: HTMLInputElement = this.element.querySelector(Autocomplete.SELECTOR.input);

    const open = () => {
      listContainer.style.display = 'block';
      input.setAttribute('aria-expanded', 'true');
      this.element.setAttribute('aria-expanded', 'true');
    };

    const close = () => {
      delete listContainer.dataset.index;
      listContainer.style.setProperty('display', '');
      input.setAttribute('aria-expanded', 'false');
      this.element.setAttribute('aria-expanded', 'false');
    };

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        open();
        if (listContainer.dataset.index) {
          listContainer.dataset.index = (index - 1 < 0 ? listItems.length - 1 : index - 1).toString();
          listItems[listContainer.dataset.index].focus();
        }
        break;
      case 'ArrowDown':
        e.preventDefault();
        open();
        if (!listContainer.dataset.index) {
          listContainer.dataset.index = '0';
        } else {
          listContainer.dataset.index = (index < listItems.length - 1 ? index + 1 : 0).toString();
        }
        listItems[listContainer.dataset.index].focus();
        break;
      case 'Escape':
        close();
        input.focus();
        break;
      case 'Enter':
        close();
        break;
      default:
    }
  };

  public onSelectListItem = (e): void => {
    const { name, value } = e.target.dataset;
    const input: HTMLInputElement = this.element.querySelector(Autocomplete.SELECTOR.input);
    if (name) input.value = name;
    if (value) {
      this.root.dataset.value = value;
      input.dataset.value = value;
    }

    this.onUserSelectItem({ name, value });
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  public onUserSelectItem({ name, value }): void {}

  static getInstance(element: HTMLElement): Autocomplete {
    return Data.getData(element, Autocomplete.DATA_KEY) as Autocomplete;
  }
}
