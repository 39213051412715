/**
 * --------------------------------------------------------------------------
 * NJ: search-input.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';
export default class SearchInput extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-form-input-search`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.search-input`;
  static readonly SELECTOR = {
    default: `.${SearchInput.NAME}`
  };
  private static readonly INPUT_CLASS = `${Core.KEY_PREFIX}-form-control`;
  private static readonly RESET_CLASS = `${Core.KEY_PREFIX}-form-control__reset`;
  protected static readonly DEFAULT_OPTIONS = {
    selector: SearchInput.SELECTOR
  };

  constructor(element: HTMLElement, options = {}) {
    super(SearchInput, element, Manipulator.extend(true, SearchInput.DEFAULT_OPTIONS, options));

    this.setListeners();
    Data.setData(element, SearchInput.DATA_KEY, this);
  }

  static init(options = {}): SearchInput[] {
    return super.init(this, options, SearchInput.SELECTOR.default) as SearchInput[];
  }

  dispose(): void {
    Data.removeData(this.element, SearchInput.DATA_KEY);
    this.element = null;
  }

  static getInstance(element: HTMLElement): SearchInput {
    return Data.getData(element, SearchInput.DATA_KEY) as SearchInput;
  }

  private setListeners(): void {
    const input = this.element.getElementsByClassName(SearchInput.INPUT_CLASS)[0] as HTMLInputElement;
    const resetButton = this.element.getElementsByClassName(SearchInput.RESET_CLASS)[0];

    EventHandler.on(resetButton, 'click', () => {
      input.value = null;
      this.element.classList.remove('is-filled');
    });
  }
}
