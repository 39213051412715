import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { If } from "../../../../common/components/If";
import { WsEventTypes } from "../../../../common/constants/wsEvents";
import usePrevious from "../../../../common/hooks/usePrevious";
import { useTemporaryFetch } from "../../../../common/hooks/useTemporaryFetch";
import { apiPatch } from "../../../../common/utils/request.utils";
import wsPublisher$ from "../../../../core/wsPublisher";
import BookingModal from "../../components/BookDialog";
import ActionPricingModal from "../../components/actionModal/ActionPricingModal";
import AddPartyModal from "../../components/actionModal/AddPartyModal";
import { AddRealizedPortModal } from "../../components/actionModal/AddRealizedPortModal";
import ErrorModal from "../../components/actionModal/ErrorModal";
import { ACTION_PRICING_ERROR, PricingError } from "../../pricingList.constant";
import { Action, Pricing } from "../../pricingListSlice";
import { AddRealizedModal } from "../../components/actionModal/AddRealizedModal";
import { SetFixingActionModal } from "../../components/actionModal/SetFixingActionModal";
import { PaginatedResponse } from "../../../../common/interfaces";

function actionHasFields(action?: Action | null) {
	return !!Object.keys(action?.fields || {})?.length;
}

function actionNeedsModal(action?: Action | null) {
	return actionHasFields(action) || action?.action === "BOOK";
}

const ACTIONS_WITH_CUSTOM_MODALS = [
	"ADD_REALIZED",
	"ADD_PARTY",
	"BOOK",
	"FIX_PRICE",
];

export function usePricingActions(
	pricings: Pricing[] | null | undefined,
	onStartLoading?: (ids: number[]) => void,
	onFinishLoading?: (ids: number[]) => void
) {
	/**
	 * We have 3 scenarios for pricing actions
	 *
	 * (A) Tender level action: all pricings on this tender satisfying a condition should be acted on
	 * (B) Batch action: all pricings selected should be acted on
	 * (C) Individual pricing action: only one pricing should be acted on
	 *
	 * Overall (simplified) workflow:
	 *
	 *     (1)                    (2)                    (3)
	 *      A                      B                      C
	 *      |                      |                      |
	 * action.filter           id in (xxx)             id == x
	 *      |                      |                      |
	 *      |                      |                      |
	 *      |                      |                      |
	 *      ----------->Fetch all involved pricings<-------
	 *                             |
	 *                             v
	 *                 does the action need a modal ? --YES--> Open action modal . . > cancel action (4)
	 *                             |                                .
	 *                             NO                               .
	 *                             |                                .
	 *                             v                                v
	 *                       Submit action  <................Submit action modal (5)
	 *                             |
	 *                             |
	 *                             V
	 *                   Update pricings locally
	 *
	 * We provide a single method for the 5 user-initiated events
	 * - actOnPricings: starts the action (1, 2, 3), depending on wether ids have been provided
	 * - 4 and 5 are self contained as we expose the modal node from this hook
	 *
	 * And a few properties so the ui can adapt to our state
	 * - isActionLoading: we are busy doing some work, display a loader where appropriate
	 * - pricingsUnderAction: so we can display a loader on each involved line
	 *
	 * TODO:
	 * - The fetch all step is a bit more involved than expected because in some case the pricings must be
	 * fetched server side (1), in some cases they are already present in the reducer (2, 3). We could simplify the
	 * logic by always fetching them server side (if fast enough) but for that we need to improve filtering first.
	 * - the booking modal is handled differently because it is specific and does not use the action's field
	 */
	const onSuccess = useRef<() => void | undefined>();
	const [isTransitionning, setIsTransitionning] = useState(false);
	const [error, setError] = useState<any>(undefined);
	const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
	const wasTransitionLoading = usePrevious(isTransitionning);
	const [currentAction, setCurrentAction] = useState<Action | null>();
	const [clientSidePricingsUnderAction, setClientSidePricingsUnderAction] =
		useState<Pricing[] | null>();
	const {
		data: serverSidePricingsUnderActionResponse,
		isLoading: areServerSidePricingsUnderActionLoading,
		fetchTemporaryData: fetchServerSidePricingsUnderAction,
		clearTemporaryData: clearServerSidePricingsUnderAction,
	} = useTemporaryFetch<PaginatedResponse<Pricing>>(
		(response) => response.data
	);

	const serverSidePricingsUnderAction = useMemo(
		() => serverSidePricingsUnderActionResponse?.data,
		[serverSidePricingsUnderActionResponse]
	);

	const isActionModalOpen = useMemo(
		() =>
			actionHasFields(currentAction) || currentAction?.action === "BOOK",
		[currentAction]
	);
	const isActionLoading = useMemo(
		() => areServerSidePricingsUnderActionLoading || isTransitionning,
		[areServerSidePricingsUnderActionLoading, isTransitionning]
	);

	const pricingsUnderAction = useMemo<Pricing[]>(
		() =>
			serverSidePricingsUnderAction ||
			clientSidePricingsUnderAction ||
			[],
		[serverSidePricingsUnderAction, clientSidePricingsUnderAction]
	);

	const clear = useCallback(() => {
		setCurrentAction(null);
		clearServerSidePricingsUnderAction();
		setClientSidePricingsUnderAction(null);
		setError(null);
	}, []);

	const actOnPricings = useCallback(
		(action: Action, pricingIds?: number[], callback?: () => void) => {
			onSuccess.current = callback;
			setCurrentAction(action);
			// case A
			if (!pricingIds?.length) {
				fetchServerSidePricingsUnderAction(`pricing?${action.filters}`);
			} else {
				// case B & C
				setClientSidePricingsUnderAction(
					pricings?.filter((pricing) =>
						pricingIds.includes(pricing.id)
					)
				);
			}
			// opening the modal is done by setCurrentAction (derived state),
			// submit is done through useEffect to allow pricings to load from server
		},
		[
			setCurrentAction,
			fetchServerSidePricingsUnderAction,
			setClientSidePricingsUnderAction,
			pricings,
			onSuccess,
		]
	);

	const isServerSideAction = useMemo(
		() => !!serverSidePricingsUnderAction?.length,
		[serverSidePricingsUnderAction]
	);

	const submit = useCallback(
		async (
			ids: number[],
			values: any = {},
			pricingPartyIds: { id: number; party_id: number }[] = []
		) => {
			try {
				if (onStartLoading) {
					onStartLoading(ids);
				}
				let filters = "";
				const payload = {
					...values,
					party_ids: pricingPartyIds,
				};
				if (pricingPartyIds?.length) {
					payload.ids = pricingPartyIds.map((item) => item.id);
				} else if (!isServerSideAction) {
					payload.ids = (clientSidePricingsUnderAction || []).map(
						(pricing) => pricing.id
					);
				} else {
					filters = currentAction?.filters || "";
				}
				setIsTransitionning(true);
				const response = await apiPatch(
					(currentAction?.endpoint as string) + "?" + filters,
					payload
				);
				const updatedPricings = response?.data.data || [];
				updatedPricings.forEach((pricing: Pricing) => {
					wsPublisher$.publish({
						type: WsEventTypes.PRICING_UPDATED,
						data: {
							...pricing,
							isNewUpdate: true,
						},
						at: new Date().toJSON(),
					});
				});
				if (onSuccess.current) {
					onSuccess.current();
				}
				setError(undefined);
				clear();
			} catch (err: any) {
				setIsTransitionning(false);
				setError(err?.response?.data);
				if (err?.response?.status === 400) {
					setOpenErrorModal(true);
				}
			} finally {
				if (onFinishLoading) {
					onFinishLoading(ids);
				}
				setIsTransitionning(false);
				setCurrentAction(null);
			}
		},
		[pricingsUnderAction, currentAction, isServerSideAction]
	);

	// automatically submit action if we have the pricings and a modal is not needed
	useEffect(() => {
		if (
			pricingsUnderAction?.length &&
			currentAction &&
			!actionNeedsModal(currentAction)
		) {
			submit(pricingsUnderAction.map((pricing) => pricing.id));
		}
	}, [currentAction]);

	const submitActionModal = useCallback(
		(
			values: any,
			ids: number[],
			pricingPartyIds: { id: number; party_id: number }[] = []
		) => {
			submit(ids, values, pricingPartyIds);
		},
		[submit]
	);

	const cancelActionModal = useCallback(() => {
		if (!isTransitionning && !areServerSidePricingsUnderActionLoading) {
			clear();
		}
	}, [clear, isTransitionning, areServerSidePricingsUnderActionLoading]);

	useEffect(() => {
		// clear modal after submit is successful
		if (
			wasTransitionLoading &&
			!isTransitionning &&
			actionNeedsModal(currentAction) &&
			!error
		) {
			clear();
		}
	}, [wasTransitionLoading, isTransitionning, actionNeedsModal, error]);

	const errorMessage = useMemo(() => {
		if (error?.errors?.[0] in ACTION_PRICING_ERROR) {
			return ACTION_PRICING_ERROR[error?.errors[0] as PricingError];
		} else if (error?.errors?.[0] && error?.errors?.[0]?.msg) {
			return error?.errors?.[0]?.msg;
		} else if (error) {
			return "Unknwon error" + error;
		}
	}, [error]);

	const actionModal = useMemo(
		() => (
			<>
				<If
					condition={
						!ACTIONS_WITH_CUSTOM_MODALS.includes(
							currentAction?.action || ""
						)
					}
				>
					<ActionPricingModal
						open={
							isActionModalOpen &&
							!!currentAction?.action &&
							!["BOOK", "ADD_PARTY"].includes(
								currentAction?.action
							)
						}
						action={currentAction}
						errorMessage={error}
						forPricings={pricingsUnderAction}
						onSubmit={(body) =>
							submitActionModal(
								body,
								(pricingsUnderAction || []).map(
									(selectedPricing) => selectedPricing.id
								)
							)
						}
						onClose={cancelActionModal}
						isLoading={isActionLoading}
						hasMorePricings={
							!!serverSidePricingsUnderActionResponse?.has_next_page
						}
					/>
				</If>
				<If
					condition={
						pricingsUnderAction[0]?.portfolio_id &&
						currentAction?.action === "ADD_REALIZED"
					}
				>
					<AddRealizedPortModal
						open={
							isActionModalOpen &&
							currentAction?.action === "ADD_REALIZED"
						}
						action={currentAction}
						errorMessage={error}
						forPricings={pricingsUnderAction}
						onSubmit={(body) =>
							submitActionModal(
								body,
								(pricingsUnderAction || []).map(
									(selectedPricing) => selectedPricing.id
								)
							)
						}
						onClose={cancelActionModal}
						isLoading={isActionLoading}
					/>
				</If>
				<If
					condition={
						!pricingsUnderAction[0]?.portfolio_id &&
						currentAction?.action === "ADD_REALIZED"
					}
				>
					<AddRealizedModal
						open={
							isActionModalOpen &&
							currentAction?.action === "ADD_REALIZED"
						}
						action={currentAction}
						errorMessage={error}
						forPricings={pricingsUnderAction}
						onSubmit={(body) =>
							submitActionModal(
								body,
								(pricingsUnderAction || []).map(
									(selectedPricing) => selectedPricing.id
								)
							)
						}
						onClose={cancelActionModal}
						isLoading={isActionLoading}
					/>
				</If>
				<If condition={currentAction?.action === "ADD_PARTY"}>
					<AddPartyModal
						open={
							isActionModalOpen &&
							currentAction?.action === "ADD_PARTY"
						}
						action={currentAction}
						errorMessage={error}
						forPricings={pricingsUnderAction}
						onSubmit={(body) =>
							submitActionModal(
								body,
								(pricingsUnderAction || []).map(
									(selectedPricing) => selectedPricing.id
								)
							)
						}
						onClose={cancelActionModal}
						isLoading={isActionLoading}
					/>
				</If>
				<If condition={currentAction?.action === "BOOK"}>
					<BookingModal
						open={
							isActionModalOpen &&
							currentAction?.action === "BOOK"
						}
						isLoading={isActionLoading}
						forPricings={pricingsUnderAction || []}
						onClose={cancelActionModal}
						onSubmit={(values, pricingIds, pricingPartyIds) => {
							submitActionModal(
								values,
								pricingIds,
								pricingPartyIds
							);
						}}
					/>
				</If>

				<If condition={currentAction?.action === "FIX_PRICE"}>
					<SetFixingActionModal
						open={
							isActionModalOpen &&
							currentAction?.action === "FIX_PRICE"
						}
						isLoading={isActionLoading}
						forPricings={pricingsUnderAction || []}
						onClose={cancelActionModal}
						onSubmit={(body) =>
							submitActionModal(
								body,
								(pricingsUnderAction || []).map(
									(selectedPricing) => selectedPricing.id
								)
							)
						}
						errorMessage={error}
					/>
				</If>

				<If condition={!!error && openErrorModal}>
					<ErrorModal
						message={errorMessage}
						open={openErrorModal}
						onClose={() => {
							setOpenErrorModal(false);
							setError(undefined);
						}}
						pricingsIds={error?.ids}
					/>
				</If>
			</>
		),
		[
			isActionModalOpen,
			currentAction,
			pricingsUnderAction,
			submitActionModal,
			cancelActionModal,
			isActionLoading,
			openErrorModal,
			errorMessage,
		]
	);

	return {
		isActionModalOpen,
		currentAction,
		isActionLoading,
		pricingsUnderAction,
		submitActionModal,
		actOnPricings,
		cancelActionModal,
		actionModal,
	};
}
