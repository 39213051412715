import { apiGet } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import { getSitesError, getSitesSuccess, setLoadingState } from "./siteSlice";

export function getTprSites(
	search: string = "",
	onlyPricingReady: boolean = true,
	page: number = 1,
	sort_by: string = "",
	order: string = "asc"
): any {
	return function (dispatch: AppDispatch) {
		dispatch(setLoadingState(true));
		const urlParams = new URLSearchParams({
			search,
			per_page: "100",
			sort_by,
			order,
		});
		if (onlyPricingReady) {
			urlParams.set("filters", `is_pricing_ready eq true`);
		}
		urlParams.set("page", "" + page);
		const url = `/sites?${urlParams.toString()}`;
		return apiGet(url).then(
			(response) => {
				dispatch(setLoadingState(false));
				return dispatch(
					getSitesSuccess({
						sites: response?.data?.data || [],
						page,
					})
				);
			},
			(error) => {
				dispatch(setLoadingState(false));
				return dispatch(getSitesError(error.message));
			}
		);
	};
}

export function getSites(page: number, search: string = ""): any {
	return function (dispatch: AppDispatch) {
		dispatch(setLoadingState(true));
		return apiGet(
			`sites?page=${page}&limit=20&name_contains=${search}&sort_by=id&order=desc`
		).then(
			(sites) => {
				return dispatch(
					getSitesSuccess({
						sites: sites.data.data,
						page: page,
					})
				);
			},
			(error) => {
				return dispatch(getSitesError(error.message));
			}
		);
	};
}

export default {
	getSites,
};
