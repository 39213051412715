export enum NativeEvent {
  click = 'click',
  dblclick = 'dblclick',
  mouseup = 'mouseup',
  mousedown = 'mousedown',
  contextmenu = 'contextmenu',
  mousewheel = 'mousewheel',
  DOMMouseScroll = 'DOMMouseScroll',
  mouseover = 'mouseover',
  mouseout = 'mouseout',
  mousemove = 'mousemove',
  selectstart = 'selectstart',
  selectend = 'selectend',
  keydown = 'keydown',
  keypress = 'keypress',
  keyup = 'keyup',
  orientationchange = 'orientationchange',
  touchstart = 'touchstart',
  touchmove = 'touchmove',
  touchend = 'touchend',
  touchcancel = 'touchcancel',
  pointerdown = 'pointerdown',
  pointermove = 'pointermove',
  pointerup = 'pointerup',
  pointerleave = 'pointerleave',
  pointercancel = 'pointercancel',
  gesturestart = 'gesturestart',
  gesturechange = 'gesturechange',
  gestureend = 'gestureend',
  focus = 'focus',
  blur = 'blur',
  change = 'change',
  reset = 'reset',
  select = 'select',
  submit = 'submit',
  focusin = 'focusin',
  focusout = 'focusout',
  load = 'load',
  unload = 'unload',
  beforeunload = 'beforeunload',
  resize = 'resize',
  move = 'move',
  DOMContentLoaded = 'DOMContentLoaded',
  readystatechange = 'readystatechange',
  error = 'error',
  abort = 'abort',
  scroll = 'scroll'
}
