import * as _ from "lodash";
import { ColumnConfigPreference, ColumnIds } from "./common";
import { Column } from "../../../../common/components/ColumnsSelector/ColumnsSelector";

/**
 * Migrations for column configuration in localstorage
 * Please check the following points:
 *
 * - make them idempotent (eg by checking previous version number)
 * - make them pure functions (clone instead of mutate)
 * - pay attention to order in migration array
 * - make sure ot increase version number
 */

function v1ToV2(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 1) {
		return {
			...previous,
			data: previous.data.filter(
				(column) => !["shape", "bidaskshape"].includes(column.id)
			),
			version: 2,
		};
	}
	return previous;
}

function v2ToV3(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 2) {
		return {
			...previous,
			data: [
				...previous.data,
				{
					label: "customer p50",
					id: ColumnIds.CUSTOMER_P50,
					disabled: false,
				},
			],
			version: 3,
		};
	}
	return previous;
}

function v3ToV4(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 3) {
		previous.data.push({
			label: "Click granularity",
			id: ColumnIds.CLICKABILITY_GRANULARITY,
			disabled: false,
		});
	}
	return previous;
}

function v4ToV5(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 4) {
		const newData = [
			...previous.data,
			{ label: "shape", id: ColumnIds.SHAPE },
			{ label: "bidaskshape", id: "bidaskshape" as ColumnIds },
		];
		return { ...previous, data: _.uniqBy(newData, "id"), version: 5 };
	}
	return previous;
}

function v5ToV6(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 5) {
		return {
			...previous,
			data: [
				...previous.data,
				{
					id: ColumnIds.CLEANING_WARNING,
					label: "Warning",
					disabled: false,
				},
			],
			version: 6,
		};
	}
	return previous;
}

function v6ToV7(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 6) {
		const allColumns: Column<ColumnIds>[] = [
			...previous.data,
			{ label: "Setup fee", id: ColumnIds.SETUPFEE },
			{
				label: "Imbalance cost provision",
				id: ColumnIds.IMBALANCECOSTPROVISION,
			},
			{
				label: "Profile cost provision",
				id: ColumnIds.PROFILECOSTPROVISION,
			},
			{
				label: "Delta profile cost provision",
				id: ColumnIds.DELTAPROFILECOSTPROVISION,
			},
			{ label: "Shape provision", id: ColumnIds.SHAPEPROVISION },
			{
				label: "Shape Bid Ask provision",
				id: ColumnIds.SHAPEBIDASKPROVISION,
			},
		];
		const v7ColumnOrder = [
			ColumnIds.PGID,
			ColumnIds.PID,
			ColumnIds.PRID,
			ColumnIds.TOURBILLON_REF,
			ColumnIds.PR_STATUS,
			ColumnIds.PR_SITE,
			ColumnIds.PR_ALPHA_OR_BETA,
			ColumnIds.P_TYPE_NAME,
			ColumnIds.P_TECHNO_NAME,
			ColumnIds.PRICING_DATE,
			ColumnIds.P_VALIDITY_DATE,
			ColumnIds.P_PARTY,
			ColumnIds.P_BASE_CAPA,
			ColumnIds.CLICKABILITY,
			ColumnIds.CLICKABILITY_GRANULARITY,
			ColumnIds.DELIVERY_PERIOD,
			ColumnIds.P_INSTALL_CAPA,
			ColumnIds.P_CHAPTER_51,
			ColumnIds.CUSTOMER_P50,
			ColumnIds.P50,
			ColumnIds.PROXY,
			ColumnIds.LF,
			ColumnIds.QTY,
			ColumnIds.CROSS,
			ColumnIds.GOO_CROSS,
			ColumnIds.GOO_ADJ,
			ColumnIds.XBC,
			ColumnIds.XPC,
			ColumnIds.XDPC,
			ColumnIds.SETUPFEE,
			ColumnIds.IMBALANCECOSTPROVISION,
			ColumnIds.PROFILECOSTPROVISION,
			ColumnIds.DELTAPROFILECOSTPROVISION,
			ColumnIds.SHAPEPROVISION,
			ColumnIds.SHAPEBIDASKPROVISION,
			ColumnIds.P50_PROV,
			ColumnIds.SHAPE,
			ColumnIds.MARGIN_PROV,
			ColumnIds.GOO_PRICE,
			ColumnIds.MARGIN_PROVISION,
			ColumnIds.ALPHA,
			ColumnIds.PR_BETA,
			ColumnIds.INDIC,
			ColumnIds.PR_CM,
			ColumnIds.PR_ALPHACLIENT,
			ColumnIds.PR_INDICCLIENT,
			ColumnIds.PR_FIXING_DT,
			ColumnIds.PR_BASELOAD_PRICE,
			ColumnIds.PR_BASELOAD_MID,
			ColumnIds.PR_COMMERCIAL_MARGIN,
			ColumnIds.PR_FIXPRICE,
			ColumnIds.PR_BETAFIX,
			ColumnIds.PR_CONTRACT_ID,
			ColumnIds.CLEANING_WARNING,
		];
		return {
			...previous,
			data: v7ColumnOrder
				.map((columnId) =>
					_.find(allColumns, (column) => column.id === columnId)
				)
				.filter((column) => !!column) as Column<ColumnIds>[],
			version: 7,
		};
	}
	return previous;
}

function v7ToV8(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 7) {
		const allColumns: Column<ColumnIds>[] = previous.data;
		// Re-order columns + defualt hide provisions
		const v7ColumnOrder = [
			ColumnIds.PGID,
			ColumnIds.PID,
			ColumnIds.PRID,
			ColumnIds.TOURBILLON_REF,
			ColumnIds.PR_STATUS,
			ColumnIds.PR_SITE,
			ColumnIds.PR_ALPHA_OR_BETA,
			ColumnIds.P_TYPE_NAME,
			ColumnIds.P_TECHNO_NAME,
			ColumnIds.PRICING_DATE,
			ColumnIds.P_VALIDITY_DATE,
			ColumnIds.P_PARTY,
			ColumnIds.P_BASE_CAPA,
			ColumnIds.CLICKABILITY,
			ColumnIds.CLICKABILITY_GRANULARITY,
			ColumnIds.DELIVERY_PERIOD,
			ColumnIds.P_INSTALL_CAPA,
			ColumnIds.P_CHAPTER_51,
			ColumnIds.CUSTOMER_P50,
			ColumnIds.P50,
			ColumnIds.PROXY,
			ColumnIds.LF,
			ColumnIds.QTY,
			ColumnIds.CROSS,
			ColumnIds.GOO_CROSS,
			ColumnIds.GOO_ADJ,
			ColumnIds.XBC,
			ColumnIds.XPC,
			ColumnIds.XDPC,
			ColumnIds.SHAPE,
			ColumnIds.P50_PROV,
			ColumnIds.MARGIN_PROV,
			ColumnIds.GOO_PRICE,
			ColumnIds.MARGIN_PROVISION,
			ColumnIds.ALPHA,
			ColumnIds.PR_BETA,
			ColumnIds.INDIC,
			ColumnIds.PR_CM,
			ColumnIds.PR_ALPHACLIENT,
			ColumnIds.PR_INDICCLIENT,
			ColumnIds.PR_FIXING_DT,
			ColumnIds.PR_BASELOAD_PRICE,
			ColumnIds.PR_BASELOAD_MID,
			ColumnIds.PR_COMMERCIAL_MARGIN,
			ColumnIds.PR_FIXPRICE,
			ColumnIds.PR_BETAFIX,
			ColumnIds.PR_CONTRACT_ID,
			ColumnIds.CLEANING_WARNING,
		];
		return {
			...previous,
			data: v7ColumnOrder
				.map((columnId) =>
					_.find(allColumns, (column) => column.id === columnId)
				)
				.filter((column) => !!column) as Column<ColumnIds>[],
			version: 8,
		};
	}
	return previous;
}

function v8ToV9(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 8) {
		return {
			...previous,
			data: [
				...previous.data,
				{
					label: "Goo Commercial Margin",
					id: ColumnIds.FIXINGGOOCOMMERCIALMARGIN,
				},
				{ label: "Goo Final Price", id: ColumnIds.FINALGOOPRICE },
			],
			version: 9,
		};
	}
	return previous;
}

function v9ToV10(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 9) {
		const data = previous.data;
		data.push({
			label: "Negative price",
			id: ColumnIds.NEGATIVEPRICEVALUE,
		});
		return { ...previous, version: 10, data };
	}

	return previous;
}

function v10ToV11(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 10) {
		const data = previous.data;
		data.push({
			label: "Hedge Ratio",
			id: ColumnIds.HEDGERATIOPERCENT,
		});
		return { ...previous, version: 11, data };
	}

	return previous;
}

function v11ToV12(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 11) {
		const data = previous.data;
		data.push({ label: "Balancing Group", id: ColumnIds.BALANCING_GROUP });
		return { ...previous, version: 12, data };
	}

	return previous;
}

function v12ToV13(previous: ColumnConfigPreference): ColumnConfigPreference {
	if (previous.version === 12) {
		const data = previous.data;
		data.push({ label: "Alpha Index", id: ColumnIds.ALPHAINDEX });
		data.push({
			label: "Total Power Margin",
			id: ColumnIds.TOTALPOWERMARGIN,
		});
		data.push({ label: "Total GOO Margin", id: ColumnIds.TOTALGOOMARGIN });
		return { ...previous, version: 13, data };
	}

	return previous;
}

const migrations = [
	v1ToV2,
	v2ToV3,
	v3ToV4,
	v4ToV5,
	v5ToV6,
	v6ToV7,
	v7ToV8,
	v8ToV9,
	v9ToV10,
	v10ToV11,
	v11ToV12,
	v12ToV13,
];
const migrateFn = _.flow(migrations);

export function migrate(
	previous: ColumnConfigPreference
): ColumnConfigPreference {
	return migrateFn(previous);
}
