/**
 * --------------------------------------------------------------------------
 * NJ: dropdown.ts
 * --------------------------------------------------------------------------
 */
import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import CollapseComponent from '../collapse';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
export default class Dropdown extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-dropdown`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.dropdown`;
  protected static readonly EVENT_KEY = `.${Dropdown.DATA_KEY}`;
  protected static readonly DATA_API_KEY = Core.KEY_PREFIX;
  private static readonly ESCAPE_KEYCODE = 27; // KeyboardEventName.which value for Escape

  private static readonly ATTRIBUTE = {
    content: 'data-content',
    selectedContent: 'data-selected-content',
    value: 'data-value'
  };

  private static readonly CLASS_NAME = {
    isFilled: `${Dropdown.NAME}__label--is-filled`,
    shownCollapse: `${Dropdown.NAME}--shown-collapse`
  };

  protected static readonly SELECTOR = {
    default: `.${Dropdown.NAME}`,
    input: 'input',
    label: `.${Dropdown.NAME}__label`,
    options: `.${Dropdown.NAME} [${Dropdown.ATTRIBUTE.value}]`
  };

  private static readonly EVENT = {
    clickDataApi: `${EventName.mousedown}${Dropdown.EVENT_KEY}${Dropdown.DATA_API_KEY}`,
    keydownDismiss: `${EventName.keydown}.dismiss${Dropdown.EVENT_KEY}`,
    onchange: `${EventName.onchange}${Dropdown.EVENT_KEY}`
  };

  value: string;

  constructor(element: HTMLElement) {
    super(Dropdown, element);

    Data.setData(element, Dropdown.DATA_KEY, this);
    this.addBlurEvent();
    this.addTouchEvent();
    this.registerEvents();
  }

  closeMenu(): void {
    // trigger click to hide options when element has lost focus
    if (this.element.classList.contains(Dropdown.CLASS_NAME.shownCollapse)) {
      this.element.click();
    }
  }

  dispose(): void {
    Data.removeData(this.element, Dropdown.DATA_KEY);
    this.element = null;
  }

  static handleCollapseShow(event): void {
    event.target.closest(Dropdown.SELECTOR.default).classList.add(Dropdown.CLASS_NAME.shownCollapse);
  }

  static handleCollapseHide(event): void {
    event.target.closest(Dropdown.SELECTOR.default).classList.remove(Dropdown.CLASS_NAME.shownCollapse);
  }

  static getInstance(element: HTMLElement): Dropdown {
    return Data.getData(element, Dropdown.DATA_KEY) as Dropdown;
  }

  static init(options = {}): Dropdown[] {
    return super.init(this, options, Dropdown.SELECTOR.default) as Dropdown[];
  }

  private addTouchEvent(): void {
    EventHandler.on(this.element, Dropdown.EVENT.keydownDismiss, (event) => {
      if (event.which === Dropdown.ESCAPE_KEYCODE) {
        event.preventDefault();
        this.closeMenu();
      }
    });
  }

  private addBlurEvent(): void {
    EventHandler.on(this.element, 'blur', () => {
      this.closeMenu();
    });
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */
  private registerEvents(): void {
    EventHandler.on(document, CollapseComponent.EVENT.show, Dropdown.SELECTOR.default, (event) => {
      Dropdown.handleCollapseShow(event);
    });

    EventHandler.on(document, CollapseComponent.EVENT.hidden, Dropdown.SELECTOR.default, (event) => {
      Dropdown.handleCollapseHide(event);
    });

    EventHandler.on(document, Dropdown.EVENT.clickDataApi, Dropdown.SELECTOR.options, (event) => {
      // HTML elements
      const option = event.target.closest(`[${Dropdown.ATTRIBUTE.value}]`);
      const dropdown = event.target.closest(Dropdown.SELECTOR.default);
      const elemDataContent = option.querySelector(`[${Dropdown.ATTRIBUTE.content}]`);
      const input = dropdown.querySelector(Dropdown.SELECTOR.input);

      // Data
      const dataSelectedValue = option.getAttribute(Dropdown.ATTRIBUTE.value);
      const dataContent = elemDataContent ? elemDataContent.textContent : option.textContent; // get option content if 'data-content' attribute doesn't exist

      input.value = dataSelectedValue;
      dropdown.setAttribute(Dropdown.ATTRIBUTE.selectedContent, dataContent);
      this.value = dataSelectedValue;

      EventHandler.trigger(this.element, Dropdown.EVENT.onchange);
    });
  }
}
