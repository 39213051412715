const Testing = {
  describe(element): string {
    if (typeof element === 'undefined') {
      return 'undefined';
    } else if (element.length === 0) {
      return '(no matching elements)';
    }
    return `${element.outerHTML.split('>')[0]}>`;
  },

  assert(element, invalidTest, message: string): void {
    if (invalidTest) {
      if (typeof element !== 'undefined') {
        element.style.border = '1px solid red';
      }
      console.error(message, element); // eslint-disable-line no-console
      throw message;
    }
  },

  isChar(event): boolean {
    const eventBackspace = 8;
    const eventCapsLock = 20;
    const eventCtrl = 17;
    const eventEnter = 13;
    const eventEscape = 27;
    const eventShift = 16;
    const eventTab = 9;

    if (typeof event.which === 'undefined') {
      return true;
    } else if (typeof event.which === 'number' && event.which > 0) {
      return (
        !event.ctrlKey &&
        !event.metaKey &&
        !event.altKey &&
        event.which !== eventBackspace && // backspace
        event.which !== eventTab && // tab
        event.which !== eventEnter && // enter
        event.which !== eventShift && // shift
        event.which !== eventCtrl && // ctrl
        event.which !== eventCapsLock && // caps lock
        event.which !== eventEscape // escape
      );
    }
    return false;
  }
};

export default Testing;
