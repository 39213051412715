/**
 * --------------------------------------------------------------------------
 * NJ : Radio.ts
 * --------------------------------------------------------------------------
 */
import AbstractFormBaseSelection from '../../globals/ts/abstract-form-base-selection';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import Manipulator from '../../globals/ts/manipulator';
export default class Radio extends AbstractFormBaseSelection {
  static readonly NAME = `${Core.KEY_PREFIX}-radio`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.radio`;

  protected static readonly SELECTOR = {
    default: `.${Radio.NAME} > label > input[type=radio]`,
    formGroup: AbstractFormBaseSelection.SELECTOR.formGroup,
    label: AbstractFormBaseSelection.SELECTOR.label
  };

  protected static readonly DEFAULT_OPTIONS = {
    template: `<span class="${Core.KEY_PREFIX}-radio__decorator"></span>`,
    njFormGroup: {
      required: false
    }
  };

  constructor(
    element: HTMLInputElement,
    options = {},
    properties = {
      inputType: 'radio',
      outerClass: `${Core.KEY_PREFIX}-radio`
    }
  ) {
    super(Radio, element, Manipulator.extend(true, Radio.DEFAULT_OPTIONS, options), properties);
    Data.setData(element, Radio.DATA_KEY, this);
    const parent: AbstractNode = element.parentNode as AbstractNode;
    const grandParent: AbstractNode = parent.parentNode as AbstractNode;
    Data.setData(grandParent, Radio.DATA_KEY, this);
  }

  dispose(): void {
    Data.removeData(this.element, Radio.DATA_KEY);
    this.element = null;
  }

  matches(): boolean {
    return this.element.getAttribute('type') === 'radio';
  }

  static getInstance(element: HTMLInputElement): Radio {
    return Data.getData(element, Radio.DATA_KEY) as Radio;
  }

  static init(options = {}): Radio[] {
    return super.init(this, options, Radio.SELECTOR.default) as Radio[];
  }
}

interface AbstractNode extends Node, ParentNode {
  key: { id: string; key: string };
}
