/**
 * --------------------------------------------------------------------------
 * NJ : Tag.ts
 * --------------------------------------------------------------------------
 */
import '../../globals/js/animation';
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import { EventName } from '../../globals/ts/enum/event-name';
import Manipulator from '../../globals/ts/manipulator';
export default class Tag extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-tag`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.tag`;

  protected static readonly KEYFRAMES = [
    {
      opacity: 1
    },
    {
      opacity: 0
    }
  ];
  static SELECTOR = {
    default: `.${Tag.NAME}:not(.disabled)`,
    close: `.${Tag.NAME}__close`
  };

  constructor(element: HTMLElement, options = {}) {
    super(Tag, element, Manipulator.extend(true, {}, options));
    EventHandler.on(element, EventName.click, this.handleClick.bind(this));
  }

  closeAndDestroy(): void {
    this.element.animate(Tag.KEYFRAMES, {
      duration: 200,
      delay: 70,
      easing: 'ease-out'
    }).onfinish = (): void => {
      this.destroyElement();
    };
  }

  async close(callback): Promise<any> {
    const duration = 200;
    const delay = 70;
    this.element.animate(Tag.KEYFRAMES, {
      duration,
      delay,
      easing: 'ease-out'
    });
    await new Promise((resolve) => setTimeout(resolve, duration + delay));
    callback();
  }

  /**
   * Remove element from DOM
   * */
  destroyElement(): void {
    if (this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }
    this.dispose();
  }

  dispose(): void {
    Data.removeData(this.element, Tag.DATA_KEY);
    EventHandler.off(this.element, EventName.click, this.handleClick.bind(this));
    this.element = null;
  }

  handleClick(event): void {
    if (event.target.closest(Tag.SELECTOR.close)) {
      event.preventDefault();
      this.closeAndDestroy();
    }
  }

  static init(options = {}): Tag[] {
    return super.init(this, options, Tag.SELECTOR.default) as Tag[];
  }

  static getInstance(element: HTMLElement): Tag {
    return Data.getData(element, Tag.DATA_KEY) as Tag;
  }

  static getRootElement(element: Element): Element {
    return element.closest(Tag.SELECTOR.default);
  }
}
