/**
 * --------------------------------------------------------------------------
 * NJ : Navbar.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import CollapseComponent from '../collapse';
import CollapseSearchBar from './collapse-searchbar';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
export default class Navbar extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-navbar`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.navbar`;

  static CLASS_NAME = {
    shownCollapse: `${Navbar.NAME}--shown-collapse`
  };

  static SELECTOR = {
    default: `.${Navbar.NAME}`
  };

  private collapseSearchBarInstances: CollapseSearchBar[];

  constructor(element: HTMLElement) {
    super(Navbar, element);
    this.collapseSearchBarInstances = CollapseSearchBar.init(); // Init CollapseSearchBar if its selector exists
    this.registerEvents();
  }

  dispose(): void {
    if (this.collapseSearchBarInstances.length > 0)
      this.collapseSearchBarInstances.forEach((collapseSearchBarInstance: CollapseSearchBar) => {
        if (collapseSearchBarInstance.getElement() === this.element.querySelector(CollapseSearchBar.SELECTOR.default))
          collapseSearchBarInstance.dispose();
      });
    Data.removeData(this.element, Navbar.DATA_KEY);
    this.element = null;
  }

  handleCollapseShow(): void {
    this.element.classList.add(Navbar.CLASS_NAME.shownCollapse);
  }

  handleCollapseHide(): void {
    this.element.classList.remove(Navbar.CLASS_NAME.shownCollapse);
  }

  static getInstance(element: HTMLElement): Navbar {
    return Data.getData(element, Navbar.DATA_KEY) as Navbar;
  }

  static init(options = {}): Navbar[] {
    return super.init(this, options, Navbar.SELECTOR.default) as Navbar[];
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */
  private registerEvents(): void {
    EventHandler.on(this.element, CollapseComponent.EVENT.show, () => {
      this.handleCollapseShow();
    });

    EventHandler.on(this.element, CollapseComponent.EVENT.hidden, () => {
      this.handleCollapseHide();
    });
  }
}
