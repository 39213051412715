import * as React from "react";
import { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { secondaryColor } from "../../../core/theme";
import { useAppDispatch } from "../../../common/hooks/default";
import {
	getTimeseriesBySites,
	triggerTimeserieCleaning,
	createTimeserie,
	createPricingsByTs,
} from "../tenderForm.thunk";
import {
	Box,
	CircularProgress,
	Radio,
	Tooltip,
	Button,
	SxProps,
	Theme,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
	selectTsBySite,
	selectTsBySiteLoader,
	selectTsTabError,
} from "../tenderForm.selector";
import { If } from "../../../common/components/If";
import { NO_CLEANED_STATUS, TS_SOURCE } from "../tenderForm.constant";
import {
	TenderGraphStatus,
	Timeseries,
	TimeseriesList,
	TsSource,
	getTimeserieStatusColor,
} from "../tenderForm.module";
import { PrimaryButton } from "../../../common/components/CustomButton";
import { useTenderActions } from "../../tender_page/hooks/useTenderActions";
import { Tender } from "../../tender_page/tender.module";
import ReplayIcon from "@mui/icons-material/Replay";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { TimeserieModal } from "./TimeserieModal";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { enqueueSnackbar } from "notistack";
import { useFormik } from "formik";
import { Site } from "../../sites/sites.module";
import { RootState } from "../../../core/rootReducers";
import { mergeStyles } from "../../../common/style";
import { TenderGraphAction } from "./TenderGraphAction";
import { AddTimeseriesButton } from "./AddTimeseriesButton";
import { UploadFileModal } from "./UploadFileModal";
import { linkFileToTender } from "../../../common/files/files.thunk";
import {
	UploadedFile,
	UploadingFile,
	isUploadedFile,
} from "../../../common/files/files.module";
import Checkbox from "@mui/material/Checkbox";

export interface TimeseriesTableProps {
	siteIds?: number[];
	tender?: Tender;
}

export interface TSInUse {
	asset_id?: string;
	site_id?: string;
	ts_id?: number;
	clean?: boolean;
	tourbillon_tag?: string;
}

export interface TSToClean {
	timeserieId: number;
	siteId: number;
	isNew: boolean;
}

const style: {
	[key: string]: SxProps<Theme> | { [key: string]: SxProps<Theme> };
} = {
	buttonContainer: {
		marginBottom: "16px",
		marginRight: "16px",
	},
	errorContainer: {
		marginBottom: "16px",
		marginRight: "16px",
		color: "red",
	},
	tableContainer: {
		backgroundColor: "transparent",
	},
	headerCellContainer: {
		backgroundColor: secondaryColor,
		color: "#000",
		fontWeight: 700,
		fontSize: 12,
		paddingLeft: 0,
		"& th": {
			fontWeight: 700,
			padding: "12px 16px",
		},
	},
	table: {
		tableLayout: "fixed",
		minWidth: 650,
	},
	siteNameCol: {
		width: "25%",
	},
	tsNameCol: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	copyIcon: {
		cursor: "pointer",
	},
	siteHeader: {
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		flex: 1,
		width: "100%",
		gap: "12px",
	},
	siteTable: {
		borderTop: "1px solid #000",
	},
	cell: {
		border: "0",
	},
	line: { border: "1px solid rgba(0, 0, 0, .2)" },
	noline: { border: "0px" },
};

export const TimeseriesTable = (props: TimeseriesTableProps) => {
	const { siteIds, tender } = props;

	const actions = tender?.actions_blotter?.filter((element: any) =>
		["TRIGGER_RENEWEX", "TRIGGER_TS_IMPORTER"].includes(element.action)
	);
	const [currentSiteToAddTs, setCurrentSiteToAddTs] = React.useState<
		number | null
	>(null);
	const [currentSiteToUploadFile, setCurrentSiteToUploadFile] =
		React.useState<number | null>(null);

	const dispatch = useAppDispatch();
	const [tsInUse, setTsInUse] = React.useState<{ [key: string]: TSInUse }>(
		{}
	);

	const [tsToClean, setTsToClean] = React.useState<{
		[key: string]: TSToClean;
	}>({});

	const tsBySite = useSelector(selectTsBySite);
	const error = useSelector((state: RootState) =>
		selectTsTabError(state, "createPricingByTs")
	);
	const loading = useSelector(selectTsBySiteLoader);

	useEffect(() => {
		if (Object.keys(tsInUse).length === 0) {
			let formInitValues: any = {};

			tsBySite.forEach((ts: TimeseriesList) => {
				if (ts.site_info?.asset_id) {
					let info = ts.ts?.filter(
						(element) =>
							element.tourbillon_tag === ts.site_info?.ts_in_use
					);
					formInitValues[ts.site_info?.asset_id] = {
						asset_id: ts.site_info.asset_id,
						site_id: ts.site_info.id,
						tourbillon_tag: info.length
							? info[0].tourbillon_tag
							: "Cluster",
						clean: info.length
							? info[0]?.status == "RAW"
								? false
								: true
							: false,
						ts_id: info.length ? info[0].id : undefined,
					};
				}
			});
			setTsInUse(formInitValues);
		}
	}, [tsBySite]);

	const formikTSInfo: any = useFormik({
		initialValues: {},
		enableReinitialize: true,
		onSubmit: () => {
			let tsList = Object.values(tsInUse);
			dispatch(
				createPricingsByTs(
					{
						tender_id: tender?.id,
						ts_list: tsList,
					},
					siteIds
				)
			);
		},
	});

	const formikTSClean: any = useFormik({
		initialValues: {},
		enableReinitialize: true,
		onSubmit: () => {
			dispatch(
				triggerTimeserieCleaning(
					Object.values(tsToClean),
					siteIds || []
				)
			);
			setTsToClean({});
		},
	});

	const handleInUseChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		site: Site,
		ts: any
	) => {
		setTsInUse((prev: any) => ({
			...prev,
			[event.target.name]: {
				asset_id: site.asset_id,
				site_id: site.id,
				tourbillon_tag: event.target.value,
				clean: ts.status === "RAW" ? false : true,
				ts_id: ts.id,
			},
		}));
	};

	const handleCleanChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		site: Site,
		ts: any
	) => {
		setTsToClean((prev: any) => {
			if (event.target.name in prev) {
				delete prev[event.target.name];
				return { ...prev };
			}
			return {
				...prev,
				[event.target.name]: {
					timeserieId: ts.id,
					siteId: site.id,
					isNew: ts.can_cleaning_be_retried ? false : true,
				},
			};
		});
	};

	useEffect(() => {
		if (siteIds && tsBySite.length === 0) {
			dispatch(getTimeseriesBySites({ siteIds: siteIds }));
		}
	}, []);

	const triggerCleaning = React.useCallback(
		(timeserieId: number, siteId: number, isNew: boolean) => {
			dispatch(
				triggerTimeserieCleaning(
					[
						{
							timeserieId,
							siteId,
							isNew,
						},
					],
					siteIds || []
				)
			);
		},
		[dispatch, siteIds]
	);

	const create = React.useCallback(
		(siteId: number, timeserieTag: string) => {
			dispatch(createTimeserie(siteId, timeserieTag, siteIds || []));
		},
		[dispatch, siteIds]
	);

	const linkUploadedFile = React.useCallback(
		(file: UploadingFile | UploadedFile) => {
			if (isUploadedFile(file) && tender?.id) {
				dispatch(linkFileToTender(file.id, tender?.id));
			}
		},
		[dispatch, tender]
	);

	const { tenderAction, tenderActionModal } = useTenderActions(tender);

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="flex-end"
				sx={style.buttonContainer}
			>
				<form onSubmit={formikTSClean.handleSubmit}>
					<PrimaryButton
						type="submit"
						color="secondary"
						sx={{
							marginRight: "16px",
						}}
					>
						{"Clean"}
					</PrimaryButton>
				</form>
				{tender &&
					TenderGraphAction(
						tender,
						Object.values(tsInUse),
						TenderGraphStatus[tender.graph_status]
					)}

				<form onSubmit={formikTSInfo.handleSubmit}>
					<PrimaryButton
						type="submit"
						color="secondary"
						sx={{
							marginRight: "16px",
						}}
					>
						{"Validate"}
					</PrimaryButton>
				</form>

				<PrimaryButton
					type="button"
					color="secondary"
					sx={{
						marginRight: "16px",
						width: 60,
					}}
					onClick={() => {
						if (siteIds && siteIds.length > 0) {
							dispatch(
								getTimeseriesBySites({ siteIds: siteIds })
							);
						}
					}}
				>
					<ReplayIcon />
				</PrimaryButton>
				{actions?.map((action: any, key: number) => (
					<PrimaryButton
						key={action + key}
						text={action.display_name}
						type="button"
						color="secondary"
						sx={{
							marginRight: "16px",
							width: 180,
						}}
						onClick={() => {
							tenderAction(action);
						}}
					></PrimaryButton>
				))}
			</Box>
			<If condition={!!error}>
				<Box
					display="flex"
					justifyContent="flex-center"
					sx={style.errorContainer}
				>
					{error}
				</Box>
			</If>

			<If condition={loading}>
				<Box display={"flex"} justifyContent={"center"}>
					<CircularProgress data-testid="loader" />
				</Box>
			</If>

			<If condition={!loading}>
				<TableContainer component={Paper} sx={style.tableContainer}>
					<Table sx={style.table} aria-label="simple table">
						<TableHead>
							<TableRow
								sx={mergeStyles(
									style.headerCellContainer,
									style.line
								)}
							>
								<TableCell
									sx={style.siteNameCol}
									component="th"
									scope="col"
								>
									Site
								</TableCell>
								<TableCell
									component="th"
									scope="col"
									sx={style.tsNameCol}
								>
									Ts Name
								</TableCell>
								<TableCell component="th" scope="col">
									Tourbillon tag
								</TableCell>
								<TableCell component="th" scope="col">
									Source
								</TableCell>
								<TableCell component="th" scope="col">
									Status
								</TableCell>
								<TableCell component="th" scope="col">
									In use
								</TableCell>
								<TableCell component="th" scope="col">
									Clean
								</TableCell>
								<TableCell component="th" scope="col">
									Source&nbsp;File
								</TableCell>
								<TableCell component="th" scope="col">
									Row&nbsp;Offset
								</TableCell>
								<TableCell component="th" scope="col">
									Tab
								</TableCell>
								<TableCell component="th" scope="col">
									Production&nbsp;value
								</TableCell>
								<TableCell component="th" scope="col">
									Actions
								</TableCell>
							</TableRow>
						</TableHead>

						{tsBySite.map((row: TimeseriesList) => (
							<TableBody
								sx={style.siteTable}
								key={row.site_info.name}
							>
								<If condition={row.ts.length > 0}>
									{row.ts?.map(
										(ts: Timeseries, index: number) => (
											<TableRow
												sx={
													index > 0
														? style.noline
														: style.line
												}
												key={ts.tourbillon_tag}
											>
												<If condition={index === 0}>
													<TableCell
														sx={style.cell}
														component="th"
														rowSpan={row.ts.length}
														scope="rowgroup"
													>
														<Box
															sx={
																style.siteHeader
															}
														>
															{row.site_info.name}
															<AddTimeseriesButton
																onAddManually={() =>
																	setCurrentSiteToAddTs(
																		row
																			.site_info
																			.id
																	)
																}
																onUploadFile={() => {
																	setCurrentSiteToUploadFile(
																		row
																			.site_info
																			.id
																	);
																}}
															/>
														</Box>
													</TableCell>
												</If>
												<TableCell
													component="td"
													scope="row"
													sx={mergeStyles(
														style.tsNameCol,
														style.cell
													)}
												>
													<Tooltip title={ts.name}>
														<span>{ts.name}</span>
													</Tooltip>
												</TableCell>
												<TableCell
													component="td"
													scope="row"
													sx={style.cell}
												>
													<Box sx={style.tsNameCol}>
														<ContentCopyIcon
															sx={style.copyIcon}
															onClick={() => {
																navigator.clipboard.writeText(
																	ts.tourbillon_tag ||
																		""
																);
																enqueueSnackbar(
																	"copied",
																	{
																		variant:
																			"success",
																		autoHideDuration: 1000,
																	}
																);
															}}
														/>
														<Tooltip
															title={
																ts.tourbillon_tag
															}
														>
															<span>
																{
																	ts.tourbillon_tag
																}
															</span>
														</Tooltip>
													</Box>
												</TableCell>
												<TableCell
													sx={style.cell}
													component="td"
												>
													{
														TS_SOURCE[
															TsSource[ts.source]
														]
													}
												</TableCell>
												<TableCell
													sx={{
														...style.cell,
														color: getTimeserieStatusColor(
															ts
														),
													}}
													component="td"
												>
													<If
														condition={
															ts.cleaning_error
														}
													>
														<Tooltip
															title={
																ts.cleaning_error
															}
														>
															<Box
																sx={{
																	color: "red",
																}}
															>
																{ts.status}
																<HelpOutlineIcon
																	sx={{
																		fontSize:
																			"16px",
																	}}
																/>
															</Box>
														</Tooltip>
													</If>
													<If
														condition={
															!ts.cleaning_error
														}
													>
														{ts.status}
													</If>
												</TableCell>

												<TableCell
													sx={style.cell}
													component="td"
												>
													<Radio
														checked={
															tsInUse[
																row.site_info
																	.asset_id ||
																	""
															]
																?.tourbillon_tag ===
															ts.tourbillon_tag
														}
														onChange={(
															event: React.ChangeEvent<HTMLInputElement>
														) =>
															handleInUseChange(
																event,
																row.site_info,
																ts
															)
														}
														value={
															ts.tourbillon_tag
														}
														name={
															row.site_info
																.asset_id
														}
														inputProps={{
															"aria-label":
																ts.tourbillon_tag,
														}}
													/>
												</TableCell>
												<TableCell
													sx={style.cell}
													component="td"
												>
													<Checkbox
														disabled={NO_CLEANED_STATUS.includes(
															ts.status
														)}
														checked={
															!!tsToClean[
																(ts.name ||
																	"") +
																	row
																		.site_info
																		.id
															]
														}
														name={
															(ts.name || "") +
															row.site_info.id
														}
														onChange={(
															event: React.ChangeEvent<HTMLInputElement>
														) =>
															handleCleanChange(
																event,
																row.site_info,
																ts
															)
														}
													/>
												</TableCell>

												<TableCell
													sx={style.cell}
													component="td"
												></TableCell>
												<TableCell
													sx={style.cell}
													component="td"
												></TableCell>
												<TableCell
													sx={style.cell}
													component="td"
												></TableCell>
												<TableCell
													sx={style.cell}
													component="td"
												></TableCell>
												<TableCell
													sx={style.cell}
													component="td"
												>
													<If
														condition={
															ts.can_be_cleaned
														}
													>
														<Button
															variant="text"
															onClick={() => {
																triggerCleaning(
																	ts.id,
																	row
																		.site_info
																		.id,
																	true
																);
															}}
														>
															Clean
														</Button>
													</If>
													<If
														condition={
															ts.can_cleaning_be_retried
														}
													>
														<Button
															variant="text"
															color="warning"
															onClick={() => {
																triggerCleaning(
																	ts.id,
																	row
																		.site_info
																		.id,
																	false
																);
															}}
														>
															Retry
														</Button>
													</If>
												</TableCell>
											</TableRow>
										)
									)}
								</If>
								<If condition={row.ts.length === 0}>
									<TableRow key={row.site_info.name}>
										<TableCell
											sx={style.cell}
											component="th"
											rowSpan={row.ts.length}
											scope="rowgroup"
										>
											<Box sx={style.siteHeader}>
												{row.site_info.name}
												<AddIcon
													sx={style.addIcon}
													onClick={() =>
														setCurrentSiteToAddTs(
															row.site_info.id
														)
													}
												/>
											</Box>
										</TableCell>
										<TableCell
											component="td"
											scope="row"
											sx={mergeStyles(
												style.tsNameCol,
												style.cell
											)}
										></TableCell>
										<TableCell
											sx={style.cell}
											component="td"
										></TableCell>
										<TableCell
											sx={style.cell}
											component="td"
										></TableCell>
										<TableCell
											sx={style.cell}
											component="td"
										></TableCell>
										<TableCell
											sx={style.cell}
											component="td"
										></TableCell>
										<TableCell
											sx={style.cell}
											component="td"
										></TableCell>
										<TableCell
											sx={style.cell}
											component="td"
										></TableCell>
										<TableCell
											sx={style.cell}
											component="td"
										></TableCell>
									</TableRow>
								</If>
							</TableBody>
						))}
					</Table>
				</TableContainer>
			</If>
			<TimeserieModal
				onClose={() => setCurrentSiteToAddTs(null)}
				isOpen={!!currentSiteToAddTs}
				onSubmit={(tag: string) => {
					if (currentSiteToAddTs) {
						setCurrentSiteToAddTs(null);
						create(currentSiteToAddTs, tag);
					}
				}}
			/>
			<UploadFileModal
				onClose={() => setCurrentSiteToUploadFile(null)}
				isOpen={!!currentSiteToUploadFile}
				onSubmit={linkUploadedFile}
				associatedSiteId={currentSiteToUploadFile}
			/>
			{tenderActionModal}
		</Box>
	);
};
