/**
 * --------------------------------------------------------------------------
 * NJ: textarea.ts
 * --------------------------------------------------------------------------
 */
import AbstractFormControl from '../../globals/ts/abstract-form-control';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import Manipulator from '../../globals/ts/manipulator';
export default class TextareaInput extends AbstractFormControl {
  static readonly NAME = `${Core.KEY_PREFIX}-form-input-textarea`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.textarea`;

  static readonly SELECTOR = {
    default: 'textarea.nj-form-control',
    formGroup: AbstractFormControl.SELECTOR.formGroup
  };

  constructor(element: HTMLTextAreaElement, options = {}) {
    super(TextareaInput, element, Manipulator.extend(true, {}, options));

    Data.setData(element, TextareaInput.DATA_KEY, this);
  }

  dispose(): void {
    super.dispose();
    Data.removeData(this.element, TextareaInput.DATA_KEY);
    this.element = null;
  }

  static init(options = {}): TextareaInput[] {
    return super.init(this, options, TextareaInput.SELECTOR.default) as TextareaInput[];
  }

  static getInstance(element: HTMLTextAreaElement): TextareaInput {
    return Data.getData(element, TextareaInput.DATA_KEY) as TextareaInput;
  }

  static matches(element: Element): boolean {
    return element.tagName === 'TEXTAREA';
  }
}
