/**
 * --------------------------------------------------------------------------
 * NJ : abstract-form-base-input.ts
 * --------------------------------------------------------------------------
 */

import AbstractFormBase from './abstract-form-base';
import EventHandler from './event-handler';
import Manipulator from './manipulator';
import Testing from './testing';

export default abstract class AbstractFormBaseInput extends AbstractFormBase {
  protected static readonly CLASS_NAME = {
    njFormGroup: 'nj-form-group',
    njLabel: 'nj-label',
    njLabelStatic: 'nj-label-static',
    njLabelPlaceholder: 'nj-label-placeholder',
    njLabelFloating: 'nj-label-floating',
    hasDanger: 'has-danger',
    isFilled: 'is-filled',
    isFocused: 'is-focused',
    inputGroup: 'input-group'
  };

  protected static readonly INPUT_SELECTOR = {
    njFormGroup: `.${AbstractFormBaseInput.CLASS_NAME.njFormGroup}`,
    njLabelWildcard: `label[class^='${AbstractFormBaseInput.CLASS_NAME.njLabel}'], label[class*=' ${AbstractFormBaseInput.CLASS_NAME.njLabel}']`
  };

  protected static readonly DEFAULT_OPTIONS = {
    validate: false,
    njFormGroup: {
      template: 'span',
      templateClass: `${AbstractFormBaseInput.CLASS_NAME.njFormGroup}`
    },
    label: {
      required: false,
      selectors: [
        '.form-control-label', // in the case of horizontal or inline forms, this will be marked
        ':scope > label' // usual case for text inputs, first child.  Deeper would find toggle labels so don't do that.
      ],
      className: AbstractFormBaseInput.CLASS_NAME.njLabelStatic
    },
    requiredClasses: [],
    convertInputSizeVariations: true
  };

  private static readonly FORM_CONTROL_SIZE_MARKERS = {
    'form-control-lg': 'nj-form-group-lg',
    'form-control-sm': 'nj-form-group-sm'
  };

  constructor(component, element: HTMLElement, options = {}, properties = {}) {
    super(component, element, Manipulator.extend(true, AbstractFormBaseInput.DEFAULT_OPTIONS, options), properties);

    // The layout has to contain the class .nj-form-group if needed. The wrapper will not be automatically added
    this.njFormGroup = this.resolveNJFormGroup();

    if (this.njFormGroup) {
      // Resolve and mark the njLabel if necessary as defined by the config
      this.resolveNJLabel();

      // Signal to the nj-form-group that a form-control-* variation is being used
      this.resolveNJFormGroupSizing();

      this.addFocusListener();
      this.addChangeListener();

      if (this.isEmpty()) {
        this.removeIsFilled();
      } else {
        this.addIsFilled();
      }
    }
  }

  dispose(): void {
    EventHandler.off(this.element, 'focus');
    EventHandler.off(this.element, 'blur');
    EventHandler.off(this.element, 'keydown');
    EventHandler.off(this.element, 'keyup');
  }

  addFocusListener(): void {
    EventHandler.on(this.element, 'focus', () => {
      this.addFormGroupFocus();
    });

    EventHandler.on(this.element, 'blur', () => {
      this.removeFormGroupFocus();
    });
  }

  addChangeListener(): void {
    EventHandler.on(this.element, 'keydown', (event) => {
      if (Testing.isChar(event)) {
        this.addIsFilled();
      }
    });
    EventHandler.on(this.element, 'keyup', () => {
      // make sure empty is added back when there is a programmatic value change.
      //  NOTE: programmatic changing of value using $.val() must trigger the change event i.e. $.val('x').trigger('change')
      if (this.isEmpty()) {
        this.removeIsFilled();
      } else {
        this.addIsFilled();
      }

      if (this.options.validate) {
        // Validation events do not bubble, so they must be attached directly to the text: http://jsfiddle.net/PEpRM/1/
        //  Further, even the bind method is being caught, but since we are already calling #checkValidity here, just alter
        //  the form-group on change.
        //
        // NOTE: I'm not sure we should be intervening regarding validation, this seems better as a README and snippet of code.
        //        BUT, I've left it here for backwards compatibility.
        const isValid = typeof this.element[0].checkValidity === 'undefined' || this.element[0].checkValidity();
        if (isValid) {
          this.removeHasDanger();
        } else {
          this.addHasDanger();
        }
      }
    });
  }

  addHasDanger(): void {
    this.njFormGroup.classList.add(AbstractFormBaseInput.CLASS_NAME.hasDanger);
  }

  removeHasDanger(): void {
    this.njFormGroup.classList.remove(AbstractFormBaseInput.CLASS_NAME.hasDanger);
  }

  isEmpty(): boolean {
    return this.element.value === null || typeof this.element.value === 'undefined' || this.element.value === '';
  }

  // Will add nj-form-group to form-group or create a nj-form-group if necessary
  resolveNJFormGroup(): Element | null {
    return this.findFormGroup(this.options.njFormGroup.required);
  }

  // Demarcation element (e.g. first child of a form-group)
  // Subclasses such as file inputs may have different structures
  outerElement(): Element {
    return this.element;
  }

  // Will add nj-label to nj-form-group if not already specified
  resolveNJLabel(): void {
    let label: NodeListOf<Element> = this.njFormGroup.querySelectorAll(
      AbstractFormBaseInput.INPUT_SELECTOR.njLabelWildcard
    );

    if (label.length === 0) {
      // we need to find it based on the configured selectors
      label = this.findLabel(this.options.label.required);

      if (label !== null && label.length > 1) {
        // a candidate label was found, add the configured default class name
        label.forEach((el) => {
          el.classList.add(this.options.label.className);
        });
      }
    }
  }

  // Find nj-label variant based on the config selectors
  findLabel(raiseError = true): null | NodeListOf<Element> {
    let label: NodeListOf<Element> = null;
    let i = 0;
    let selector: string;
    let labelFound = false;

    do {
      selector = this.options.label.selectors[i];

      try {
        label = this.njFormGroup.querySelectorAll(selector);
      } catch (e) {
        // handle the SyntaxError exception, if the selector is not understand by the browser.
        // @see https://developer.mozilla.org/en-US/docs/Web/API/Element/querySelectorAll
        label = null;
      }

      labelFound = label !== null && label.length > 0;

      i++;
    } while (!labelFound && i < this.options.label.selectors.length);

    if (!labelFound && raiseError) {
      console.error(
        `Failed to find ${
          AbstractFormBaseInput.INPUT_SELECTOR.njLabelWildcard
        } within nj-form-group for ${Testing.describe(this.element)}`
      );
    }

    return label;
  }

  // Due to the interconnected nature of labels/inputs/help-blocks, signal the nj-form-group-* size variation based on
  //  a found form-control-* size
  resolveNJFormGroupSizing(): void {
    if (!this.options.convertInputSizeVariations) {
      return;
    }

    // Modification - Change text-sm/lg to form-group-sm/lg instead (preferred standard and simpler css/less variants)
    for (const inputSize in AbstractFormBaseInput.FORM_CONTROL_SIZE_MARKERS) {
      if (this.element.classList.contains(inputSize)) {
        // this.element.classList.remove(inputSize)
        this.njFormGroup.classList.add(AbstractFormBaseInput.FORM_CONTROL_SIZE_MARKERS[inputSize]);
      }
    }
  }
}
