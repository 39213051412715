/**
 * --------------------------------------------------------------------------
 * NJ : Fab.ts
 * --------------------------------------------------------------------------
 */
import '../../globals/js/animation';
import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';
export default class Fab extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-fab-menu`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.fab`;
  protected static readonly EVENT_KEY = `.${Fab.DATA_KEY}`;

  static SELECTOR = {
    default: `.${Fab.NAME}`,
    button: `.${Core.KEY_PREFIX}-fab`,
    item: `.${Core.KEY_PREFIX}-fab__item`
  };

  private static readonly EVENT = {
    click: `${EventName.click}${Fab.EVENT_KEY}`,
    mouseenter: `${EventName.mouseenter}${Fab.EVENT_KEY}`,
    mouseleave: `${EventName.mouseleave}${Fab.EVENT_KEY}`
  };

  private static readonly DURATION_PER_ITEM = 35;
  private static readonly ITEMS_HEIGHT = 3.8;
  static OPEN_CLASS = 'active';
  private static readonly STAGGER_DELAY = 70;

  private buttons: NodeListOf<Element>;
  private items: NodeListOf<Element>;
  private button: Element;

  constructor(element, options) {
    super(Fab, element);

    this.buttons = this.element.querySelectorAll(Fab.SELECTOR.button);
    this.items = this.element.querySelectorAll(Fab.SELECTOR.item);
    this.button = this.buttons[0];

    this.setOptions(options);
    this.element = element;

    this.setDefaultAriaAttribute();

    this.setListeners();
    Data.setData(element, Fab.DATA_KEY, this);
  }

  open(): void {
    let i;
    const menuPos = this.options.menuPosition;
    const durationMax = Fab.DURATION_PER_ITEM * this.items.length;
    Manipulator.toggleClass(this.button, Fab.OPEN_CLASS);
    const open = this.button.classList.contains(Fab.OPEN_CLASS);
    this.button.setAttribute('aria-expanded', open.toString());
    const transPos = menuPos === 'top' || menuPos === 'bottom' ? 'translateY' : 'translateX';
    const transWay = menuPos === 'top' || menuPos === 'left' ? '-' : '';

    for (i = 0; i < this.items.length; i++) {
      const transLength = (i + 1) * Fab.ITEMS_HEIGHT + 1;
      const openAnimation = [
        {
          transform: `${transPos}(0)`,
          opacity: 0
        },
        {
          transform: `${transPos}(${transWay}${transLength}rem)`,
          opacity: 1
        }
      ];
      const keyFrames = open ? openAnimation : openAnimation.reverse();
      const duration = durationMax - Fab.DURATION_PER_ITEM * i;
      const delay = Fab.STAGGER_DELAY * i;

      this.items[i].animate(keyFrames, {
        duration,
        delay,
        fill: 'forwards'
      });
    }
  }

  setListeners(): void {
    const eventOpen = Fab.EVENT.click;

    EventHandler.on(this.element, eventOpen, this.options.selector, () => this.open());
  }

  setOptions(options): void {
    const menuPosition = this.element.getAttribute('data-placement');

    this.options = {
      menuPosition
    };
    this.options = Manipulator.extend(this.options, options);

    // Avoid wrong configuration
    if (['top', 'right', 'bottom', 'left'].indexOf(this.options.menuPosition) < 0) {
      this.options.menuPosition = 'top';
    }
  }

  getOptions(): any {
    return this.options;
  }

  setDefaultAriaAttribute(): void {
    this.button.setAttribute('aria-expanded', 'false');
  }

  dispose(): void {
    Data.removeData(this.element, Fab.DATA_KEY);
    this.element = null;
  }

  static getInstance(element: HTMLElement): Fab {
    return Data.getData(element, Fab.DATA_KEY) as Fab;
  }

  static init(options = {}): Fab[] {
    return super.init(this, options, Fab.SELECTOR.default) as Fab[];
  }
}
