/**
 * --------------------------------------------------------------------------
 * NJ : Select.ts
 * --------------------------------------------------------------------------
 */
import AbstractFormBaseInput from '../../globals/ts/abstract-form-base-input';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import Manipulator from '../../globals/ts/manipulator';
export default class Select extends AbstractFormBaseInput {
  static readonly NAME = `${Core.KEY_PREFIX}-select`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.select`;

  protected static readonly SELECTOR = {
    default: 'select.nj-form-control',
    formGroup: AbstractFormBaseInput.SELECTOR.formGroup
  };

  protected static readonly DEFAULT_OPTIONS: any = {
    requiredClasses: ['form-control||custom-select']
  };

  constructor(element: HTMLSelectElement, options = {}) {
    super(Select, element, Manipulator.extend(true, Select.DEFAULT_OPTIONS, options));

    // floating labels will cover the options, so trigger them to be above (if used)
    this.addIsFilled();
    Data.setData(element, Select.DATA_KEY, this);
  }

  dispose(): void {
    Data.removeData(this.element, Select.DATA_KEY);
    this.element = null;
  }

  static init(options = {}): Select[] {
    return super.init(this, options, Select.SELECTOR.default) as Select[];
  }

  static getInstance(element: HTMLSelectElement): Select {
    return Data.getData(element, Select.DATA_KEY) as Select;
  }

  static matches(element): boolean {
    return element.tagName === 'SELECT';
  }
}
