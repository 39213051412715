import { useCallback, useEffect, useMemo, useState } from "react";
import { Action, Pricing } from "../../pricingListSlice";
import { xorBy, intersectionBy, uniq } from "lodash";

const filterActions = (pricings: Pricing[], actions: Action[]): Action[] => {
	const res: Action[] = actions.filter((action) => {
		if (action.by_site_or_portfolios) {
			const sites = pricings.map((p) =>
				p.site_pricings
					.map((site_pricing) => site_pricing.site.id)
					.sort()
					.join(",")
			);
			const equalBySites = sites.every((val) => val === sites[0]);

			const portfolios = pricings.map((p) => p.portfolio_id);
			const equalByPortfolios = portfolios.every(
				(val) => val && val === portfolios[0]
			);
			return equalBySites || equalByPortfolios;
		}
		if (action.is_unique_by_group) {
			const groupIds = pricings.map((p) =>
				JSON.stringify(p.pricing_group_id)
			);
			const uniqueByGroupIds = uniq(groupIds);
			return uniqueByGroupIds.length === groupIds.length;
		}
		if (action.is_unique_by_pricing_type) {
			const pricingTypeIds = pricings.map((p) =>
				JSON.stringify(p.pricing_type_id)
			);
			return uniq(pricingTypeIds).length === 1;
		}
		if (pricings.length > 1 && action.action === "ADD_PARTY") {
			return false;
		}

		return true;
	});
	return res;
};

export function usePricingSelection(all?: Pricing[] | null) {
	const [selectedPricings, setSelectedPricings] = useState<Pricing[]>([]);

	const togglePricingSelection = useCallback(
		(pricing: Pricing) => {
			setSelectedPricings((prev) => xorBy(prev, [pricing], "id"));
		},
		[setSelectedPricings]
	);

	const clearSelectedPricings = useCallback(() => {
		setSelectedPricings([]);
	}, [setSelectedPricings]);

	useEffect(() => {
		// NB: "all" must be first argument to intersectionBy otherwise when the pricings change status
		// they'll stay selected and keep their previous actions intersection
		setSelectedPricings((prev) => intersectionBy(all || [], prev, "id"));
	}, [all, setSelectedPricings]);

	const selectAll = useCallback(() => {
		const selectablepricings = all?.filter((a) => a.actions.length);
		setSelectedPricings(selectablepricings || []);
	}, [all, setSelectedPricings]);

	const selectedIds = useMemo(
		() => selectedPricings.map((pricing) => pricing.id),
		[selectedPricings]
	);
	const selectedRunIds = useMemo(
		() => selectedPricings.map((pricing) => pricing.pricing_runs[0].id),
		[selectedPricings]
	);

	const availableActions = useMemo(() => {
		let res = intersectionBy(
			...selectedPricings.map((pricing) => pricing.actions),
			"action"
		);
		return filterActions(selectedPricings, res);
	}, [selectedPricings]);

	return {
		selectedPricings,
		selectedIds,
		availableActions,
		selectAll,
		togglePricingSelection,
		clearSelectedPricings,
		selectedRunIds,
	};
}
