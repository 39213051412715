import { Box, IconButton, Typography } from "@mui/material";
import { style } from "./ControlObject.style";
import { InfoBlockItem } from "../../../../../common/components/InfoBlockItem";
import { PrimaryTitle } from "../../../../../common/components/PrimaryTitle";
import Group from "../../../../../common/components/Group";
import YesNoTag from "../../../../../common/components/YesNoTag";
import { If } from "../../../../../common/components/If";
import { ControlObject } from "../../../../../requests_geco/sitesApi/sitesApi.types";
import EditIcon from "@mui/icons-material/Edit";

export interface ControlObjectCardProps {
	controlObject: ControlObject;
	active?: boolean;
	gecoEndpoint: string | undefined;
	onEdit: (controlObject: ControlObject) => void;
}

export const ControlObjectCard = ({
	controlObject,
	active,
	gecoEndpoint,
	onEdit,
}: ControlObjectCardProps) => {
	return (
		<Box sx={{ ...style.container, background: active ? "#FFFFFF" : "" }}>
			<Box sx={style.titleContainer}>
				<PrimaryTitle underline title={controlObject.name} />
				<IconButton role="button" onClick={() => onEdit(controlObject)}>
					<EditIcon />
				</IconButton>
			</Box>
			<Group>
				<InfoBlockItem
					fallback="2000-01-01"
					label="Start Date"
					value={controlObject?.start_date}
				/>
				<InfoBlockItem
					fallback="2100-01-01"
					label="End Date"
					value={controlObject?.end_date}
				/>
			</Group>
			<Group>
				<If condition={controlObject?.night_start}>
					<InfoBlockItem
						fallback="Not provided"
						label="Night Start"
						value={controlObject?.night_start}
					/>
				</If>
				<If condition={controlObject?.night_end}>
					<InfoBlockItem
						fallback="Not provided"
						label="Night End"
						value={controlObject?.night_end}
					/>
				</If>
			</Group>
			<Group>
				<If condition={controlObject?.night_set_point}>
					<InfoBlockItem
						fallback="Not provided"
						label="Night Set Point"
						value={controlObject?.night_set_point}
					/>
				</If>
				<If condition={controlObject?.minimum_value}>
					<InfoBlockItem
						fallback="Not provided"
						label="Min Value"
						value={`${controlObject?.minimum_value} kW`}
					/>
				</If>
				<If condition={controlObject?.minimum_duration}>
					<InfoBlockItem
						fallback="Not provided"
						label="Min Duration"
						value={controlObject?.minimum_duration}
					/>
				</If>
				<If condition={controlObject?.minimum_rest_time}>
					<InfoBlockItem
						fallback="Not provided"
						label="Min Rest Time"
						value={controlObject?.minimum_rest_time}
					/>
				</If>
			</Group>
			<Group>
				<If condition={controlObject?.ramp_up}>
					<InfoBlockItem
						fallback="Not provided"
						label="Ramp Up"
						value={controlObject?.ramp_up}
					/>
				</If>
				<If condition={controlObject?.ramp_down}>
					<InfoBlockItem
						fallback="Not provided"
						label="Ramp Down"
						value={controlObject?.ramp_down}
					/>
				</If>
			</Group>
			<If condition={controlObject?.test}>
				<InfoBlockItem
					fallback="Not provided"
					label="Tested"
					value={<YesNoTag yes={controlObject?.test} />}
				/>
			</If>
			<If condition={controlObject?.software_provider}>
				<InfoBlockItem
					fallback="Not provided"
					label="Software Provider"
					value={controlObject?.software_provider}
				/>
			</If>
			<If condition={controlObject?.files_attached?.length}>
				<InfoBlockItem
					fallback="Not provided"
					label="Total Files"
					value={
						<>
							{controlObject?.files_attached.map(
								(file, index) => {
									return (
										<Box
											sx={style.container.fileContainer}
											key={index}
										>
											<a
												download={file.name}
												href={new URL(
													file.file,
													gecoEndpoint
												).toString()}
												target="_blank"
												rel="noreferrer"
											>
												<Typography
													style={
														style.container.fileText
													}
													variant="h3"
												>
													{file.name}
												</Typography>
											</a>
										</Box>
									);
								}
							)}
						</>
					}
				/>
			</If>
		</Box>
	);
};
