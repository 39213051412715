import { isAfter } from "date-fns";
import * as yup from "yup";
import { CommodityFixings } from "./PricingRequestStepper.slice";
import { MAXIMUM_TYPES_IN_ONE_GO } from "./PricingRequestStepper.constant";

const validateChapter51Options = (
	chapter51Switch: boolean[],
	schema: yup.NumberSchema | yup.StringSchema
) => {
	if (!chapter51Switch[0]) return schema;
	return yup
		.mixed()
		.required("Chapter51 type & number of hours are required.");
};

const validateClickCount = (params: any, schema: yup.NumberSchema) => {
	const [clickGranularity, clickOptionSwitch] = params;
	if (!!clickOptionSwitch && clickGranularity.length > 0)
		return yup
			.number()
			.required("Number of clicks is a required field")
			.min(1, "Please enter a value between 1 and 10")
			.max(10, "Please enter a value between 1 and 10");
	return schema;
};
const validateClickGranularity = (params: any, schema: yup.Schema) => {
	const [clickOptionSwitch] = params;

	if (!clickOptionSwitch) return schema;
	return yup.array().min(1, "Choosing click granularity is required");
};

function validateEndDateAfterStartDate(values: any) {
	const isValid = isAfter(values.endDate, values.startDate);
	if (!isValid) {
		// @ts-ignore
		return this.createError({
			path: "startDate",
			message: "There should be at least one day between start and end",
		});
	}
}

function alphaOrBetaSelectedIfIndexesSelected(values: any) {
	if (values.pricingType?.length) {
		const hasIndexPrice =
			values.pricingType.findIndex(
				(index: any) => index.name !== "Fix" && index.name !== "Click"
			) >= 0;
		if (hasIndexPrice) {
			if (!values.alpha && !values.beta) {
				// @ts-ignore
				return this.createError({
					path: "alpha",
					message:
						"There should be at least alpha or beta result options selected",
				});
			}
		}
	}
}

export const firstOfNextYear = new Date(new Date().getFullYear() + 1, 0, 1);

export const pricingRequestStepSchema = yup
	.object()
	.shape({
		pricingType: yup
			.array()
			.of(
				yup.object().shape({
					name: yup
						.string()
						.required("Pricing type is a required field"),
				})
			)
			.min(1, "Pricing type must have at least 1 items")
			.max(
				MAXIMUM_TYPES_IN_ONE_GO,
				`Maximum ${MAXIMUM_TYPES_IN_ONE_GO} types in one request, if you need more, do multiple requests`
			),
		gooPricingType: yup
			.array()
			.of(
				yup.object().shape({
					name: yup.string(),
				})
			)
			.max(
				MAXIMUM_TYPES_IN_ONE_GO,
				`Maximum ${MAXIMUM_TYPES_IN_ONE_GO} types in one request, if you need more, do multiple requests`
			),
		startDate: yup
			.date()
			.typeError("Start date is a required field")
			.required("Start date is a required field"),
		endDate: yup
			.date()
			.typeError("End date is a required field")
			.required("End date is a required field"),
		gooStartDate: yup
			.date()
			.typeError("Start date is a required field")
			.required("Start date is a required field"),
		gooEndDate: yup
			.date()
			.typeError("End date is a required field")
			.required("End date is a required field"),
		baseCapacity: yup
			.number()
			.when("pricingType", (pricingType, schema) => {
				if (pricingType.length === 0) {
					return schema;
				}
				const hasBaseProd = pricingType[0].some((p: { name: string }) =>
					p.name.toLowerCase().includes("baseprod")
				);
				return hasBaseProd ? schema.required() : schema;
			})
			.moreThan(0, "Please enter a minimum value greater than 0")
			.max(100, "Please enter a Maximum value of 100"),
		clickGranularity: yup
			.mixed()
			.when(["clickOptionSwitch"], validateClickGranularity),
		gooPowerAdjustment: yup
			.number()
			.min(0, "Please enter a minimum value of 0")
			.max(100, "Please enter a Maximum value of 100"),
		clickCountYear: yup
			.number()
			.when(
				["clickGranularity", "clickOptionSwitch"],
				validateClickCount
			),
		clickCountMonth: yup
			.number()
			.when(
				["clickGranularity", "clickOptionSwitch"],
				validateClickCount
			),
		clickCountQuarter: yup
			.number()
			.when(
				["clickGranularity", "clickOptionSwitch"],
				validateClickCount
			),
		chapter51Type: yup
			.string()
			.when("chapter51Switch", validateChapter51Options),
		chapter51NumberOfHours: yup
			.number()
			.when("chapter51Switch", validateChapter51Options),
		alpha: yup.boolean(),
		beta: yup.boolean(),
	})
	.test("endDateAfterStartDate", validateEndDateAfterStartDate)
	.test(
		"alphaOrBetaSelectedIfIndexesSelected",
		alphaOrBetaSelectedIfIndexesSelected
	);

export type ClickabilityGranularity = "Year" | "Quarter" | "Month" | "NoSplit";
export interface Clickability {
	granularity: ClickabilityGranularity;
	click_count: number;
}

export type Granularity = "MONTH" | "QUARTER" | "CAL" | "WHOLE_HORIZON";
export interface PricingRequestAPI {
	start_date: String;
	end_date: String;
	pricing_date?: String;
	is_real_time?: boolean;
	granularities: Granularity[];
	clickability?: Clickability[] | undefined;
	alpha: boolean;
	beta: boolean;
	tender_id: number;
	site_ids: number[];
	types: CommodityFixings[];
	portfolio_ids: number[];
	base_capacity_volume_percentage?: number;
	chapter51_type: string | undefined;
	chapter51_number_of_hours: number | undefined;
	goo_power_adjustment?: number;
	ppa_with_goo: boolean;
	only_goo: boolean;
	goo_granularities?: Granularity[];
	goo_start_date?: String;
	goo_end_date?: String;
	goo_pricing_types?: CommodityFixings[];
	negative_price_value?: number | null;
}
