import { Action, Pricing } from "../../pricingListSlice";
import React, { useCallback, useMemo, useRef } from "react";
import PricingModal from "../PricingModal";
import { ValidationError } from "../../../../common/hooks/usePydanticErrorsFormat";
import { Alert, Box, FormLabel, InputAdornment } from "@mui/material";
import CustomInput from "../../../../common/components/CustomInput";
import { Formik, FormikProps, FormikValues } from "formik";
import { schema } from "../../pricingList.constant";
import { If } from "../../../../common/components/If";
import * as _ from "lodash";

export interface SetFixingActionModalProps {
	open: boolean;
	onClose: () => void;
	forPricings: Pricing[];
	onSubmit: (body: any) => void;
	isLoading: boolean;
	action?: Action | null;
	errorMessage: string | ValidationError | undefined | null;
}

const style = {
	wrapper: {
		display: "flex",
		flexDirection: "column",
		gap: "5px",
	},
	field: {
		display: "flex",
		flexDirection: "row",
		gap: "8px",
		alignItems: "center",
	},
	label: {
		width: "120px",
		wordBreak: "break-all",
	},
	inputWrapper: {
		display: "flex",
		alignItems: "center",
		flex: 1,
	},
};

const FIELDS = [
	{ displayName: "Baseload Mid", technicalName: "baseloadMid" },
	{ displayName: "Baseload Spread", technicalName: "baseloadSpread" },
	{ displayName: "Baseload Price", technicalName: "baseloadPrice" },
	{ displayName: "Goo Mid", technicalName: "goo_cross" },
	{ displayName: "Goo Price", technicalName: "goo_price" },
];

export function SetFixingActionModal(props: SetFixingActionModalProps) {
	const submit = useCallback(
		(values: any) => {
			props.onSubmit(values);
		},
		[props.onSubmit]
	);
	const formRef = useRef<FormikProps<FormikValues>>();

	const getGOOCrossInitialValue = useMemo(() => {
		const value =
			props.forPricings?.[0]?.pricing_runs?.[0]?.summary?.GOO_Cross
				?.value;
		return !isNaN(Number(value)) ? Number(value) : 0;
	}, [props.forPricings]);

	const getGOOPriceInitialValue = useMemo(() => {
		const value =
			props.forPricings?.[0]?.pricing_runs?.[0]?.summary?.FinalGooPrice
				?.value;
		return !isNaN(Number(value)) ? Number(value) : 0;
	}, [props.forPricings]);

	const haveMultipleAndDifferentGOOCross = useMemo(() => {
		const values = props.forPricings.map((pricing) => {
			const gooCrossValue =
				pricing.pricing_runs?.[0]?.summary?.GOO_Cross?.value ?? 0;
			return gooCrossValue;
		});
		return _.uniq(values).length !== 1;
	}, [props.forPricings]);

	const haveMultipleAndDifferentGOOPrice = useMemo(() => {
		const values = props.forPricings.map((pricing) => {
			const finalGooPriceValue =
				pricing.pricing_runs?.[0]?.summary?.FinalGooPrice?.value ?? 0;
			return finalGooPriceValue;
		});
		return _.uniq(values).length !== 1;
	}, [props.forPricings]);

	return (
		<PricingModal
			open={props.open}
			onClose={props.onClose}
			onSubmit={() => {
				if (formRef.current) {
					// @ts-ignore
					formRef.current.submitForm();
				}
			}}
			forPricings={props.forPricings}
			title={props.action?.display_name || ""}
			subTitle={"Applicable for the following pricings:"}
			isSubmitDisabled={props.isLoading}
			isLoading={props.isLoading}
			errorMessage={props.errorMessage}
		>
			<Formik
				initialValues={{
					baseloadMid: 0,
					baseloadSpread: 0,
					baseloadPrice: 0,
					goo_cross: getGOOCrossInitialValue,
					goo_price: getGOOPriceInitialValue,
					validityInMinutes: null,
				}}
				validationSchema={schema}
				onSubmit={submit}
				//@ts-ignore
				innerRef={formRef}
			>
				{({
					values,
					errors,
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit}>
						<Box sx={style.wrapper}>
							{FIELDS.map((field, index) => (
								<Box key={field.technicalName} sx={style.field}>
									<FormLabel sx={style.label}>
										{field.displayName}
									</FormLabel>
									<Box sx={style.inputWrapper}>
										<CustomInput
											InputProps={{
												endAdornment: (
													<InputAdornment position="start">
														{field.displayName}
													</InputAdornment>
												),
												type: "number",
											}}
											rows={3}
											name={field.technicalName}
											autoFocus={index === 0}
											placeholder={`Please enter ${field.displayName}`}
											disabled={props.isLoading}
											onChange={handleChange}
											fullWidth
											onBlur={(e) => {
												if (
													field.technicalName ===
													"baseloadMid"
												) {
													setFieldValue(
														"baseloadPrice",
														Number(
															(
																values.baseloadMid +
																values.baseloadSpread
															)?.toFixed(2)
														)
													);
												} else if (
													field.technicalName ===
													"baseloadSpread"
												) {
													setFieldValue(
														"baseloadPrice",
														Number(
															(
																values.baseloadMid +
																values.baseloadSpread
															)?.toFixed(2)
														)
													);
												} else if (
													field.technicalName ===
													"baseloadPrice"
												) {
													setFieldValue(
														"baseloadSpread",
														Number(
															(
																values.baseloadPrice -
																values.baseloadMid
															)?.toFixed(2)
														)
													);
												}
												handleBlur(e);
											}}
											variant="standard"
											// @ts-ignore
											value={values[field.technicalName]}
											// @ts-ignore
											errorText={
												errors[field.technicalName]
											}
											touched={true}
										/>
									</Box>
									<If
										condition={
											haveMultipleAndDifferentGOOCross &&
											field.technicalName === "goo_cross"
										}
									>
										<Alert severity="warning">
											There were multiple values for this
											field
										</Alert>
									</If>
									<If
										condition={
											haveMultipleAndDifferentGOOPrice &&
											field.technicalName === "goo_price"
										}
									>
										<Alert severity="warning">
											There were multiple values for this
											field
										</Alert>
									</If>
								</Box>
							))}
						</Box>
						<Box sx={style.wrapper}>
							<Box key={"validityInMinutes"} sx={style.field}>
								<FormLabel sx={style.label}>
									Price validity (minutes)
								</FormLabel>
								<Box sx={style.inputWrapper}>
									<CustomInput
										InputProps={{
											endAdornment: (
												<InputAdornment position="start">
													Price validity (minutes)
												</InputAdornment>
											),
											type: "number",
										}}
										rows={3}
										name={"validityInMinutes"}
										autoFocus={false}
										placeholder={`Please enter Price validity (minutes)`}
										disabled={props.isLoading}
										onChange={handleChange}
										fullWidth
										onBlur={handleBlur}
										variant="standard"
										// @ts-ignore
										value={values.validityInMinutes}
										// @ts-ignore
										errorText={errors.validityInMinutes}
										touched={true}
									/>
								</Box>
							</Box>
						</Box>
					</form>
				)}
			</Formik>
		</PricingModal>
	);
}
