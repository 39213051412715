import { createApi } from "@reduxjs/toolkit/query/react";
import customBaseQuery from "../../common/utils/customBaseQuery";
import {
	ServicePointsResponseType,
	DynamicEndpointQuery,
	GenericReferential,
	NegativePriceIndex,
	ClickTypesResponseType,
	GetContactsResponseType,
} from "./referentialApi.types";

const referentialApi = createApi({
	reducerPath: "referentialApi",
	baseQuery: customBaseQuery({
		baseURL: process.env.REACT_APP_GECO_API_PATH!,
	}),
	endpoints: (builder) => ({
		getCertificateTypes: builder.query<
			GenericReferential[],
			DynamicEndpointQuery
		>({
			query: ({ endpoint }) => ({
				url: `${endpoint || ""}/referentials/certificate_types`,
				method: "GET",
			}),
		}),
		getClickTypes: builder.query<
			ClickTypesResponseType[],
			DynamicEndpointQuery
		>({
			query: ({ endpoint }) => ({
				url: `${endpoint || ""}/referentials/click_types`,
				method: "GET",
			}),
		}),
		getContractTypes: builder.query<
			GenericReferential[],
			DynamicEndpointQuery
		>({
			query: ({ endpoint }) => ({
				url: `${endpoint || ""}/referentials/contract_types`,
				method: "GET",
			}),
		}),
		getNegativePriceIndexes: builder.query<
			NegativePriceIndex[],
			{ country_code: string } & DynamicEndpointQuery
		>({
			query: ({ country_code, endpoint }) => {
				return {
					url: `${endpoint || ""}/referentials/indexes`,
					method: "GET",
					params: {
						country_code,
					},
				};
			},
		}),
		getTsos: builder.query<GenericReferential[], DynamicEndpointQuery>({
			query: ({ endpoint }) => {
				return {
					url: `${endpoint || ""}/referentials/tsos`,
					method: "GET",
				};
			},
		}),
		getDsos: builder.query<GenericReferential[], DynamicEndpointQuery>({
			query: ({ endpoint }) => {
				return {
					url: `${endpoint || ""}/referentials/dsos`,
					method: "GET",
				};
			},
		}),
		getEicBalancingGroups: builder.query<
			GenericReferential[],
			DynamicEndpointQuery
		>({
			query: ({ endpoint }) => {
				return {
					url: `${endpoint || ""}/referentials/eic_balancing_groups`,
					method: "GET",
				};
			},
		}),
		getServicePoints: builder.query<
			ServicePointsResponseType,
			{ search__name: string } & DynamicEndpointQuery
		>({
			query: ({ endpoint, ...params }) => {
				return {
					url: `${endpoint || ""}/referentials/servicepoints`,
					method: "GET",
					params,
				};
			},
		}),
		getServicePointTypes: builder.query<
			GenericReferential[],
			DynamicEndpointQuery
		>({
			query: ({ endpoint }) => {
				return {
					url: `${endpoint || ""}/referentials/servicepoint_types`,
					method: "GET",
				};
			},
		}),
		getServicePointCodeTypes: builder.query<
			GenericReferential[],
			DynamicEndpointQuery
		>({
			query: ({ endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/referentials/servicepoint_code_types`,
					method: "GET",
				};
			},
		}),
		getBooks: builder.query<GenericReferential[], DynamicEndpointQuery>({
			query: ({ endpoint }) => {
				return {
					url: `${endpoint || ""}/referentials/books`,
					method: "GET",
				};
			},
		}),
		getCountries: builder.query<GenericReferential[], DynamicEndpointQuery>(
			{
				query: ({ endpoint }) => {
					return {
						url: `${endpoint || ""}/referentials/countries`,
						method: "GET",
					};
				},
			}
		),
		getAgreementTypes: builder.query<
			GenericReferential[],
			DynamicEndpointQuery
		>({
			query: ({ endpoint }) => {
				return {
					url: `${endpoint || ""}/referentials/agreement_types`,
					method: "GET",
				};
			},
		}),
		getMachines: builder.query<GenericReferential[], DynamicEndpointQuery>({
			query: ({ endpoint }) => {
				return {
					url: `${
						endpoint || ""
					}/referentials/machines?page=1&per_page=500&sort=name`,
					method: "GET",
				};
			},
		}),
		getContacts: builder.query<
			GetContactsResponseType,
			{
				search__name?: string;
				search__email?: string;
			} & DynamicEndpointQuery
		>({
			query: ({ search__name, search__email, endpoint }) => {
				return {
					url: `${endpoint || ""}/referentials/contacts`,
					method: "GET",
					params: {
						search__name,
						search__email,
					},
				};
			},
		}),
	}),
});

export const {
	useGetAgreementTypesQuery,
	useGetBooksQuery,
	useGetClickTypesQuery,
	useGetContactsQuery,
	useGetContractTypesQuery,
	useGetCountriesQuery,
	useGetDsosQuery,
	useGetEicBalancingGroupsQuery,
	useGetMachinesQuery,
	useGetNegativePriceIndexesQuery,
	useGetServicePointCodeTypesQuery,
	useGetServicePointTypesQuery,
	useGetTsosQuery,
	useLazyGetNegativePriceIndexesQuery,
	useLazyGetServicePointsQuery,
} = referentialApi;

export default referentialApi;
