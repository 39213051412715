/**
 * --------------------------------------------------------------------------
 * NJ : AbstractFormControl.ts
 * --------------------------------------------------------------------------
 */

import AbstractFormBaseInput from './abstract-form-base-input';
import Manipulator from './manipulator';

export default abstract class AbstractFormControl extends AbstractFormBaseInput {
  protected static readonly DEFAULT_OPTIONS: any = {
    requiredClasses: ['nj-form-control']
  };

  constructor(component, element: HTMLElement, options = {}) {
    super(component, element, Manipulator.extend(true, AbstractFormControl.DEFAULT_OPTIONS, options), {});
  }

  dispose(): void {
    super.dispose();
  }
}
