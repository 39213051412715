/**
 * --------------------------------------------------------------------------
 * NJ: collapse.ts
 * --------------------------------------------------------------------------
 */
import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';
import Util from '../../globals/ts/util';
export default class Collapse extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-collapse`;
  public static readonly DATA_KEY = `${Core.KEY_PREFIX}.collapse`;
  protected static readonly EVENT_KEY = `.${Collapse.DATA_KEY}`;
  protected static readonly DATA_API_KEY = Core.KEY_PREFIX;
  public static hasInit = false;
  public static readonly CLASS_NAME = {
    show: 'show',
    collapse: `${Core.KEY_PREFIX}-collapse`,
    collapsing: `${Core.KEY_PREFIX}-collapsing`,
    collapsed: `${Core.KEY_PREFIX}-collapsed`
  };

  public static readonly EVENT = {
    show: `${EventName.show}${Collapse.EVENT_KEY}`,
    shown: `${EventName.shown}${Collapse.EVENT_KEY}`,
    hide: `${EventName.hide}${Collapse.EVENT_KEY}`,
    hidden: `${EventName.hidden}${Collapse.EVENT_KEY}`,
    clickDataApi: `${EventName.click}${Collapse.EVENT_KEY}${Collapse.DATA_API_KEY}`
  };

  protected static readonly DEFAULT_OPTIONS = {
    toggle: false,
    parent: ''
  };

  private static readonly DEFAULT_TYPE = {
    toggle: 'boolean',
    parent: '(string|element)'
  };

  private static readonly DIMENSION = {
    width: 'width',
    height: 'height'
  };

  protected static readonly SELECTOR = {
    default: `.${Collapse.CLASS_NAME.collapse}`,
    actives: `.${Collapse.CLASS_NAME.show}, .${Collapse.CLASS_NAME.collapsing}`,
    dataToggle: '[data-toggle="collapse"]'
  };

  private isTransitioning: boolean;
  private triggerArray: Element[];

  private selector: string | null;
  private parent: HTMLElement;

  constructor(element: HTMLElement, options = {}) {
    super(Collapse, element, Collapse.getOptions(options));

    this.element = element;
    this.isTransitioning = false;
    this.triggerArray = Util.makeArray(
      document.querySelectorAll(
        `${Collapse.SELECTOR.dataToggle}[href="#${element.id}"],` +
          `${Collapse.SELECTOR.dataToggle}[data-target="#${element.id}"]`
      )
    );

    const toggleList = Util.makeArray(document.querySelectorAll(Collapse.SELECTOR.dataToggle));

    for (let i = 0, len = toggleList.length; i < len; i++) {
      const elem = toggleList[i];
      const selector = Util.getSelectorFromElement(elem);

      const filterElement = Util.makeArray(document.querySelectorAll(selector)).filter(
        (foundElem) => foundElem === element
      );

      if (selector !== null && filterElement.length) {
        this.selector = selector;
        this.triggerArray.push(elem);
      }
    }

    this.parent = this.options.parent ? this.getParent() : null;

    if (!this.options.parent) {
      this.addAriaAndCollapsedClass(this.element, this.triggerArray);
    }

    // Data.setData(element, Collapse.DATA_KEY, this);

    if (this.options.toggle) {
      this.toggle();
    }

    Data.setData(element, Collapse.DATA_KEY, this);
    if (!Collapse.hasInit) {
      Collapse.hasInit = true;
      this.registerEvents();
    }
  }

  toggle(): void {
    if (this.element.classList.contains(Collapse.CLASS_NAME.show)) {
      this.hide();
    } else {
      this.show();
    }
  }

  show(): void {
    if (this.isTransitioning || this.element.classList.contains(Collapse.CLASS_NAME.show)) {
      return;
    }

    let actives: Element[] | null;
    let activesData;

    if (this.parent) {
      actives = Util.makeArray(this.parent.querySelectorAll(Collapse.SELECTOR.actives)).filter((elem) => {
        if (typeof this.options.parent === 'string') {
          return elem.getAttribute('data-parent') === this.options.parent;
        }

        return elem.classList.contains(Collapse.CLASS_NAME.collapse);
      });

      if (actives.length === 0) {
        actives = null;
      }
    }

    const container = document.querySelector(this.selector);
    if (actives) {
      const tempActiveData = actives.filter((elem) => container !== elem);
      activesData = tempActiveData[0] ? Data.getData(tempActiveData[0], Collapse.DATA_KEY) : null;

      if (activesData && activesData.isTransitioning) {
        return;
      }
    }

    const startEvent = EventHandler.trigger(this.element, Collapse.EVENT.show);
    if (startEvent.defaultPrevented) {
      return;
    }

    if (actives) {
      actives.forEach((elemActive) => {
        if (container !== elemActive) {
          Collapse.collapseInterface(elemActive, 'hide');
        }

        if (!activesData) {
          Data.setData(elemActive, Collapse.DATA_KEY, null);
        }
      });
    }

    const dimension = this.getDimension();

    this.element.classList.remove(Collapse.CLASS_NAME.collapse);
    this.element.classList.add(Collapse.CLASS_NAME.collapsing);

    this.element.style[dimension] = 0;

    if (this.triggerArray.length) {
      this.triggerArray.forEach((element) => {
        element.classList.remove(Collapse.CLASS_NAME.collapsed);
        element.setAttribute('aria-expanded', 'true');
      });
    }

    this.setTransitioning(true);

    const complete = (): void => {
      this.element.classList.remove(Collapse.CLASS_NAME.collapsing);
      this.element.classList.add(Collapse.CLASS_NAME.collapse);
      this.element.classList.add(Collapse.CLASS_NAME.show);

      this.element.style[dimension] = '';

      this.setTransitioning(false);

      EventHandler.trigger(this.element, Collapse.EVENT.shown);
    };

    const capitalizedDimension = dimension[0].toUpperCase() + dimension.slice(1);
    const scrollSize = `scroll${capitalizedDimension}`;
    const transitionDuration = Util.getTransitionDurationFromElement(this.element);

    EventHandler.one(this.element, Util.TRANSITION_END, complete);

    Util.emulateTransitionEnd(this.element, transitionDuration);
    this.element.style[dimension] = `${this.element[scrollSize]}px`;
  }

  hide(): void {
    if (this.isTransitioning || !this.element.classList.contains(Collapse.CLASS_NAME.show)) {
      return;
    }

    const startEvent = EventHandler.trigger(this.element, Collapse.EVENT.hide);
    if (startEvent.defaultPrevented) {
      return;
    }

    const dimension = this.getDimension();

    this.element.style[dimension] = `${this.element.getBoundingClientRect()[dimension]}px`;

    Util.reflow(this.element);

    this.element.classList.add(Collapse.CLASS_NAME.collapsing);
    this.element.classList.remove(Collapse.CLASS_NAME.collapse);
    this.element.classList.remove(Collapse.CLASS_NAME.show);

    const triggerArrayLength = this.triggerArray.length;
    if (triggerArrayLength > 0) {
      for (let i = 0; i < triggerArrayLength; i++) {
        const trigger = this.triggerArray[i];
        const selector = Util.getSelectorFromElement(trigger);

        if (selector !== null) {
          const elem = document.querySelector(selector);

          if (!elem.classList.contains(Collapse.CLASS_NAME.show)) {
            trigger.classList.add(Collapse.CLASS_NAME.collapsed);
            trigger.setAttribute('aria-expanded', 'false');
          }
        }
      }
    }

    this.setTransitioning(true);

    const complete = (): void => {
      this.setTransitioning(false);
      this.element.classList.remove(Collapse.CLASS_NAME.collapsing);
      this.element.classList.add(Collapse.CLASS_NAME.collapse);
      EventHandler.trigger(this.element, Collapse.EVENT.hidden);
    };

    this.element.style[dimension] = '';
    const transitionDuration = Util.getTransitionDurationFromElement(this.element);

    EventHandler.one(this.element, Util.TRANSITION_END, complete);
    Util.emulateTransitionEnd(this.element, transitionDuration);
  }

  setTransitioning(isTransitioning: boolean): void {
    this.isTransitioning = isTransitioning;
  }

  dispose(): void {
    Data.removeData(this.element, Collapse.DATA_KEY);

    this.options = null;
    this.parent = null;
    this.element = null;
    this.triggerArray = null;
    this.isTransitioning = null;
  }

  getDimension(): string {
    const hasWidth = this.element.classList.contains(Collapse.DIMENSION.width);
    return hasWidth ? Collapse.DIMENSION.width : Collapse.DIMENSION.height;
  }

  getParent(): HTMLElement {
    let parent: HTMLElement;

    if (Util.isElement(this.options.parent)) {
      parent = this.options.parent;
    } else if (this.options.parent) {
      parent = document.querySelector(this.options.parent);
    }

    const selector = `[data-toggle="collapse"][data-parent="${this.options.parent}"]`;

    Util.makeArray(parent.querySelectorAll(selector)).forEach((element) => {
      this.addAriaAndCollapsedClass(Collapse.getTargetFromElement(element), [element]);
    });

    return parent;
  }

  addAriaAndCollapsedClass(element, triggerArray): void {
    if (element) {
      const isOpen = element.classList.contains(Collapse.CLASS_NAME.show);

      if (triggerArray.length) {
        triggerArray.forEach((elem) => {
          if (!isOpen) {
            elem.classList.add(Collapse.CLASS_NAME.collapsed);
          } else {
            elem.classList.remove(Collapse.CLASS_NAME.collapsed);
          }
          elem.setAttribute('aria-expanded', isOpen);
        });
      }
    }
  }

  private static getOptions(options): any {
    options = {
      ...Collapse.DEFAULT_OPTIONS,
      ...options
    };
    options.toggle = Boolean(options.toggle); // Coerce string values
    Util.typeCheckConfig(Collapse.NAME, options, Collapse.DEFAULT_TYPE);

    return options;
  }

  static getTargetFromElement(element): Element | null {
    const selector = Util.getSelectorFromElement(element);

    return selector ? document.querySelector(selector) : null;
  }

  static collapseInterface(element, options): void {
    let data = Data.getData(element, Collapse.DATA_KEY);
    const cfg = {
      ...Collapse.DEFAULT_OPTIONS,
      ...Manipulator.getDataAttributes(element),
      ...(typeof options === 'object' && options ? options : {})
    };

    if (!data && cfg.toggle && /show|hide/.test(options)) {
      cfg.toggle = false;
    }

    if (!data) {
      data = new Collapse(element, cfg);
    }

    if (typeof options === 'string') {
      if (typeof data[options] === 'undefined') {
        throw new Error(`No method named "${options}"`);
      }
      data[options]();
    }
  }

  static getInstance(element: HTMLElement): Collapse {
    return Data.getData(element, Collapse.DATA_KEY) as Collapse;
  }

  static init(options = {}): Collapse[] {
    return super.init(this, options, Collapse.SELECTOR.default) as Collapse[];
  }

  /**
   * ------------------------------------------------------------------------
   * Data Api implementation
   * ------------------------------------------------------------------------
   */
  private registerEvents(): void {
    EventHandler.on(document, Collapse.EVENT.clickDataApi, Collapse.SELECTOR.dataToggle, function (event) {
      // preventDefault only for <a> elements (which change the URL) not inside the collapsible element
      if (event.target.tagName === 'A') {
        event.preventDefault();
      }

      const triggerData = Manipulator.getDataAttributes(this);
      const selector = Util.getSelectorFromElement(this);
      const selectorElements = Util.makeArray(document.querySelectorAll(selector)) as HTMLElement[];

      selectorElements.forEach((element) => {
        const data = Collapse.getInstance(element);
        let options;

        if (data) {
          // update parent attribute
          if (data.parent === null && typeof triggerData.parent === 'string') {
            data.options.parent = triggerData.parent;
            data.parent = data.getParent();
          }
          options = 'toggle';
        } else {
          options = triggerData;
        }

        Collapse.collapseInterface(element, options);
      });
    });
  }
}
