import { Box, CircularProgress } from "@mui/material";
import { HamburgerMenu } from "../../../../common/components/HamburgerMenu";
import { PricingRun } from "../../pricingListSlice";
import {
	STATUS_CANNOT_EXPORT_INPUT,
	STATUS_CANNOT_EXPORT_RESULT,
} from "../../pricingList.constant";
import { HamburgerOptions } from "../../../../common/components/HamburgerOptions";
import { useMemo } from "react";

export interface PricingHamburgerProps {
	pricingRun: PricingRun;
	isExportLoading: boolean;
	onExportRunToExcel: () => void;
	onExportRunToJson: () => void;
}

const EMPTY_ARRAY: any[] = [];

export const PricingRunHamburger = (props: PricingHamburgerProps) => {
	const {
		pricingRun,
		onExportRunToExcel,
		onExportRunToJson,
		isExportLoading,
	} = props;

	const printAction = useMemo(() => {
		if (!isExportLoading) {
			if (!STATUS_CANNOT_EXPORT_RESULT.includes(pricingRun?.status)) {
				return [
					<HamburgerOptions
						key={"export"}
						title={"Export to excel"}
						action={onExportRunToExcel}
					/>,
					<HamburgerOptions
						key={"export_JSON"}
						title={"Export JSON"}
						action={onExportRunToJson}
					/>,
				];
			} else if (
				!STATUS_CANNOT_EXPORT_INPUT.includes(pricingRun?.status)
			) {
				return [
					<HamburgerOptions
						key={"export_JSON"}
						title={"Export JSON"}
						action={onExportRunToJson}
					/>,
				];
			}
		}
		return [];
	}, [pricingRun, onExportRunToExcel, onExportRunToJson]);

	const loader = useMemo(
		() =>
			isExportLoading
				? [
						<Box
							key={"loader"}
							sx={{
								display: "flex",
							}}
						>
							<CircularProgress size={20} />
						</Box>,
				  ]
				: EMPTY_ARRAY,
		[isExportLoading]
	);

	const RenderedChildren = useMemo(
		() => [...printAction, ...loader],
		[printAction, loader]
	);

	return RenderedChildren.length > 0 ? (
		<HamburgerMenu>{RenderedChildren}</HamburgerMenu>
	) : (
		<></>
	);
};
