/**
 * --------------------------------------------------------------------------
 * NJ : abstract-base-selection.ts
 * --------------------------------------------------------------------------
 */

import AbstractFormBaseInput from './abstract-form-base-input';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';
import Testing from '../../globals/ts/testing';

export default abstract class AbstractFormBaseSelection extends AbstractFormBaseInput {
  protected static readonly SELECTOR = {
    formGroup: AbstractFormBaseInput.SELECTOR.formGroup,
    label: 'label'
  };

  protected static readonly DEFAULT_OPTIONS: any = {
    label: {
      required: false
    }
  };

  protected outerClass: string;

  constructor(component, element: HTMLElement, options = {}, properties = {}) {
    super(component, element, Manipulator.extend(true, AbstractFormBaseSelection.DEFAULT_OPTIONS, options), properties);

    this.decorateMarkup();
  }

  decorateMarkup(): void {
    const decorator = Manipulator.createHtmlNode(this.options.template);
    this.element.parentNode.appendChild(decorator);
  }

  // Demarcation element (e.g. first child of a form-group)
  outerElement(): Element {
    return this.element.parentElement.closest(this.outerClass);
  }

  rejectWithoutRequiredStructure(): void {
    // '.checkbox|switch|radio > label > input[type=checkbox|radio]'
    // '.${this.outerClass} > label > input[type=${this.inputType}]'
    Testing.assert(
      this.element,
      this.element.parentElement.tagName.toLowerCase() === 'label',
      `${this.constructor.name}'s ${Testing.describe(this.element)} parent element should be <label>.`
    );
    Testing.assert(
      this.element,
      !this.outerElement().classList.contains(this.outerClass),
      `${this.constructor.name}'s ${Testing.describe(this.element)} outer element should have class ${this.outerClass}.`
    );
  }

  addFocusListener(): void {
    // checkboxes didn't appear to bubble to the document, so we'll bind these directly
    const anscestor = this.element.closest(AbstractFormBaseSelection.SELECTOR.label);
    EventHandler.on(anscestor, 'mouseenter', () => {
      this.addFormGroupFocus();
    });

    EventHandler.on(anscestor, 'mouseleave', () => {
      this.removeFormGroupFocus();
    });
  }

  addChangeListener(): void {
    EventHandler.on(this.element, 'change', () => {
      this.element.blur();
    });
  }
}
