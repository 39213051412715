/**
 * --------------------------------------------------------------------------
 * NJ : Checkbox.ts
 * --------------------------------------------------------------------------
 */
import AbstractFormBaseSelection from '../../globals/ts/abstract-form-base-selection';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import Manipulator from '../../globals/ts/manipulator';
export default class Checkbox extends AbstractFormBaseSelection {
  static readonly NAME = `${Core.KEY_PREFIX}-checkbox`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.checkbox`;

  protected static readonly SELECTOR = {
    default: `.${Checkbox.NAME}`,
    input: `.${Checkbox.NAME} > label > input[type=checkbox]`,
    formGroup: AbstractFormBaseSelection.SELECTOR.formGroup,
    label: AbstractFormBaseSelection.SELECTOR.label
  };

  protected static readonly DEFAULT_OPTIONS = {
    template: `<span class="${Checkbox.NAME}__decorator"><span class="${Checkbox.NAME}__check"></span></span>`,
    njFormGroup: {
      required: false
    }
  };

  constructor(element: HTMLInputElement, options = {}, properties = {}) {
    super(Checkbox, element, Manipulator.extend(true, Checkbox.DEFAULT_OPTIONS, options), properties);
    const parent: AbstractNode = element.parentNode as AbstractNode;
    const grandParent: AbstractNode = parent.parentNode as AbstractNode;
    Data.setData(grandParent, Checkbox.DATA_KEY, this);
  }

  dispose(): void {
    const parent: AbstractNode = this.element.parentNode as AbstractNode;
    const grandParent: AbstractNode = parent.parentNode as AbstractNode;
    Data.removeData(grandParent, Checkbox.DATA_KEY);
    this.element = null;
  }

  static init(options = {}): Checkbox[] {
    return super.init(this, options, Checkbox.SELECTOR.input) as Checkbox[];
  }

  static getInstance(element: HTMLInputElement): Checkbox {
    return Data.getData(element, Checkbox.DATA_KEY) as Checkbox;
  }

  static matches(element: Element): boolean {
    return element.getAttribute('type') === 'checkbox';
  }
}

interface AbstractNode extends Node, ParentNode {
  key: { id: string; key: string };
}
